package com.picme.installrefer

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.important
import com.picme.*
import com.picme.MostlyEmptySemantic
import com.picme.views.*
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.minutes

@Routable("/install-refer-start")
object InstallReferStartPage : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        launch {
            if (!Platform.probablyAppleUser || !Platform.usesTouchscreen) {
                pageNavigator.reset(NoCollectionsPage)
            }
        }
        return MostlyEmptySemantic.onNext - frame {
            frame {
                col {
                    centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                        source = Resources.picmePaige
                    }
                    space()

                    col {
                        spacingAndPadding = 0.dp
                        centered - FadedSemantic.onNext - h3("Ready to")
                        centered - FadedSemantic.onNext - h3("capture experiences?")
                    }

                    centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                        centered - row {
                            centered - h6("Get Started")
                            onClick {
                                // We switch to a different domain in order to get Safari to follow this link as a universal link and open in the app
                                val base =
                                    if (frontendUrl() == "https://app.picme.com") "https://staging.app.picme.com" else "https://app.picme.com"
                                val installReferFinishPath =
                                    pageNavigator.routes.render(InstallReferFinishPage().apply {
                                        refreshToken.value = sessionRefreshToken()
                                        lastScannedQr()
                                            ?.takeIf { it.timestamp.plus(15.minutes) > Clock.System.now() }
                                            ?.let {
                                                shouldOpenQrCodeId.value = it.codeId
                                            }
                                    })?.urlLikePath?.render()
                                ExternalServices.openTab("$base/$installReferFinishPath")
                            }
                        }
                    }
                }
            }
        }
    }
}