package com.picme.components

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.awaitNotNull
import com.lightningkite.readable.invoke
import com.lightningkite.readable.shared
import com.picme.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.toSafeEncoded
import com.picme.views.CollectionSettings
import com.picme.views.ViewTrash
import com.picme.views.share.GuestView

fun Frame.collectionActionsMenu(collectionId: CollectionId): ViewModifiable {
    return sizeConstraints(width = 20.rem) - frame {
        val collection2 = shared { session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection }
        val isOwner1 = shared { ownsPCollection(collection2())() }

        MenuOptionContainerSemantic.onNext - col {

            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.settings, "")
                    text("Settings")
                }
                ::exists { isOwner1() }
                onClick {
                    closePopovers()
                    smallPageNavigator.reset(CollectionSettings(collectionId.raw.toSafeEncoded()))
                }
            }


            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.guests, "")
                    text("Guests")
                }
                ::exists { isOwner1() }
                onClick {
                    closePopovers()
                    ensureVerifiedAccount {
                        smallPageNavigator.reset(GuestView(collectionId.raw.toSafeEncoded()))
                    }
                }
            }


            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.download, "")
                    text("Download all items")
                }
                ::exists {
                    collection2().images().all().isNotEmpty()
                }
                onClick {
                    closePopovers()
                    ensureVerifiedAccount {
                        val message = when (Platform.current) {
                            Platform.Web -> "All items will be downloaded to your device as a zip file."
                            Platform.iOS -> "All items will be saved to your Camera Roll."
                            else -> "All items will be saved to your device."
                        }
                        showConfirmDialog(
                            title = "Confirm Download",
                            confirmLabel = "Download",
                            content = message,
                            onConfirm = {
                                collectionDownload(collectionId)
                            }
                        )
                    }
                }
            }

            MenuOptionSemantic.onNext - button {
                row {
                    icon {
                        description = ""
                        ::source {
                            when (gridSize()) {
                                GridSize.Small -> PIcon.expand
                                GridSize.Large -> PIcon.contract
                            }
                        }
                    }
                    text {
                        ::content {
                            when (gridSize()) {
                                GridSize.Small -> "Large grid size"
                                GridSize.Large -> "Small grid size"
                            }
                        }
                    }
                }
                onClick {
                    closePopovers()
                    gridSize set when (gridSize.value) {
                        GridSize.Large -> GridSize.Small
                        GridSize.Small -> GridSize.Large
                    }
                }
            }


            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.refresh, "")
                    text("Refresh")
                }
                onClick {
                    closePopovers()
                    forceRefresh()
                }
            }


            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.unlink, "")
                    text("Disconnect")
                }
                ::exists { !isOwner1() }
                onClick {
                    closePopovers()
                    showConfirmDialog(
                        title = "Disconnect Collection",
                        content = "Are you sure you want to disconnect from this collection?",
                        warning = "This action cannot be undone.",
                        confirmLabel = "Disconnect",
                        onConfirm = {
                            session.awaitNotNull().collection2.revokeRights(
                                collectionId = collectionId,
                                userId = session.awaitNotNull()
                                    .authenticatedUser().userId
                            )
                            navigateToCollOrLanding(notThisCollection = collectionId)
                        }
                    )
                }
            }


            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.deletetrash, "")
                    text("Delete all items")
                }
                ::exists { isOwner1() }
                onClick {
                    closePopovers()
                    dialogGeneric { close ->
                        h4 {
                            content = "Confirm Delete"
                            align = Align.Center
                        }
                        text("All items will be deleted from the collection.")
                        actionOrCancelSection(
                            onCancel = { close() },
                            actionButton = {
                                important - buttonTheme - button {
                                    centered - text("Delete")
                                    onClick {
                                        session.awaitNotNull().collection2.deleteAllUploads(collectionId)
                                        close()
                                        showToast("Successfully moved images to trash")
                                    }
                                }
                            }
                        )
                    }
                }
            }

            MenuOptionSemantic.onNext - button {
                row {
                    icon(PIcon.viewtrash, "")
                    text("View Trash")
                }
                ::exists { isOwner1() }
                onClick {
                    closePopovers()
                    mainPageNavigator.navigate(ViewTrash(collectionId.raw.let(SafeIds::encode)))
                }
            }
        }
    }
}


