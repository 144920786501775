package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.picme.*
import com.picme.actuals.disableContextMenu
import kotlin.math.round
import kotlin.math.roundToInt

sealed class RecyclerItem(open val id: String)

data class RecyclerSpacer(override val id: String) : RecyclerItem(id)

data class RecyclerInfo(
    override val id: String,
    val thumbnail: ImageSource,
    val mimeType: String
) : RecyclerItem(id)


fun ViewWriter.imageRecyclerView(
    colImages: Readable<List<RecyclerInfo>>,
    selectedImages: SelectItems,
    setup: RecyclerView.() -> Unit,
    navToImage: (suspend (image: RecyclerInfo) -> Page)? = null
) {
    expanding - recyclerView {
        spacing = 2.dp
        log = null
        outerFrame.exists = false
        outerFrame::exists { colImages().isNotEmpty() }
        paddingByEdge = Edges(left = 0.px, right = 0.px, top = 0.px, bottom = 15.rem)
        overdraw = 200.0

        placer = RecyclerViewPlacerVerticalGrid(4, 1.0)
        val gs = Property<Double>(100.0)
        reactiveSuspending { gs.set(gridSize().size.canvasUnits) }
        reactive {
            placer = RecyclerViewGridPlacerSizeBasedColumns(columnsFromViewport = {
                val s = gs()
                (it.width / s).roundToInt()
            }, ratio = 1.0)
        }

        rendererSet = object : RecyclerViewRendererSet<RecyclerItem, String> {
            override fun id(item: RecyclerItem): String = item.id
            val main = object : RecyclerViewRenderer<RecyclerItem> {
                override fun render(
                    viewWriter: ViewWriter,
                    data: Readable<RecyclerItem>,
                    index: Readable<Int>
                ): ViewModifiable = with(viewWriter) {
                    frame {
                        unpadded - card - button {
                            ::exists {
                                data() !is RecyclerSpacer
                            }
                            frame {
                                image {
                                    showLoadingIndicator = false
                                    // TODO: Debug why this is required on iOS
                                    // Otherwise images display at about a third of the expected size. Is it an image caching thing?
                                    // Is the thumbnail really just at a resolution that naturally appears small on high-res iOS pages?
                                    naturalSize = true
                                    disableContextMenu()
                                    ::source {
                                        val img = data()
                                        if (img is RecyclerInfo) {
                                            img.thumbnail
                                        } else null
                                    }
                                    scaleType = ImageScaleType.Crop
                                }
                                atTopStart - sizeConstraints(
                                    width = 2.rem,
                                    height = 2.rem
                                ) - unpadded - important - buttonTheme - frame {
                                    ::exists { selectedImages.selected().contains(data()) }
                                    centered - icon {
                                        source = PIcon.check
                                    }
                                }
                                ImageOverlaySemantic.onNext - frame {
                                    ::exists {
                                        val img = data()
                                        if (img is RecyclerInfo) {
                                            img.mimeType.contains("video")
                                        } else false
                                    }

                                    centered - sizeConstraints(
                                        width = 3.rem,
                                        height = 3.rem
                                    ) - unpadded - icon {
                                        source = PIcon.playIcon
                                    }
                                }
                            }

                            onClick {
                                val d = data()
                                val img = if (d is RecyclerInfo) d else return@onClick
                                if (selectedImages.isSelecting()) {
                                    if (selectedImages.selected().contains(img)) {
                                        selectedImages.removeItem(img)
                                    } else {
                                        selectedImages.addItem(img)
                                    }
                                } else {
                                    navToImage?.let {
                                        pageNavigator.navigate(it(img))
                                        smallPageNavigator.clear()
                                    }
                                }
                            }
                        }
                    }
                }
            }

            override fun renderer(item: RecyclerItem): RecyclerViewRenderer<RecyclerItem> = main
        }
        reactive {
            val d = colImages()
            new.data = object : RecyclerViewData<RecyclerItem, String> {
                override val range: IntRange get() = d.indices
                override fun get(index: Int): RecyclerItem = d[index]
            }
        }
        setup()
    }
}
