package com.picme

import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.NavSemantic
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.PageNavigator
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.Frame
import com.lightningkite.kiteui.views.direct.RowOrCol
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.h6
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.direct.link
import com.lightningkite.kiteui.views.direct.menuButton
import com.lightningkite.kiteui.views.direct.onlyWhen
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.space
import com.lightningkite.kiteui.views.direct.unpadded
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.readable.Action
import com.lightningkite.readable.AppState
import com.lightningkite.readable.ReactiveContext
import com.lightningkite.readable.invoke
import com.lightningkite.readable.reactive

data class PicmeAction(
    val title: String,
    val icon: ReactiveContext.()->Icon,
    val enabled: ReactiveContext.()->Boolean = { true },
    val shown: ReactiveContext.()->Boolean = { true },
    val menu: (Frame.()-> ViewModifiable)? = null,
    val count: (ReactiveContext.()->Int)? = null,
    val destination: (ReactiveContext.()->()-> Page)? = null,
    val resetsStack: Boolean = false,
    val navigator: ViewWriter.()-> PageNavigator = { this.mainPageNavigator },
    val action: suspend ViewWriter.()->Unit = {},
    val group: List<PicmeAction>? = null
) {
    constructor(
        title: String,
        icon: Icon,
        enabled: ReactiveContext.()->Boolean = { true },
        shown: ReactiveContext.()->Boolean = { true },
        menu: (Frame.()-> ViewModifiable)? = null,
        count: (ReactiveContext.()->Int)? = null,
        destination: (()-> Page)? = null,
        resetsStack: Boolean = false,
        navigator: ViewWriter.()-> PageNavigator = { this.mainPageNavigator },
        action: suspend ViewWriter.()->Unit = {}
    ):this(
        title = title,
        icon = { icon },
        enabled = enabled,
        shown = shown,
        resetsStack = resetsStack,
        menu = menu,
        count = count,
        destination = destination?.let { { it } },
        navigator = navigator,
        action = action,
    )
}


fun RowOrCol.renderTopActions(actions: ReactiveContext.()->List<PicmeAction>?): ViewModifiable {
    return unpadded - row {
        reactive {
            clearChildren()
            actions()?.forEach {
                centered - onlyWhen { it.shown() } - actionButton(
                    it,
                    PopoverPreferredDirection.belowLeft
                )
            }
        }
    }
}

fun RowOrCol.renderBottomActions(actions: ReactiveContext.()->List<PicmeAction>?): ViewModifiable {
    return sizeConstraints(height = 72.dp) - NavSemantic.onNext - row {
        ::exists { !AppState.softInputOpen() && actions()?.isNotEmpty() == true }
        reactive {
            clearChildren()
            var isFirstElement: (ReactiveContext.() -> Boolean) = { true }
            actions()?.forEach {
                val p = isFirstElement
                isFirstElement.let { first ->
                    expanding - space(0.0) { ::exists { !first() && it.shown() } }
                }
                isFirstElement = { (p()) && !it.shown() }
                centered - onlyWhen { it.shown() } - actionButton(it, PopoverPreferredDirection.aboveLeft)
            }
        }
    }
}

private fun ViewWriter.actionButton(it: PicmeAction, preferredDirection: PopoverPreferredDirection): RView {
    it.group?.let {
        return row {
            it.forEach {
                onlyWhen { it.shown() } - actionButton(it, preferredDirection)
            }
        }
    }
    fun RView.content(it: PicmeAction): ViewModifiable {
        return it.count?.let { count ->
            dynamicTheme {
                if (count() > 0) SemiImportantSemantic else null
            }
            row {
                icon {
                    ::source { it.icon() }
                    ::description { it.title }
                }
                centered - onlyWhen { count() > 0 } - h6 {
                    ::content { count().toString() }
                }
            }
        } ?: icon {
            ::source { it.icon() }
            ::description { it.title }
        }
    }
    return centered - buttonTheme - when {
        it.menu != null -> menuButton {
            this.preferredDirection = preferredDirection
            requireClick = true
            opensMenu { it.menu(this) }
            content(it)
            ::enabled { it.enabled() }
        }

        it.destination != null -> link {
            content(it)
            ::enabled { it.enabled() }
            onNavigator = it.navigator(this)
            resetsStack = it.resetsStack
            ::to { it.destination() }
            onNavigate { it.action(this) }
        }

        else -> button {
            content(it)
            ::enabled { it.enabled() }
            action = Action(it.title) {
                it.action(this@button)
            }
        }
    }
}