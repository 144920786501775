package com.picme.views.comments

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.DateTime
import com.picme.sdk2.generated.UserInfo

interface CommentsPage : Page, HasSubtitle {
    override val subtitle: Readable<String>
        get() = Constant("Comments")
    val control: Readable<CommentsControl>
    val noComments: String

    override fun ViewWriter.render(): ViewModifiable {
        return col {
            padding = 0.rem
            expanding - frame {
                exists = false
                ::exists {
                    control().comments()?.isNotEmpty() != true
                }
                centered - EmptyTextSemantic.onNext - text {
                    content = noComments
                    align = Align.Center
                }
            }

            expanding - recyclerView {
                outerFrame.paddingByEdge = Edges(left = 0.px, top = 0.px, bottom = 0.px, right = (-0.5).rem)
                ::exists { control().comments()?.isNotEmpty() == true }
                children(
                    items = shared {
                        (control().comments()?.sortedBy { it.createdAt.epochSeconds } ?: listOf())
                    },
                    id = Comment::id
                ) { comment ->
                    row {
                        val name = shared { comment().userInfo.let { if (it.isMe) "Me" else it.name } }
                        space(2.0) {
                            ::exists { comment().userInfo.isMe }
                        }
                        atTopStart - userAvatar(comment.lens { it.userInfo }, 1.5.rem)
                        expanding - col {
                            spacing = 4.dp
                            row {
                                expanding - CommentCaptionSemantic.onNext - text { ::content { name() } }
                                CommentCaptionSemantic.onNext - text {
                                    ::content { comment().createdAt.renderRelativeToString() }
                                }
                            }
                            text {
                                dynamicTheme {
                                    if (comment().userInfo.isMe) MyCommentCardSemantic
                                    else CommentItemCardSemantic
                                }
                                ::content { comment().message }
                            }
                        }
                        centered - buttonTheme - unpadded - menuButton {
                            requireClick = true
                            centered - icon(PIcon.dotsvertical, "More")
                            opensMenu {
                                MenuOptionContainerSemantic.onNext - col {
                                    MenuOptionSemantic.onNext - button {
                                        row {
                                            icon(PIcon.edit, "")
                                            text("Edit")
                                        }
                                        ::exists { comment().userInfo.isMe }
                                        onClick {
                                            closePopovers()
                                            dialogGeneric { close ->
                                                col {
                                                    centered - h4("Edit comment")
                                                    val dirty = Property("")
                                                    reactive {
                                                        dirty.value = comment().message
                                                    }
                                                    fieldTheme - textArea {
                                                        content bind dirty
                                                        hint = "Message"
                                                    }
                                                    space()
                                                    actionOrCancelSection(
                                                        onCancel = { close() },
                                                        actionButton = {
                                                            important - buttonTheme - button {
                                                                centered - text("Save")
                                                                action = Action(
                                                                    "Save",
                                                                    action = {
                                                                        control().modify(comment().id, dirty())
                                                                        close()
                                                                    }
                                                                )
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    MenuOptionSemantic.onNext - button {
                                        row {
                                            icon(PIcon.copy, "")
                                            text("Copy")
                                        }
                                        onClick {
                                            closePopovers()
                                            ExternalServices.setClipboardText(comment().message)
                                            showToast("Copied to clipboard")
                                        }
                                    }
                                    MenuOptionSemantic.onNext - button {
                                        row {
                                            icon(PIcon.trash, "")
                                            text("Delete")
                                        }
                                        ::exists { control().canDelete(comment()) }
                                        onClick {
                                            closePopovers()
                                            showConfirmDialog(
                                                title = "Confirm Delete",
                                                content = "This comment will be deleted.",
                                                confirmLabel = "Delete",
                                                warning = "This action cannot be undone.",
                                                onConfirm = {
                                                    control().delete(comment())
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        space(1.0)
                    }
                }
                reactive { scrollToIndex((control().comments()?.size ?: 1) - 1, Align.End) }
            }

            separator()

            sizeConstraints(minHeight = 3.rem) - padded - row {
                val message = Property("")
                val action = Action("Submit comment", action = {
                    val m = message()
                    message.value = ""
                    control().postComment(m)
                })

                expanding - centered - fieldTheme - textArea {
                    content bind message
                    hint = "Type your message here..."
                    this.action = action
                }
                unpadded - (centered - buttonTheme) - button {
                    padded - icon(PIcon.send, "Create Comment")
                    ::enabled { message().trim().isNotBlank() }
                    this.action = action
                }
            }
        }
    }
}

data class Comment(
    val id: String,
    val userInfo: UserInfo,
    val message: String,
    val createdAt: DateTime,
)

