package com.picme.sdk2.generated.poll

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class PollHandlerApiLive(val endpoints: PollHandlerEndpoints, val accessToken: suspend () -> String?): PollHandlerApi {
/** Submits a poll selection for the currently-authenticated user. **/
    override suspend fun putMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        selectedChoiceId: String,
    ): Unit = endpoints.putMyPollSelection(
        scope = scope,
        pollOrEntityTypeId = pollOrEntityTypeId,
        pollOrEntityId = pollOrEntityId,
        selectedChoiceId = selectedChoiceId,
        accessToken = accessToken,
    )
/** Gets any poll selection for the currently-authenticated user. **/
    override suspend fun getMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
    ): GetPollSelectionResponse = endpoints.getMyPollSelection(
        scope = scope,
        pollOrEntityTypeId = pollOrEntityTypeId,
        pollOrEntityId = pollOrEntityId,
        accessToken = accessToken,
    )
/** Lists the poll selection details. **/
    override suspend fun listPollSelections(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        continuation: String?,
    ): ListPollSelectionsResponse = endpoints.listPollSelections(
        scope = scope,
        pollOrEntityTypeId = pollOrEntityTypeId,
        pollOrEntityId = pollOrEntityId,
        continuation = continuation,
        accessToken = accessToken,
    )
/** Lists the poll results summary. **/
    override suspend fun listPollResultSummary(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
    ): ListPollResultSummaryResponse = endpoints.listPollResultSummary(
        scope = scope,
        pollOrEntityTypeId = pollOrEntityTypeId,
        pollOrEntityId = pollOrEntityId,
        accessToken = accessToken,
    )
/** Patches a standalone poll. **/
    override suspend fun patchPoll(
        scope: DataScope,
        pollId: PollId,
        body: PatchPollBody,
    ): PatchPollResponse = endpoints.patchPoll(
        scope = scope,
        pollId = pollId,
        body = body,
        accessToken = accessToken,
    )
/** Deletes the specified standalone poll. **/
    override suspend fun deletePoll(
        scope: DataScope,
        pollId: PollId,
    ): DeletePollResponse = endpoints.deletePoll(
        scope = scope,
        pollId = pollId,
        accessToken = accessToken,
    )
/** Creates a standalone poll. **/
    override suspend fun createPoll(
        scope: DataScope,
        body: CreatePollBody,
    ): CreatePollResponse = endpoints.createPoll(
        scope = scope,
        body = body,
        accessToken = accessToken,
    )
/** Lists standalone polls that the user has rights to. **/
    override suspend fun listPolls(
        scope: DataScope,
        filter: PollQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
    ): ListPollsResponse = endpoints.listPolls(
        scope = scope,
        filter = filter,
        itemsPerPage = itemsPerPage,
        continuation = continuation,
        forUserId = forUserId,
        accessToken = accessToken,
    )
}
