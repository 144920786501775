package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme
import com.picme.*
import com.picme.sdk2.generated.UserInfo

fun ViewWriter.userAvatar(user: Readable<UserInfo>, size: Dimension = 3.rem): ViewModifiable {
    return frame {
        val image = sharedSuspending {
            user().getProfilePicture.imageIfExists()
        }
        val name = user.lens { it.name.anonymousIfEmpty() }

        centered - sizeConstraints(
            width = size,
            height = size
        ) - RoundedImageSemantic.onNext - frame {
            dynamicTheme {
                val c = if (user().isMe) {
                    Color.fromInt(selectedThemePrimaryColor.value)
                } else user().name.userIconColor()

                if (image() == null) {
                    ThemeDerivation {
                        it.copy(
                            id = "without-image-${c.hashCode()}",
                            background = c,
                            foreground = Color.white
                        ).withBack
                    }
                } else {
                    null
                }
            }
            centered - ProfileLetterSemantic[size].onNext - text {
                ::exists { image() == null }
                ::content { name().take(1) }
            }
            RoundedImageSemantic.onNext - image {
                ::exists { image() != null }
                scaleType = ImageScaleType.Crop
                refreshOnParamChange = true
                ::source { image() }
            }
        }
    }
}

fun String.anonymousIfEmpty(): String = this.ifEmpty { "Anonymous" }

fun String.userIconColor(): Color {
    val notEmpty = this.anonymousIfEmpty()
    val hash = notEmpty.hashCode()
    val r = (hash shr 16) and 0xFF
    val g = (hash shr 8) and 0xFF
    val b = hash and 0xFF
    return Color.fromHexString("#${r.toString(16)}${g.toString(16)}${b.toString(16)}")
}