package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.toast
import com.picme.*
import com.picme.LinkSemantic
import com.picme.components.*
import com.picme.sdk2.*
import com.picme.session


@Routable("confirm-email")
class ConfirmEmail : Page, UnauthPage {
    var userPassword: String? = null
    val code = Property("")
    val codeFieldError = Property<String?>(null)
    val invalidCode: Property<Boolean> = Property(false)
    override fun ViewWriter.render(): ViewModifiable {
        val phoneOrEmailWording = shared {
            when {
                emailOrPhone().isPhone -> "phone number"
                else -> "email"
            }
        }

        return authPage("Confirm Account", true) {
            text {
                ::content{ "Enter the verification code sent to your ${phoneOrEmailWording()}, ${emailOrPhone()}." }
            }

            reactiveScope {
                if (code().isNotBlank()) codeFieldError.value = null
            }
            val action = Action(
                "Submit",
                 action = {
                     if (code().isEmpty()) {
                         codeFieldError set "Please enter a verification code."
                         return@Action
                     }
                     try {
                         session()?.authentication?.continueSignUpUser(
                             emailOrPhoneNumber = emailOrPhone().lowercase(),
                             verificationCode = code(),
                             name = null,
                             setTosRead = true,
                         ) ?: unauthApi().authenticationHandler.continueSignUpUser(
                             emailOrPhoneNumber = emailOrPhone().lowercase(),
                             verificationCode = code(),
                             name = null,
                             setTosRead = true,
                             accessToken = { null },
                         )
                         if (userPassword == null) {
                             pageNavigator.navigate(LogInPage)
                             return@Action
                         }

                         val loginResponse = session()?.authentication?.startAuthentication(
                             emailOrPhoneNumber = emailOrPhone().lowercase(),
                             password = userPassword!!
                         ) ?: unauthApi().authenticationHandler.startAuthentication(
                             emailOrPhoneNumber = emailOrPhone().lowercase(),
                             password = userPassword!!
                         )
                         val auth = loginResponse.successfulAuthentication
                         if (auth == null) {
                             alert("Error", "More authentication required")
                             return@Action
                         }

                         if (auth.authenticatedUser.name.isBlank()) {
                             pageNavigator.reset(FinishCreateAccount).also {
                                 sessionRefreshToken set auth.authenticated.refreshToken
                             }
                         } else {
                             navigateToCollOrLanding()
                         }
                     } catch (e: Exception) {
                         invalidCode set true
                     }
                 }
            )
            field("Verification Code") {
                textInput {
                    this.action = action
                    hint = "Verification Code"
                    content bind code
                    reactive {
                        codeFieldError()?.let { throw PlainTextException(it, "Error") }
                    }
                }
            }

            onlyWhen { invalidCode() } - danger -
                    centered - text("Invalid verification code.")



            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
            actionOrCancelSection(
                onCancel = { pageNavigator.reset(LoginOrSignUp()) },
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Continue")
                        this.action = action
                    }
                }
            )
            gravityCentered - compact - LinkSemantic.onNext - button {
                centered - text("Resend verification code")
                onClick {
                    unauthApi().authenticationHandler.resendAccountVerification(emailOrPhone().lowercase())
                    showToast("Verification code sent")
                }
            }
        }
    }
}