package com.picme.views.comments

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.readable.Property
import com.lightningkite.readable.Readable
import com.lightningkite.readable.lens
import com.lightningkite.readable.shared
import com.lightningkite.readable.waitForNotNull
import com.picme.AllowPartialExpansion
import com.picme.ShowsLiveUpload
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.session
import com.picme.sessionNotNull
import com.picme.views.PageForCollection

@Routable("collections/{urlSafeId}/items/comments")
class ItemCommentsPage(override val urlSafeId: String): CommentsPage, AllowPartialExpansion, PageForCollection(),
    ShowsLiveUpload {

    override fun forOtherCollection(urlSafeId: String): PageForCollection = ItemCommentsPage(urlSafeId)

    override val noComments: String
        get() = "Be the first to comment on this photo!"

    constructor(urlSafeId: String, item: UploadId): this(urlSafeId) {
        this.item.value = item
    }

    @QueryParameter
    override val item: Property<UploadId?> = Property(null)
    val details: Readable<GetUploadResponse2> = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = collection().collection.collectionId,
            uploadId = item() ?: return@shared null
        )()
    }.waitForNotNull
    override val control = details.lens {
        BaseCommentsControl(
            ScopedEntity(
                DataScope(it.upload.uploadGlobalId.scope),
                it.upload.uploadId.raw,
                RecordTypeId(it.upload.uploadGlobalId.typeId)
            ),
            canDelete = {comment ->
                comment.userInfo.userId == sessionNotNull.state.raw.authenticatedUser.state.raw.userId
            }
        )
    }
}