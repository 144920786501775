package com.picme.components

import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.readable.Property
import com.picme.PIcon
import com.picme.spaceBetweenFieldsAndAction


val createUserName = Property("")

fun RowOrCol.askForNameForm(action: Action) {
    fieldTheme - row {
        padding = 0.dp
        padded - expanding - centered - textInput {
            this.requestFocus()
            content bind createUserName
            hint = "Enter name"
            this.action = action
        }

        separator { }

        centered - button {
            icon {
                source = PIcon.refresh.copy(width = 1.5.rem, height = 1.5.rem)
            }
            onClick {
                createUserName set "Anonymous ${randomAnimals.random()}"
            }
        }
        space()
    }

    spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
    important - buttonTheme - button {
        centered - text("Continue")
        ::enabled { createUserName() != "" }
        this.action = action
    }
}

private val randomAnimals = listOf(
    "mandrill",
    "gemsbok",
    "panther",
    "bumble bee",
    "lovebird",
    "ocelot",
    "budgerigar",
    "mole",
    "eagle owl",
    "crocodile",
    "badger",
    "guinea pig",
    "steer",
    "reindeer",
    "mouse",
    "guanaco",
    "monkey",
    "basilisk",
    "walrus",
    "blue crab",
    "hare",
    "coati",
    "grizzly bear",
    "gnu",
    "porcupine",
    "stallion",
    "lemur",
    "tapir",
    "polar bear",
    "addax",
    "meerkat",
    "eland",
    "cougar",
    "gila monster",
    "mule",
    "muskrat",
    "aoudad",
    "hamster",
    "ferret",
    "peccary",
    "baboon",
    "armadillo",
    "jackal",
    "kangaroo",
    "chameleon",
    "marmoset",
    "fawn",
    "chinchilla",
    "elephant",
    "anteater",
    "horse",
    "seal",
    "impala",
    "gazelle",
    "fox",
    "mountain goat",
    "ape",
    "panda",
    "puma",
    "squirrel",
    "waterbuck",
    "moose",
    "civet",
    "chicken",
    "jerboa",
    "bunny",
    "hyena",
    "musk deer",
    "chipmunk",
    "mustang",
    "doe",
    "ram",
    "sloth",
    "goat",
    "rabbit",
    "rooster",
    "cheetah",
    "wolf",
    "elk",
    "whale",
    "llama",
    "gorilla",
    "gopher",
    "giraffe",
    "warthog",
    "raccoon",
    "ibex",
    "iguana",
    "puppy",
    "hedgehog",
    "buffalo",
    "crow",
    "kitten",
    "otter",
    "mare",
    "orangutan",
    "dormouse",
    "highland cow",
    "chamois",
    "springbok",
    "porpoise",
    "lion",
    "rat",
    "wolverine",
    "ox",
    "wildcat",
    "bald eagle",
    "antelope",
    "alligator",
    "weasel",
    "cat",
    "turtle",
    "bighorn",
    "marten",
    "okapi",
    "vicuna",
    "newt",
    "bear",
    "canary",
    "ewe",
    "hog",
    "zebu",
    "snake",
    "yak",
    "finch",
    "alpaca",
    "tiger",
    "pronghorn",
    "snowy owl",
    "toad",
    "burro",
    "sheep",
    "skunk",
    "dromedary",
    "prairie dog",
    "frog",
    "deer",
    "capybara",
    "mongoose",
    "aardvark",
    "boar",
    "rhinoceros",
    "quagga",
    "argali",
    "beaver",
    "mink",
    "starfish",
    "ground hog",
    "hippopotamus",
    "bull",
    "opossum",
    "pony",
    "lynx",
    "lizard",
    "cow",
    "dingo",
    "coyote",
    "dog",
    "parrot",
    "leopard",
    "oryx",
    "zebra",
    "salamander",
    "camel",
    "bison",
    "silver fox",
    "ermine",
    "donkey",
    "woodchuck",
    "wombat",
    "lamb",
    "colt",
    "parakeet",
    "pig",
    "shrew",
    "chimpanzee",
    "octopus",
    "fish",
    "jaguar",
    "koala",
    "bat",
    "hartebeest",
)