package com.picme.sdk2.generated.discussion

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


/** A struct that holds a discussion message ID. **/
@Serializable
@JvmInline
value class DiscussionMessageId(val raw: String = "")

/** A record containing the structure of the body for the <see cref="M:PicMeApi.DiscussionApis.PatchDiscussionMessage(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,SimpleDatabase.DiscussionMessageId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchDiscussionMessageBody(
    val text: Retainable<Text> = Retainable.retain, 
)

/** The response for <see cref="M:PicMeApi.DiscussionApis.PatchDiscussionMessage(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,SimpleDatabase.DiscussionMessageId,System.Text.Json.JsonDocument)" />. **/
@Serializable
data class PatchDiscussionMessageResponse(
    val message: DiscussionMessage = DiscussionMessage(), 
)

/** A record that holds information about a discussion message.
            Discussion messages should be scoped to a discussion or to some other entity (each of which has an implicit discussion). **/
@Serializable
data class DiscussionMessage(
    val discussionMessageId: DiscussionMessageId = DiscussionMessageId(""), 
    val discussionMessageGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val text: Text = Text(""), 
    val creatorUserId: UserId = UserId(""), 
    val creationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val modifierUserId: UserId = UserId(""), 
    val modificationTime: DateTime = Instant.fromEpochMilliseconds(0), 
)

/** A record containing the response after deleting a colleciton. **/
@Serializable
data class DeleteDiscussionMessageResponse(
    val discussionMessageGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val discussionMessageId: DiscussionMessageId = DiscussionMessageId(""), 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.DiscussionApis.CreateDiscussionMessage(SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,PicMeApi.CreateDiscussionMessageBody)" /> API. **/
@Serializable
data class CreateDiscussionMessageBody(
    val text: Text = Text(""), 
)

/** The response for <see cref="M:PicMeApi.DiscussionApis.CreateDiscussionMessage(SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,PicMeApi.CreateDiscussionMessageBody)" />. **/
@Serializable
data class CreateDiscussionMessageResponse(
    val discussionMessageId: DiscussionMessageId = DiscussionMessageId(""), 
)

/** A record containing the response from the <see cref="M:PicMeApi.DiscussionApis.ListDiscussionMessages(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,System.String)" /> API. **/
@Serializable
data class ListDiscussionMessagesResponse(
    val entries: List<ListedDiscussionMessage> = listOf(), 
    val userInfo: List<UserInfo> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the data for one discussion message returned in a list of discussion messages. **/
@Serializable
data class ListedDiscussionMessage(
    val message: DiscussionMessage = DiscussionMessage(), 
)

/** A struct that holds a discussion id. **/
@Serializable
@JvmInline
value class DiscussionId(val raw: String = "")

/** A record containing the structure of the body for the <see cref="M:PicMeApi.DiscussionApis.PatchDiscussion(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.DiscussionId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchDiscussionBody(
    val topic: Retainable<Text> = Retainable.retain, 
)

/** The response for <see cref="M:PicMeApi.DiscussionApis.PatchDiscussion(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.DiscussionId,System.Text.Json.JsonDocument)" />. **/
@Serializable
data class PatchDiscussionResponse(
    val discussion: Discussion = Discussion(), 
)

/** A record that holds information about a discussion. **/
@Serializable
data class Discussion(
    val discussionId: DiscussionId = DiscussionId(""), 
    val discussionGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val topic: Text = Text(""), 
    val creatorUserId: UserId = UserId(""), 
    val creationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val modifierUserId: UserId = UserId(""), 
    val modificationTime: DateTime = Instant.fromEpochMilliseconds(0), 
)

/** A record ctonaining the discussion metadata for the specified discussion. **/
@Serializable
data class GetDiscussionResponse(
    val discussion: Discussion = Discussion(), 
)

/** A record containing the response after deleting a colleciton. **/
@Serializable
data class DeleteDiscussionResponse(
    val discussionId: DiscussionId = DiscussionId(""), 
    val discussionGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.DiscussionApis.CreateDiscussion(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.CreateDiscussionBody)" /> API. **/
@Serializable
data class CreateDiscussionBody(
    val topic: Text = Text(""), 
)

/** The response for <see cref="M:PicMeApi.DiscussionApis.CreateDiscussion(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.CreateDiscussionBody)" />. **/
@Serializable
data class CreateDiscussionResponse(
    val discussionId: DiscussionId = DiscussionId(""), 
    val discussionGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record that holds information about an upload query. **/
@Serializable
data class DiscussionQuery(
    val creationTime: DateTimeRange? = null, 
    val creatorUserId: UserId? = null, 
    val modificationTime: DateTimeRange? = null, 
    val topic: String? = null, 
    val isEmpty: Boolean = false, 
)

/** A record containing the response from the <see cref="M:PicMeApi.DiscussionApis.ListDiscussions(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.DiscussionQuery,System.Int32,System.String,System.Nullable{SimpleDatabase.UserId})" /> API. **/
@Serializable
data class ListDiscussionsResponse(
    val discussions: List<ListedDiscussion> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the data for a discussion returned in a list of discussions. **/
@Serializable
data class ListedDiscussion(
    val discussion: Discussion = Discussion(), 
    val userRights: Rights = Rights.values().first(), 
    val userParticipationRights: Rights = Rights.values().first(), 
)

