@file:OptIn(ExperimentalEncodingApi::class)

package com.picme.views.share

import com.lightningkite.kiteui.Blob
import com.lightningkite.kiteui.DownloadLocation
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.ButtonSemantic
import com.lightningkite.kiteui.models.FieldLabelSemantic
import com.lightningkite.kiteui.models.ImageRaw
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.WordBreak
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.compact
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.activityIndicator
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.kiteui.views.direct.h6
import com.lightningkite.kiteui.views.direct.image
import com.lightningkite.kiteui.views.direct.menuButton
import com.lightningkite.kiteui.views.direct.onClick
import com.lightningkite.kiteui.views.direct.padded
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.subtext
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.forEach
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.awaitNotNull
import com.lightningkite.readable.invoke
import com.lightningkite.readable.shared
import com.lightningkite.readable.sharedSuspending
import com.picme.ControlsStatusBarColor
import com.picme.FadedSemantic
import com.picme.HasSubtitle
import com.picme.InviteType
import com.picme.MenuOptionContainerSemantic
import com.picme.MenuOptionSemantic
import com.picme.PIcon
import com.picme.SemiImportantSemantic
import com.picme.actuals.toBlob
import com.picme.components.*
import com.picme.getQrCodesForCollection
import com.picme.sdk2.GenerateQr
import com.picme.sdk2.QrType
import com.picme.sdk2.generated.CreateQrCodeImageResponse
import com.picme.sdk2.generated.InviteCode
import com.picme.sdk2.generated.PatchInviteCodeBody
import com.picme.session
import com.picme.sharePath
import com.picme.validDownloadableName
import com.picme.views.PageForCollection
import com.picme.views.QrAccept
import com.picme.views.frontendUrl
import kotlinx.coroutines.launch
import kotlinx.datetime.DatePeriod
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


@Routable("/collection/{urlSafeId}/share")
class ShareView(override val urlSafeId: String) : ControlsStatusBarColor, PageForCollection(), HasSubtitle {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = ShareView(urlSafeId)
    override val subtitle: Readable<String> get() = Constant("Share")

    private val qrCodes = sharedSuspending {
        val coll = session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
        getQrCodesForCollection(coll).reversed()
    }

    private val selectedInvite = Property<ViewInviteInfo?>(null)

    val inviteIsEnabled = shared {
        selectedInvite()?.inviteCode?.temporarilyDisabled != true
    }

    val pngQrCode = sharedSuspending {
        GenerateQr.getQrCode(
            hostName = frontendUrl(),
            path = selectedInvite()?.inviteCode?.sharePath() ?: "",
            QrType.Png
        ).await()
    }

    val fileBlob = shared {
        Base64.decode(pngQrCode().base64Encoded)
            .toBlob(pngQrCode().contentType.raw)
    }


    override fun ViewWriter.render(): ViewModifiable {
        launch { selectedInvite.value = qrCodes().find { it.inviteCode.name == "Share Collection" } }
        return formColumn(
            fieldSection = {
                unStyledField("Permissions") {
                    col {
                        ::exists { selectedInvite.state().ready }
                        forEach(qrCodes) { qrInfo ->
                            row {
                                radioButton {
                                    checked bind selectedInvite.equalTo(qrInfo)
                                }
                                centered - FadedSemantic.onNext - text {
                                    ::content {
                                        when (qrInfo.inviteCode.name) {
                                            "Request Photos" -> "Upload Photos Only"
                                            "Share Collection" -> "Upload Photos & View Collection"
                                            else -> ""
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                col {
//                    spacing = 0.px
                    centered - sizeConstraints(width = 12.rem, height = 12.rem) - image {
                        exists = false
                        ::exists { pngQrCode().base64Encoded; true }
                        ::source {
                            ImageRaw(fileBlob())
                        }
                        ::opacity { if (inviteIsEnabled()) 1.0 else 0.1 }
                    }
                    subtext {
                        align = Align.Center
                        wraps = true
                        wordBreak = WordBreak.BreakAll
                        ::content { selectedInvite()?.link?.removePrefix("https://") ?: "" }
                    }
                }

                centered - row {
                    card - ButtonSemantic.onNext - menuButton {
                        ::enabled { inviteIsEnabled() }
                        requireClick = true
                        preferredDirection = PopoverPreferredDirection.belowRight
                        icon(PIcon.download, "Download")
                        opensMenu {
                            frame {
                                downloadOptions(
                                    this@ShareView.selectedInvite,
                                    this@ShareView.pngQrCode,
                                    this@ShareView.fileBlob
                                )
                            }
                        }
                    }
                    card - ButtonSemantic.onNext - button {
                        icon(PIcon.eye, "eye")
                        ::enabled { inviteIsEnabled() }
                        onClick {
//                            if (Platform.current === Platform.Web) {
//                                ExternalServices.openTab(selectedInvite()?.link ?: "")
//                            } else {
                                mainPageNavigator.navigate(
                                    QrAccept(
                                        selectedInvite()?.inviteCode?.inviteCodeId?.raw ?: ""
                                    )
                                )
//                            }
                        }
                    }
                    card - ButtonSemantic.onNext - button {
                        icon(PIcon.copy, "copy")
                        ::enabled { inviteIsEnabled() }
                        onClick {
                            ExternalServices.setClipboardText(selectedInvite()?.link ?: "")
                            showToast("Url copied to clipboard")
                        }
                    }
                    card - ButtonSemantic.onNext - button {
                        icon(PIcon.share, "share")
                        ::enabled { inviteIsEnabled() }
                        onClick {
                            if (selectedInvite()?.permission?.name != null && selectedInvite()?.link != null) {
                                ExternalServices.share(
                                    title = selectedInvite()!!.permission.name,
                                    url = selectedInvite()!!.link
                                )
                            }
                        }
                    }
                }
            },
            actionSection = {
                centered - onlyWhen { inviteIsEnabled() } - sizeConstraints(width = 15.rem) - SemiImportantSemantic.onNext - danger - buttonTheme - button {
                    centered - text("Pause This Invitation")
                    onClick {
                        showConfirmDialog(
                            title = "Pause Invitation",
                            content = "Are you sure you want to pause this invitation?",
                            confirmLabel = "Pause",
                            onConfirm = {
                                session.awaitNotNull().collection2.patchInviteCode(
                                    selectedInvite.awaitNotNull().inviteCode.inviteCodeId,
                                    PatchInviteCodeBody(temporarilyDisabled = true)
                                )
                                pageNavigator.dismiss()
                            },
                        )
                    }
                }
                centered - onlyWhen { !inviteIsEnabled() } - sizeConstraints(width = 15.rem) - SemiImportantSemantic.onNext - danger - buttonTheme - button {
                    centered - text("Enable This Invitation")
                    onClick {
                        showConfirmDialog(
                            title = "Confirm Enable",
                            content = "This Invitation will be enabled.",
                            confirmLabel = "Enable",
                            onConfirm = {
                                session.awaitNotNull().collection2.patchInviteCode(
                                    selectedInvite.awaitNotNull().inviteCode.inviteCodeId,
                                    PatchInviteCodeBody(temporarilyDisabled = false)
                                )
                                pageNavigator.dismiss()
                            },
                        )
                    }
                }
            })
    }
}
data class ViewInviteInfo(
    val inviteCode: InviteCode,
    val permission: InviteType,
    val link: String,
)

fun ContainingView.downloadOptions(
    qrCodeInfo: Readable<ViewInviteInfo?>,
    pngQrCode: Readable<CreateQrCodeImageResponse>,
    fileBlob: Readable<Blob>
) {
    MenuOptionContainerSemantic.onNext - col {
        centered - padded - bold - text("File Format")
        MenuOptionSemantic.onNext - button {
            text(".png")
            onClick {
                ExternalServices.download(
                    name = "${qrCodeInfo()?.inviteCode?.name}${pngQrCode().fileExtension}".replace(
                        " ",
                        "_"
                    ).validDownloadableName(),
                    blob = fileBlob(),
                    preferredDestination = DownloadLocation.Pictures
                )
                val message = when (Platform.current) {
                    Platform.Web -> "QR code downloaded"
                    Platform.iOS -> "QR code saved to Camera Roll"
                    else -> "QR code downloaded"
                }
                showToast(message)
                closePopovers()
            }
        }
        MenuOptionSemantic.onNext - button {
            text(".svg")
            onClick {
                val newQrCode = GenerateQr.getQrCode(
                    hostName = frontendUrl(),
                    path = qrCodeInfo()?.inviteCode?.sharePath() ?: "",
                    QrType.Svg
                ).await()

                ExternalServices.download(
                    name = "${qrCodeInfo()?.inviteCode?.name}.svg".validDownloadableName(),
                    blob = Base64.decode(newQrCode.base64Encoded).toBlob(newQrCode.contentType.raw),
                )
                showToast("QR code downloaded")
                closePopovers()
            }
        }
    }
}