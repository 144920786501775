package com.picme.sso

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.readable.Action
import com.lightningkite.readable.Property
import com.lightningkite.readable.invoke
import com.lightningkite.readable.reactive
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.*
import com.picme.sdk2.Session
import com.picme.sdk2.generated.UserAuthenticated
import com.picme.sdk2.generated.sso.ContinueAppleAuthenticationResponse
import com.picme.sdk2.unauthApi

import com.picme.views.LoginOrSignUp
import com.picme.views.selectedBackend

fun ViewWriter.startSSO() {
    col {
        separatorWithChildren("OR")
        googleSsoOverride?.let {
            card - buttonTheme - button {
                centered - row {
                    sizeConstraints(width = 24.dp, height = 24.dp) - image { source = Vectors.google; description = "" }
                    text("Log in with Google")
                }
                action = Action("Google Login", Icon.login, action = it)
            }
        } ?: run {
            card - buttonTheme - externalLink {
                centered - row {
                    sizeConstraints(width = 24.dp, height = 24.dp) - image { source = Vectors.google; description = "" }
                    text("Log in with Google")
                }
                ::to { "${selectedBackend()}/a/SsoHandler/sso/google?configName=&responseUriId=&setTosRead=true" }
            }
        }
        appleSsoOverride?.let { authenticateWithApple ->
            card - buttonTheme - button {
                centered - row {
                    sizeConstraints(width = 24.dp, height = 24.dp) - image { source = Vectors.apple; description = "" }
                    text("Log in with Apple")
                }
                action = Action("Apple Login", Icon.login, action = {
                    authenticateWithApple().let {
                        val userAuth = it.successfulAuthentication
                        if (userAuth == null) {
                            mainPageNavigator.reset(LoginOrSignUp(false))
                        } else {
                            sessionRefreshToken set userAuth.authenticated.refreshToken
                            navigateToCollOrLanding()
                        }
                    }
                })
            }
        } ?: run {
            card - buttonTheme - externalLink {
                centered - row {
                    sizeConstraints(width = 24.dp, height = 24.dp) - image { source = Vectors.apple; description = "" }
                    text("Log in with Apple")
                }
                ::to { "${selectedBackend()}/a/SsoHandler/sso/apple?configName=&responseUriId=&setTosRead=true" }
            }
        }
    }
}

var appleSsoOverride: (suspend () -> ContinueAppleAuthenticationResponse)? = null
var googleSsoOverride: (suspend () -> Unit)? = null

fun ViewWriter.separatorWithChildren(text: String) {
    sizeConstraints(height = 2.rem) - row {
        spacingAndPadding = 8.dp
        expanding - col { expanding - stack(); separator(); expanding - stack() }
        centered - subtext(text)
        expanding - col { expanding - stack(); separator(); expanding - stack() }
    }
}
