package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.CachedApi
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.seconds


@Routable("account")
object ProfilePage : Page {
    override val title = Constant("Account")
    override fun ViewWriter.render(): ViewModifiable {
        val editName = Property("")
        val email = Property("")
        val phone = Property("")
        val profileImage = Property(ImageUploadInfo())

        reactiveSuspending {
            sessionNotNull().authentication.getMyUserInfo()
                .let {
                    editName set it.userData.name
                    email set it.userData.email
                    phone set it.userData.phoneNumber
                }
            profileImage set ImageUploadInfo(original = sessionNotNull().authentication.profileImage())
        }

        onRemove {
            selectedThemePrimaryColorDirty.value = null
            selectedThemeOptionDirty.value = null
        }

        return formColumn(
            fieldSection = {
                field("Name") {
                    textInput {
                        hint = "Name"
                        content bind editName
                        keyboardHints = KeyboardHints.title
                        enabled = false
                        ::enabled { email() != "" || phone() != "" }
                    }
                }
                onlyWhen { email() != "" } - field("Email") {
                    textInput {
                        hint = "Email"
                        content bind email
                        keyboardHints = KeyboardHints.email
                    }
                }
                onlyWhen { phone() != "" } - field("Phone Number") {
                    textInput {
                        hint = "Phone Number"
                        content bind phone
                        keyboardHints = KeyboardHints.phone
                    }
                }
                sizeConstraints(minHeight = 12.rem) - centered - col {
                    ::exists { email() == "" && phone() == "" }
                    centered - activityIndicator()
                }

                unStyledField("Profile Image") {
                    imagePlaceholderPicker(profileImage, "Upload image")
                }

                unStyledField("Accent Color") {
                    row {
                        forEach(
                            Constant(
                                listOf(
                                    Color.neoPicmePurple,
                                    Color.neoPicmeBlue
                                ) + if (devSettings.value) listOf(
                                    Color.picmePink,
                                    Color.fromHexString("#feac39")
                                ) else listOf()
                            )
                        ) { color ->
                            sizeConstraints(width = 36.dp, height = 36.dp) - RoundedImageSemantic.onNext - button {
                                dynamicTheme {
                                    if (color.hashCode() == Color.fromInt(
                                            selectedThemePrimaryColorDirty() ?: selectedThemePrimaryColor()
                                        ).hashCode()
                                    ) {
                                        ThemeDerivation {
                                            it.copy(
                                                id = "color-selected-${color.hashCode()}",
                                                background = color,
                                                foreground = it.background,
                                                cascading = true
                                            ).withBack
                                        }
                                    } else {
                                        ThemeDerivation {
                                            it.copy(
                                                id = "color-${color.hashCode()}",
                                                background = color,
                                                foreground = color,
                                                cascading = true
                                            ).withBack
                                        }
                                    }

                                }
                                padding = 2.dp
                                RoundedImageSemantic.onNext - frame {
                                    themeChoice += SelectedAccentColorOutline
                                }
                                onClick {
                                    selectedThemePrimaryColorDirty set color.toInt()
                                }
                            }
                        }
                    }
                }
                unStyledField("Theme Preference") {
                    padded - scrollingHorizontally - row {
                        forEach(Constant(SelectedTheme.entries.filter { !it.secret || devSettings.value })) {
                            buttonTheme - button {
                                paddingByEdge = Edges(1.5.rem, 0.25.rem)
                                dynamicTheme {
                                    if (selectedThemeOptionDirty() == it) SemiImportantSemantic
                                    else null
                                }
                                text(it.display)
                                onClick {
                                    selectedThemeOptionDirty set it
                                }
                            }
                        }
                    }
                }
            },
            actionSection = {
                actionOrCancelSection(
                    onCancel = { pageNavigator.dismiss() },
                    constrainedWidth = true,
                    actionButton = {
                        important - buttonTheme - button {
                            centered - text("Save")
                            reactiveSuspending {
                                enabled =
                                    listOf(
                                        (editName() != session()?.authentication?.getMyUserInfo()?.userData?.name && editName().isNotBlank()),
                                        selectedThemePrimaryColorDirty() != null,
                                        selectedThemeOptionDirty() != null,
                                        profileImage().newImage != null,
                                        profileImage().deleteOriginal,
                                    ).any { it }
                            }
                            onClick {
                                if (editName() != session()?.authenticatedUser()?.name) {
                                    session.awaitNotNull().authentication.setUserAttributes(
                                        setTosRead = true,
                                        name = editName(),
                                    )
                                }
                                selectedThemePrimaryColorDirty()?.let { selectedThemePrimaryColor set it }
                                selectedThemePrimaryColorDirty.value = null
                                selectedThemeOptionDirty()?.let { selectedThemeOption set it }
                                selectedThemeOptionDirty.value = null

                                if (profileImage().deleteOriginal) {
                                    session.awaitNotNull().authentication.deleteUserProfilePicture()
                                    delay(3.seconds)
                                    session()?.let { if (it.discussion is CachedApi) it.discussion.clearCache() }
                                }
                                profileImage().newImage?.let {
                                    session.awaitNotNull().authentication.putProfilePicture(it)
                                    delay(2.seconds)
                                    session()?.let { if (it.discussion is CachedApi) it.discussion.clearCache() }
                                }
                                dialogPageNavigator.dismiss()
                            }
                        }
                    }
                )


                centered - compact - danger - buttonTheme - button {
                    text { content = "Delete account" }
                    onClick {
                        pageNavigator.navigate(DeleteAccount)
                    }
                }

                centered - row {
                    spacing = 2.5.rem
                    LinkSemantic.onNext - externalLink {
                        text("Terms of Use")
                        to = "https://www.picme.com/terms-of-use.html"
                        newTab = true
                    }
                    LinkSemantic.onNext - externalLink {
                        text("Privacy Policy")
                        to = "https://www.picme.com/privacy-policy.html"
                        newTab = true
                    }
                }
            }
        )
    }
}
