package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.ImportantForegroundSemantic
import com.picme.MostlyEmptySemantic
import com.picme.components.eula

@Routable("welcome")
object WelcomeTOSLanding : Page, UnauthPage {

    override val title = Constant("")

    val acknowledged = PersistentProperty("tos-launch-flag", false)

    override fun ViewWriter.render(): ViewModifiable {
        return MostlyEmptySemantic.onNext - frame {
            frame {
                col {
                    space(multiplier = 6.0)
                    centered - sizeConstraints(width = 5.rem, height = 5.rem) - image {
                        source = Resources.picmelogo
                    }
                    centered - row {
                        themeChoice += ImportantForegroundSemantic

                        centered - bold - text { content = "capture experiences" }
                    }
                    space(multiplier = 6.0)

                    centered - FadedSemantic.onNext - h3("Welcome to PicMe")

                    space(multiplier = 0.5)

                    centered - sizeConstraints(width = 28.rem, height = 42.dp) - important - buttonTheme - button {
                        centered - row {
                            centered - h6("Continue")
                            onClick {
                                acknowledged.value = true
                                pageNavigator.replace(NoCollectionsPage)
//                                if (Platform.current == Platform.iOS) {
//                                    val installReferStartPath = pageNavigator.routes.render(InstallReferStartPage)?.urlLikePath?.render()
//                                    ExternalServices.openTab("${frontendUrl()}/$installReferStartPath")
//                                }
                            }
                        }
                    }
                    space(multiplier = 0.01)
                    centered - eula("By clicking Continue, you agree to our")
                    space(); space()
                    getTheAppCard("collection", "Get our free app.")
                }
            }
        }
    }
}