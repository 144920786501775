package com.picme.components

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.Constant
import com.lightningkite.readable.Readable
import com.lightningkite.readable.invoke
import com.picme.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

fun ViewWriter.authPage(
    title: String,
    backArrow: Boolean = false,
    innerContent: RowOrCol.() -> Unit
): ViewModifiable {
    return unpadded - scrolling - col {
        dynamicTheme { if (isSmallPage()) AuthSmallSemantic else AuthSemantic }
        expanding - space { ::exists { !isSmallPage() } }
        sizeConstraints(minHeight = 1.5.rem) - space {
            ::exists { isSmallPage() }
        }

        centered - sizeConstraints(width = 28.rem) - padded - frame {
            dynamicTheme {
                if (isSmallPage()) null
                else FatCardSemantic
            }
            col {
                picmeIconDisplay()
                frame {
                    centered - buttonTheme - atTopStart - button {
                        icon(PIcon.chevronleft, "Back")
                        exists = backArrow
                        onClick {
                            pageNavigator.goBack()
                        }
                    }
                    centered - h3 {
                        content = title
                    }
                }
                innerContent()
            }
        }
        sizeConstraints(minHeight = 4.rem) - space { ::exists { isSmallPage() } }
        expanding - space { ::exists { !isSmallPage() } }
    }
}


fun ViewWriter.picmeIconDisplay(size: Dimension = 48.dp): ViewModifiable {
    return unpadded - frame {
        centered - sizeConstraints(width = size, height = size) - image {
            source = Vectors.logoWithWords
            launch {
                delay(10)
                source =
                    if (theme.background.closestColor().perceivedBrightness > 0.5f) Vectors.logoWithWords else Vectors.logoWithWordsDark
            }
        }
    }
}