package com.picme.actuals

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.Readable
import com.lightningkite.kiteui.views.RContext
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.animate
import com.lightningkite.kiteui.views.direct.createObjectURL
import com.lightningkite.kiteui.views.direct.nativeSetSrc
import com.lightningkite.kiteui.views.direct.vprop
import kotlinx.browser.window
import kotlinx.dom.addClass
import org.w3c.dom.HTMLElement
import kotlin.js.json


actual fun RView.animatePulsating() {
    native.onElement {
        it as HTMLElement
        it.animate(
            arrayOf(
                json("transform" to "none"),
                json("transform" to "scale(1.1)"),
                json("transform" to "none"),
            ), json(
                "duration" to 1500.0,
                "easing" to "linear",
                "iterations" to 1000
            )
        )
    }
}

actual fun RView.disableContextMenu() {
    native.onElement {
        it as HTMLElement
        it.oncontextmenu = {
            it.preventDefault()
        }
        it.addClass("disableContextMenu")
    }
}

actual fun deviceCanShareFiles(): Boolean {
    return !!window.navigator.asDynamic().canShare
}

actual fun webUrl(): String? {
    return window.location.href
}

actual class Advertisement actual constructor(context: RContext) : RView(context) {
    init {
        native.tag = "div"
        native.classes.add("viewDraws")
        native.classes.add("swapImage")
//        native.style.height = "250px"
    }

    actual var adUnitId: String?
        get() = null
        set(value) {}

    actual var adImgSrc: ImageSource? = null
        set(value) {
            when (value) {
                null -> setSrc(value, "")
                is ImageRemote -> {
                    setSrc(value, value.url)
                }

                is ImageRaw -> {
                    setSrc(value, createObjectURL(value.data))
                }

                is ImageResource -> {
                    setSrc(value, context.basePath + value.relativeUrl)
                }

                is ImageLocal -> {
                    setSrc(value, createObjectURL(value.file))
                }

                is ImageVector -> {
                    setSrc(value, value.vectorToSvgDataUrl())
                }

                else -> {}
            }
        }

    fun setSrc(source: ImageSource?, url: String) = nativeSetSrc(url, onError = { if(source == this.adImgSrc) this.adImgSrc = null }, onSuccess = {})
    actual var fullSizeAd: Boolean?
        get() = null
        set(value) {}
    actual val adLoaded: Readable<Boolean?>
        get() = native .vprop(
            "animationstart",
            {
                println("ad loaded")
                true },
            {true }
        )
         // TODO implement fully when web ads added
}

actual suspend fun loadInterstitialAd(adUnitId: String): InterstitialAd? {
    console.log("We'd open an ad here, but it's not connected yet.")
    return null
}