package com.picme.sso

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.readable.AppScope
import com.lightningkite.readable.invoke
import com.picme.UnauthPage
import com.picme.fullPageLoading
import com.picme.navigateToCollOrLanding
import com.picme.sdk2.unauthApi
import com.picme.sessionRefreshToken
import com.picme.views.LoginOrSignUp
import kotlinx.coroutines.launch

@Routable("/sso-apple/{access}/{refresh}")
class AppleSSOLanding(val access: String, val refresh: String) : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {

        suspend fun signIn() {
            val userAuth = unauthApi().authenticationHandler.refreshUserAuthentication(
                refresh
            ) { access }.successfulAuthentication?.authenticatedUser

            if (userAuth == null) {
                mainPageNavigator.reset(LoginOrSignUp(false))
            } else {
                sessionRefreshToken set refresh
                navigateToCollOrLanding()

            }
        }

        return frame {
            AppScope.launch { signIn() }
            fullPageLoading()
        }
    }

}