package com.picme.views

import com.lightningkite.kiteui.PlatformStorage
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.unauthApi
import com.picme.sso.startSSO


@Routable("login-or-signup")
class LoginOrSignUp(val backArrow: Boolean = false) : Page, UnauthPage {


    override fun ViewWriter.render(): ViewModifiable {
        val errorText = Property<String?>(null)
        val fieldError = Property<String?>(null)


        return authPage("Log in or Sign up", backArrow) {
            scrolling - col {

                danger - text {
                    ::exists { (errorText() ?: "").isNotBlank() }
                    ::content { errorText() ?: "" }
                    align = Align.Center
                }

                reactiveSuspending {
                    if (emailOrPhone().isValidEmailOrPhone()) fieldError set null
                }

                val action = Action(
                    "Submit",
                    action = {
                        // When the user's refresh token expires, sometimes this doesn't get removed. I'm not sure why
                        PlatformStorage.remove("cacheKeys")
                        PlatformStorage.remove("collections.cache")
                        if (emailOrPhone() == "84043") {
                            devSettings set true
                            showToast("Dev options enabled", "You will now see the bottom action bar")
                        } else if (emailOrPhone().isBlank()) {
                            fieldError set "Required"
                        } else if (!emailOrPhone().isValidEmailOrPhone()) {
                            fieldError set "Please enter a valid email or phone number"
                        } else {
                            try {
                                val userStatus =
                                    unauthApi().authenticationHandler.checkUsername(emailOrPhone().lowercase())
                                if (userStatus.status != "CONFIRMED") {
                                    pageNavigator.navigate(CreateAccount)
                                    return@Action
                                }
                                if (userStatus.verified) {
                                    pageNavigator.navigate(LogInPage)
                                    return@Action
                                }
                                unauthApi().authenticationHandler.resendAccountVerification(emailOrPhone().lowercase())
                                showToast("Verification code sent")
                                pageNavigator.navigate(ConfirmEmail().apply { userPassword })

                            } catch (e: Exception) {
                                if (e.message?.contains("NotFound") == true) {
                                    pageNavigator.navigate(CreateAccount)
                                } else {
                                    errorText.value = "An error has occurred"
                                }
                            }
                        }
                    }
                )

                field("Email or Phone") {
                    textInput {
                        hint = "Email or Phone"
                        content bind emailOrPhone
                        keyboardHints = KeyboardHints.email
                        reactive {
                            fieldError()?.let { throw PlainTextException(it, "Error") }
                        }
                        this.action = action
                    }
                }

                spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
                important - buttonTheme - button {
                    centered - text("Continue")
                    this.action = action
                }

                startSSO()
                space()
                eula("By continuing, you agree to our")
                space()
                getTheAppCard("login-or-signup", "Get our free app.")

                backendUrlSelect()
            }
        }
    }
}