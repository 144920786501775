package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.generated.collection2.CreateCollectionBody
import com.picme.sdk2.toSafeEncoded

@Routable("collection")
object NoCollectionsPage : Page, UnauthPage {
    override val title = Constant("")
    override fun ViewWriter.render(): ViewModifiable {
        return MostlyEmptySemantic.onNext - frame {
            reactive {
                session()?.let {
                    val firstColl = it.collection2.listCollectionsLive().all().firstOrNull()
                    if (firstColl != null) {
                        pageNavigator.reset(CollectionImageView(firstColl.collection.collectionId.raw.toSafeEncoded()))
                    }
                }
            }
            col {
                spacing = 2.rem
                centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                    source = Resources.picmePaige
                }

                centered - FadedSemantic.onNext - text("To get started, create a collection")
                centered - sizeConstraints(width = 28.rem, height = 42.dp) - important - buttonTheme - button {
                    centered - row {
                        icon { source = PIcon.add }
                        centered - h6("Create Collection")
                    }
                    suspend fun ViewWriter.createColl(myName: String) {
                        createAccountIfNewUser(myName)
                        val created = session.awaitNotNull().collection2.createCollection(
                            CreateCollectionBody(name = "My Collection")
                        )

                        createInitialQrCodes(created.collection)
                        session.awaitNotNull().collection2.forceRefresh()
                        mainPageNavigator.reset(CollectionImageView(created.collection.collectionId.raw.toSafeEncoded()))
                    }

                    onClick {
                        val name =
                            session()?.authenticatedUser()?.name?.takeIf { it.isNotBlank() } ?: createUserName()
                        if (name.isNotEmpty()) createColl(name)
                        else {
                            dialogGeneric { close ->
                                col {
                                    frame {
                                        centered - h4("What's your name?")
                                        atCenterEnd - buttonTheme - button {
                                            icon(PIcon.close, "Close")
                                            onClick {
                                                createUserName set ""
                                                close()
                                            }
                                        }
                                    }

                                    FadedSemantic.onNext - text {
                                        content = "Enter your name or generate a random one to remain anonymous."
                                        align = Align.Center
                                    }

                                    space()

                                    askForNameForm(
                                        Action(
                                            title = "Submit",
                                            action = {
                                                if (createUserName().isNotBlank()) {
                                                    createColl(createUserName())
                                                }
                                                close()
                                            }
                                        )
                                    )
                                    frame {
                                        spacing = 0.dp
                                        ::exists { !WelcomeTOSLanding.acknowledged() }
                                        eula("By clicking Continue, you agree to our")
                                    }
                                }
                            }
                        }
                    }
                }

                space(0.0) {
                    ::exists { !isSmallPage() }
                }
                centered - text("Already have an account?")
                centered - NaturalLinkSemantic.onNext - link {
                    subtext("Click here to log in")
                    to = { LoginOrSignUp() }
                }
                getTheAppCard("collection", "Get our free app.")
            }
        }
    }
}