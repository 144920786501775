package com.picme.sdk2

import com.lightningkite.kiteui.*
import com.lightningkite.readable.AppScope
import com.lightningkite.readable.PersistentProperty
import com.lightningkite.readable.invoke
import com.picme.compressForLocalStorage
import com.picme.sdk2.generated.CreateQrCodeImageResponse
import com.picme.session
import com.picme.uncompressForLocalStorage
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer


object GeneratedQrCache {
    val cachedUrls: PersistentProperty<String> = PersistentProperty(
        "picme-invites",
        compressForLocalStorage(
            json.encodeToString(
                MapSerializer(String.serializer(), CreateQrCodeImageResponse.serializer()),
                hashMapOf()
            )
        )
    )

    suspend fun decodedMap(): HashMap<String, CreateQrCodeImageResponse> {
        val curr = uncompressForLocalStorage(cachedUrls())
        return curr.let { json.decodeFromString<HashMap<String, CreateQrCodeImageResponse>>(it) }
    }

    suspend fun setCached(key: String, info: CreateQrCodeImageResponse) {
        try {
            val decoded = decodedMap()
            decoded[key] = info
            val encoded =
                json.encodeToString(MapSerializer(String.serializer(), CreateQrCodeImageResponse.serializer()), decoded)
            cachedUrls set compressForLocalStorage(encoded)
        } catch (e: Exception) {
            e.printStackTrace2()
        }
    }

    suspend fun getCached(k: String): CreateQrCodeImageResponse? {
        try {
            val decoded = decodedMap()
            return decoded[k]
        } catch (e: Exception) {
            e.printStackTrace2()
            return null
        }
    }

    suspend fun invalidateAll() {
        cachedUrls set compressForLocalStorage(
            json.encodeToString(
                MapSerializer(String.serializer(), CreateQrCodeImageResponse.serializer()),
                hashMapOf()
            )
        )
    }
}

enum class QrType { Png, Svg }

object GenerateQr {
    fun getQrCode(
        hostName: String,
        path: String,
        type: QrType,
        pixelSize: Int = 600
    ): Deferred<CreateQrCodeImageResponse> {
        val stripped = hostName.replace("https://", "")
        val key = "$stripped-$path-$type-$pixelSize"

        return AppScope.async {
            GeneratedQrCache.getCached(key) ?: run {
                val generated = if (type == QrType.Svg) {
                    session()!!.collection2.generateQrCodeSvg(
                        hostnamePrefix = stripped,
                        pathAndQueryStringPattern = path,
                        useLogo = true,
                        colorLogo = false
                    )
                } else {
                    session()!!.collection2.generateQrCodePng(
                        hostnamePrefix = stripped,
                        pathAndQueryStringPattern = path,
                        useLogo = true,
                        colorLogo = false,
                        pixels = pixelSize,
                    )
                }
                generated.also {
                    GeneratedQrCache.setCached(key, it)
                }
            }
        }
    }
}