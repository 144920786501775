package com.picme.views.share

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.picme.ControlsStatusBarColor
import com.picme.HasSubtitle
import com.picme.components.Filters
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.PageForCollection

enum class GuestSubView { Guests, GuestDetail }

@Routable("/collection/{urlSafeId}/guests")
class GuestView(override val urlSafeId: String) : ControlsStatusBarColor, PageForCollection(), HasSubtitle {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = GuestView(urlSafeId)
    override val subtitle: Readable<String> get() = Constant("Guests")
    val guest = Property<CollectionUserData?>(null)

    override fun ViewWriter.render(): ViewModifiable = swapView {
        swapping(
            current = {
                if (guest() != null) GuestSubView.GuestDetail
                else GuestSubView.Guests
            },
            views = { currTab ->
                when (currTab) {
                    GuestSubView.Guests -> with(Guests(collectionId) {
                        guest set it
                    }) { render() }

                    GuestSubView.GuestDetail -> with(
                        GuestDetail(
                            guest = shared { guest.awaitNotNull() },
                            collectionId = collectionId,
                            onBack = { guest set null }
                        )) { render() }
                }
            }
        )
    }
}