package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.actionOrCancelSection
import com.picme.components.formColumn
import com.picme.components.showToast
import com.picme.components.styledPassword
import kotlinx.coroutines.launch


@Routable("password-and-security")
object PasswordAndSecurity : Page {
    override val title = Constant("Password and Security")
    override fun ViewWriter.render(): ViewModifiable = scrolling - col {
        space(2.0)
        centered - sizeConstraints(width = 18.rem) - ImportantSemantic.onNext - buttonTheme - button {
            onClick {
                pageNavigator.navigate(ResetPasswordDialog)
            }
            centered - text("Change Password")
        }
    }
}

object ResetPasswordDialog : Page {
    override val title = Constant("Reset Password")
    val oldPassword = Property("")
    val newPassword = Property("")
    val passwordError: Property<String?> = Property(null)

    override fun ViewWriter.render(): ViewModifiable {
        val submitAction = Action("Submit") {
            if (oldPassword().length < 8 || newPassword().length < 8) {
                passwordError set "Password must be at least 8 characters long"
                return@Action
            }
            try {
                session.awaitNotNull().authentication.changeUserPassword(oldPassword(), newPassword())
                pageNavigator.clear()
                showToast("Password successfully changed.")
            } catch (e: Exception) {
                showToast("Unable to change your password. You can double check your old password and try again.")
            }
        }

        return formColumn(
            fieldSection = {
                styledPassword(
                    label = "Old Password",
                    new = false,
                    password = oldPassword,
                    passwordError = Constant(null)
                )
                styledPassword(
                    label = "New Password",
                    new = true,
                    password = newPassword,
                    passwordError = passwordError,
                    action = submitAction
                )

            },
            actionSection = {
                actionOrCancelSection(
                    onCancel = {
                        pageNavigator.dismiss()
                    },
                    constrainedWidth = true,
                    actionButton = {
                        important - buttonTheme - button {
                            centered - text("Submit")
                            action = submitAction
                        }
                    }
                )
            })
    }
}

object DeleteAccount : Page {
    override val title = Constant("Delete Account")

    override fun ViewWriter.render(): ViewModifiable = scrolling - col {
        val confirmDeleteAccount = Property("")
        val errorText = Property<String?>(null)

        text("Are you sure you want to delete your account?")
        text("To confirm, please type \"delete my account\" below.")
        reactive {
            if (confirmDeleteAccount() == "delete my account") {
                errorText.value = null
            }
        }
        field("Confirmation") {
            textInput {
                hint = "delete my account"
                content bind confirmDeleteAccount
                keyboardHints = KeyboardHints.email
                reactive {
                    errorText()?.let { throw PlainTextException(it, "Error") }
                }
            }
        }

        danger - text("This action is not recoverable! Your account, and all associated photos, collections & referrals will be deleted!")
        actionOrCancelSection(
            onCancel = {
                pageNavigator.dismiss()
            },
            actionButton = {
                buttonTheme - DangerSemantic.onNext - button {
                    centered - h6("Delete Account")
                    onClick {
                        if (confirmDeleteAccount() == "delete my account") {
                            pageNavigator.clear()
                            smallPageNavigator.clear()
                            AppScope.launch {
                                session()?.authentication?.deleteUser()
                                showToast("Your account has been deleted")
                            }
                            logout()
                            showToast("Your account has been deleted.")
                        } else {
                            errorText set "Confirmation text is incorrect"
                        }
                    }
                }
            }
        )
    }
}
