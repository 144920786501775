package com.picme.sdk2.caching

import com.lightningkite.readable.AppScope
import com.picme.sdk2.CachedApi
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.discussion.*
import com.picme.views.comments.ScopedEntity

class DiscussionCache(val basedOn: DiscussionHandlerApi) : CachedApi, DiscussionHandlerApi by basedOn {

    private val discussionMessages: HashMap<ScopedEntity, Deferred<ListDiscussionMessagesResponse>> = hashMapOf()

    override fun clearCache() {
        discussionMessages.clear()
    }

    override suspend fun patchDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId,
        body: PatchDiscussionMessageBody
    ): PatchDiscussionMessageResponse {
        return basedOn.patchDiscussionMessage(
            scope,
            discussionOrEntityTypeId,
            discussionOrEntityId,
            discussionMessageId,
            body
        ).also { clearCache() }
    }

    override suspend fun deleteDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId
    ): DeleteDiscussionMessageResponse {
        return basedOn.deleteDiscussionMessage(
            scope,
            discussionOrEntityTypeId,
            discussionOrEntityId,
            discussionMessageId
        ).also { clearCache() }
    }

    override suspend fun createDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        body: CreateDiscussionMessageBody
    ): CreateDiscussionMessageResponse {
        return this.basedOn.createDiscussionMessage(
            scope,
            discussionOrEntityTypeId,
            discussionOrEntityId,
            body,
        ).also { clearCache() }
    }

    override suspend fun listDiscussionMessages(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        continuation: String?
    ): ListDiscussionMessagesResponse {
        return discussionMessages.getOrPut(ScopedEntity(scope, discussionOrEntityId, discussionOrEntityTypeId)) {
            AppScope.async {
                basedOn.listDiscussionMessages(
                    scope,
                    discussionOrEntityTypeId,
                    discussionOrEntityId,
                    continuation
                )
            }
        }.await()
    }
}