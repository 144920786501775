package com.picme.views.share


import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.sdk2.generated.Rights
import com.picme.sdk2.generated.UserInfo
import kotlinx.coroutines.launch

class GuestDetail(
    val guest: Readable<CollectionUserData>,
    val collectionId: CollectionId,
    val onBack: suspend () -> Unit
) : Page {

    val currentRights = Property<Rights?>(null)
    val proposedRights = Property<Rights?>(null)

    override fun ViewWriter.render(): ViewModifiable {
        return scrolling - padded - col {
            spacing = 2.rem
            launch {
                currentRights.set(guest().participationRights)
                proposedRights.set(guest().participationRights)
            }

            col {
                userAvatar(guest.lens { UserInfo(userId = it.userId, name = it.name) }, size = 6.rem)
                centered - h4 { ::content { guest().name.anonymousIfEmpty() } }
            }

            col {
                spacing = 0.5.rem
                FieldLabelSemantic.onNext - text("Permissions")
                row {
                    radioButton {
                        checked bind proposedRights.lens(
                            get = { (it?.value ?: 0) >= 3 },
                            modify = { r, b -> Rights(3) }
                        )
                    }
                    centered - text("Upload Photos & View Collection")
                }
                row {
                    radioButton {
                        checked bind proposedRights.lens(
                            get = { (it?.value ?: 0) < 3 },
                            modify = { r, b -> Rights(1) }
                        )
                    }
                    centered - text("Upload Photos Only")
                }
            }


            space(2.0)
            separator()

            actionOrCancelSection(
                onCancel = onBack,
                constrainedWidth = true,
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Save")
                        ::enabled { currentRights() != proposedRights() }
                        onClick {
                            val proposed = proposedRights() ?: return@onClick
                            val rightsModifiedResponse = session.awaitNotNull().collection2.modifyRights(
                                collectionId,
                                guest().userId,
                                proposed
                            )
                            if (rightsModifiedResponse.linksModified > 0) {
                                val updatedGuest = session.awaitNotNull().collection2.listUsers(collectionId).let {
                                    it.users.find { it.userId == guest().userId }
                                }
                                if (updatedGuest != null) {
                                    currentRights.set(proposed)
                                }
                            }
                            onBack()
                        }
                    }
                })
            space()
        }
    }

}