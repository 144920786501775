package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.readable.*
import com.lightningkite.readable.awaitNotNull
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.ListedUpload
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.views.share.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.seconds

// TODO: Experimental and not yet ready
@Routable("/collection/{urlSafeId}/slideshow")
class Slideshow(val urlSafeId: String) : Page {
    private val collectionId = CollectionId(SafeIds.decode(urlSafeId))
    val collectionMeta = shared { session.awaitNotNull().collection2.getCollectionLive(collectionId) }
    val collection = shared { collectionMeta()() }
    val paged = shared {
        CollectionState.filterGuests()
        CollectionState.filterDate()
        collection().collection.images()
    }
    val current = LateInitProperty<ListedUpload>()
    val currentSource = shared(useLastWhileLoading = true) {
        val it = current()
        if(it.mimeType.raw.startsWith("video/")) {
            session.awaitNotNull().collection2.getUploadLive(collectionId, it.uploadId)().getDetailsUri.let(::VideoRemote)
        } else {
            session.awaitNotNull().collection2.getUploadLive(collectionId, it.uploadId)().getDetailsUri.let(::ImageRemote)
        }
    }
    override fun ViewWriter.render(): ViewModifiable { 
        return swapView {
            launch {
                paged().requireIndexLoaded = 9999
                while(true) {
                    paged().all().randomOrNull()?.let { current.value = it }
                    delay(10.seconds)
                }
            }
            swapping(
                transition = { ScreenTransition.Fade },
                current = {
                    currentSource()
                },
                views = {
                    when(it) {
                        is VideoRemote -> video {
                            scaleType = ImageScaleType.Crop
                            source = it
                            loop = true
                            launch {
                                volume set 0f
                                playing set true
                            }
                        }
                        is ImageRemote -> image {
                            source = it
                            scaleType = ImageScaleType.Crop
                        }
                        else -> text("Huh?")
                    }
                }
            )
        }
    }
}