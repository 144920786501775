package com.picme.views.share

import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.FieldLabelSemantic
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.anonymousIfEmpty
import com.picme.components.showConfirmDialog
import com.picme.components.showToast
import com.picme.components.userAvatar
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.UserInfo
import com.picme.sdk2.generated.collection2.CollectionUserData


class Guests(
    val collectionId: CollectionId,
    val navToGuest: suspend (guest: CollectionUserData) -> Unit
) : Page {

    override fun ViewWriter.render(): ViewModifiable {
        return expanding - unpadded - frame {

            val sharees = Property<List<CollectionUserData>?>(null)

            reactiveSuspending {
                val coll = sessionNotNull().collection2.getCollectionLive(collectionId)
                sharees set sessionNotNull().collection2.listUsers(collectionId).let {
                    it.users.filterNot { it.userId == coll().collection.creatorUserId }
                        .sortedByDescending { it.participationRights.value }.distinctBy { it.userId }
                }
            }

            val loading = shared { sharees() == null }

            col {
                ::exists { loading() }
                space(3.0)
                centered - activityIndicator()
            }

            col {
                ::exists { sharees()?.isEmpty() == true }
                spacing = 2.rem
                space()
                centered - bold - text("Invite Guests")

                text {
                    content = "To invite guests to upload to \nthis collection, click the share \nicon below:"
                    align = Align.Center
                }

                centered - button {
                    icon(
                        PIcon.qrcode.copy(width = 8.rem, height = 8.rem), "Share QR code"
                    )
                    onClick {
                        smallPageNavigator.reset(
                            ShareView(collectionId.raw.let(SafeIds::encode))
                        )
                    }
                }
                spacingOverrideBeforeNext(0.5.rem)
                text {
                    content = "Share"
                    align = Align.Center
                }
            }


            expanding - recyclerView {
                ::exists { sharees()?.isNotEmpty() == true }
                children(
                    items = sharees.lens { it ?: emptyList() },
                    id = { it.userId },
                    render = { guest ->
                        row {
                            userAvatar(guest.lens { UserInfo(userId = it.userId, name = it.name) }, 36.dp)

                            col {
                                spacing = 0.dp
                                text {
                                    ::content { guest().name.anonymousIfEmpty() }
                                }
                                FieldLabelSemantic.onNext - subtext {
                                    ::content { guest().participationRights.rightsOnCollection() }
                                }
                            }

                            expanding - space()

                            centered - buttonTheme - unpadded - menuButton {
                                requireClick = true
                                centered - icon(PIcon.dotsvertical, "More")
                                opensMenu {
                                    MenuOptionContainerSemantic.onNext - col {
                                        MenuOptionSemantic.onNext - button {
                                            row {
                                                icon(PIcon.edit, "Edit permissions")
                                                text("Edit Permissions")
                                            }
                                            onClick {
                                                closePopovers()
                                                navToGuest(guest())
                                            }
                                        }
                                        MenuOptionSemantic.onNext - button {
                                            row {
                                                icon(PIcon.unlink, "Edit permissions")
                                                text("Disconnect Guest")
                                            }
                                            onClick {
                                                closePopovers()
                                                showConfirmDialog(
                                                    title = "Disconnect Guest",
                                                    content = "Are you sure you want to disconnect this guest from the collection?",
                                                    warning = "This action cannot be undone.",
                                                    confirmLabel = "Disconnect",
                                                    onConfirm = {
                                                        session.awaitNotNull().collection2.revokeRights(
                                                            collectionId,
                                                            guest().userId
                                                        )
                                                        sharees set sharees.value?.minus(guest())
                                                        showToast("${guest().name} has been disconnected")
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            space(0.0)
                        }
                    })
            }
        }
    }
}
