package com.picme.views

import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.readable.awaitNotNull
import com.lightningkite.readable.invoke
import com.lightningkite.readable.shared
import com.lightningkite.readable.sharedSuspending
import com.picme.HasClickableTitle

import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.session

abstract class PageForCollection : Page, HasClickableTitle {
    abstract val urlSafeId: String
    open fun forOtherCollection(urlSafeId: String): PageForCollection? = null
    val collectionId by lazy { CollectionId(SafeIds.decode(urlSafeId)) }
    val collection by lazy {
        sharedSuspending {
            session.awaitNotNull().collection2.getCollectionLive(collectionId)()
        }
    }
    override val title
        get() = shared {
            collection().collection.name
        }

    override suspend fun ViewWriter.onTitleClick() {
        pageNavigator.reset(CollectionImageView(urlSafeId))
    }
}