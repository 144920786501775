package com.picme.sdk2.generated.poll

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


/** A record containing the response to <see cref="M:PicMeApi.PollApis.PutMyPollSelection(SimpleDatabase.Auth,CloudFileSystems.ICloudFileSystem,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,System.String)" />. **/

/** A record containing the response to <see cref="M:PicMeApi.PollApis.GetMyPollSelection(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String)" />. **/
@Serializable
data class GetPollSelectionResponse(
    val selection: PollSelection? = null, 
)

/** A record containing the details for a poll selection. **/
@Serializable
data class PollSelection(
    val choiceId: String = "", 
    val userInfo: UserInfo = UserInfo(), 
    val choiceTime: DateTime = Instant.fromEpochMilliseconds(0), 
)

/** A record containing the response to <see cref="M:PicMeApi.PollApis.ListPollSelections(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,System.String)" />. **/
@Serializable
data class ListPollSelectionsResponse(
    val selections: List<PollSelection> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the response to <see cref="M:PicMeApi.PollApis.ListPollResultSummary(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String)" />. **/
@Serializable
data class ListPollResultSummaryResponse(
    val results: List<PollSummaryEntry> = listOf(), 
)

/** A record containing the summary (ie. counts) for one poll choice. **/
@Serializable
data class PollSummaryEntry(
    val choiceId: String = "", 
    val count: Int32 = 0, 
)


@Serializable
@JvmInline
value class PollId(val raw: String = "")

/** A record containing the structure of the body for the <see cref="M:PicMeApi.PollApis.PatchPoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.PollId,System.Text.Json.JsonDocument)" /> API.
            Any parts of the structure may be left unspecified, which will cause the existing value to be retained. **/
@Serializable
data class PatchPollBody(
    val name: Retainable<String?> = Retainable.retain, 
    val description: Retainable<Text?> = Retainable.retain, 
    val choices: Retainable<List<PollChoice>?> = Retainable.retain, 
    val startDate: Retainable<DateTime?> = Retainable.retain, 
    val endDate: Retainable<DateTime?> = Retainable.retain, 
)


@Serializable
data class PollChoice(
    val choiceId: String = "", 
    val choiceDisplay: String = "", 
)

/** The response for <see cref="M:PicMeApi.PollApis.PatchPoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.PollId,System.Text.Json.JsonDocument)" />. **/
@Serializable
data class PatchPollResponse(
    val poll: Poll = Poll(), 
)


@Serializable
data class Poll(
    val pollId: PollId = PollId(""), 
    val pollGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val creatorUserId: UserId = UserId(""), 
    val creationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val modifierUserId: UserId = UserId(""), 
    val modificationTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val startDate: DateTime? = null, 
    val endDate: DateTime? = null, 
    val name: String = "", 
    val description: Text = Text(""), 
    val choices: List<PollChoice> = listOf(), 
)

/** A record containing the response after deleting a colleciton. **/
@Serializable
data class DeletePollResponse(
    val pollId: PollId = PollId(""), 
    val pollGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record containing the structure of the body for the <see cref="M:PicMeApi.PollApis.CreatePoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.CreatePollBody)" /> API. **/
@Serializable
data class CreatePollBody(
    val name: String = "", 
    val description: Text = Text(""), 
    val choices: List<PollChoice> = listOf(), 
    val startDate: DateTime? = null, 
    val endDate: DateTime? = null, 
)

/** The response for <see cref="M:PicMeApi.PollApis.CreatePoll(SimpleDatabase.Auth,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.CreatePollBody)" />. **/
@Serializable
data class CreatePollResponse(
    val pollId: PollId = PollId(""), 
    val pollGlobalId: RecordGlobalId = RecordGlobalId(""), 
)

/** A record that holds information about an upload query. **/
@Serializable
data class PollQuery(
    val creationTime: DateTimeRange? = null, 
    val creatorUserId: UserId? = null, 
    val modificationTime: DateTimeRange? = null, 
    val name: String? = null, 
    val description: Text? = null, 
    val isEmpty: Boolean = false, 
)

/** A record containing the response from the <see cref="M:PicMeApi.PollApis.ListPolls(SimpleDatabase.Auth,SimpleDatabase.Database,CloudFileSystems.ICloudFileSystem,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,PicMeApi.PollQuery,System.Int32,System.String,System.Nullable{SimpleDatabase.UserId})" /> API. **/
@Serializable
data class ListPollsResponse(
    val polls: List<ListedPoll> = listOf(), 
    val continuation: String? = null, 
)

/** A record containing the data for a poll returned in a list of polls. **/
@Serializable
data class ListedPoll(
    val poll: Poll = Poll(), 
    val userRights: Rights = Rights.values().first(), 
    val userParticipationRights: Rights = Rights.values().first(), 
)

