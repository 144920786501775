package com.picme

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.readable.*
import com.picme.sdk2.Session
import com.picme.sdk2.unauthApi
import com.picme.views.acceptingInvite
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable


val logoutTrigger = BasicListenable()

suspend fun logout() {
    sessionRefreshToken.value = null
    acceptingInvite.value = null
    logoutTrigger.invokeAll()
    removeCaches()
}

@Serializable
enum class GridSize(val size: Dimension) { Large(220.dp), Small(110.dp) }

val gridSize = PersistentProperty<GridSize>("p-grid-size", GridSize.Small)

val session: Readable<Session?> = sharedSuspending {
    try {
        val token = sessionRefreshToken()
        if (token != null) {
            val api = unauthApi()
            val res = api.authenticationHandler.refreshUserAuthentication(token)
            val newSession = Session(
                api,
                res.successfulAuthentication!!.authenticated,
                res.successfulAuthentication.authenticatedUser
            )
            newSession.collection2.listCollectionsLive().all.awaitOnce()
            return@sharedSuspending newSession
        } else {
            return@sharedSuspending null
        }
    } catch (e: Exception) {
        println("Caught here!")
        e.printStackTrace2()
        logout()
        return@sharedSuspending null
    }
}

val sessionNotNull = shared { session.awaitNotNull() }

@Serializable
data class QRScanEvent(
    val codeId: String,
    val timestamp: Instant
)


val sessionRefreshToken = PersistentProperty<String?>("picme-refresh-token", null)
val lastScannedQr = PersistentProperty<QRScanEvent?>("last-scanned-qr", null)

val emailOrPhone = PersistentProperty("picme-user-email", "")
