package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.l2.children
import com.picme.*
import com.picme.MenuOptionSemantic
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.spacingAndPadding
import com.picme.views.PageForCollection
import kotlinx.coroutines.delay


fun ViewWriter.openCopyMenu(collection: Readable<PCollection>, images: List<UploadId>) {
    dialogGeneric { close ->
        col {
            spacingAndPadding = 1.5.rem
            centered - h4("Confirm Copy")
            text("Copy the selected items to")
            val destinationColl1 = Property<PCollection?>(null)
            atStart - sizeConstraints(minWidth = 16.rem) - card - menuButton {
                row {
                    expanding - centered - text {
                        ::content {
                            destinationColl1()?.name ?: "Select a collection"
                        }
                    }
                    atEnd - icon { source = Icon.dropdown }
                }
                requireClick = true
                opensMenu {
                    val currentCollectionId =
                        shared { (mainPageNavigator.currentPage() as? PageForCollection)?.collectionId }
                    sizeConstraints(
                        width = 14.rem,
                        height = 14.rem
                    ) - expanding - MenuOptionContainerSemantic.onNext - recyclerView {
                        children(shared {
                            session.awaitNotNull().collection2.listCollectionsLive()
                                .all()
                                .filter {
                                    if (it.collection.collectionId == currentCollectionId()) false
                                    else ownsCollection(it)
                                }
                        }, { it.collection.collectionId }) { option ->
                            MenuOptionSemantic.onNext - button {
                                dynamicTheme {
                                    if (destinationColl1() == option().collection) SelectedSemantic
                                    else null
                                }
                                atStart - text {
                                    ::content { option().collection.name }
                                }
                                onClick {
                                    destinationColl1 set option().collection
                                    closePopovers()
                                }
                            }
                        }
                    }
                }
            }
            space()
            actionOrCancelSection(
                onCancel = { close() },
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Copy")
                        ::enabled { destinationColl1() != null }
                        onClick {
                            val dest = destinationColl1() ?: return@onClick
                            val sourceColl = collection()
                            val sess = session() ?: return@onClick
                            openProgressModal(
                                "Copying ${images.size} ${"item".pluralize(images.size)}",
                                execute = {
                                    itemCount = images.size

                                    for ((index, uploadId) in images.withIndex()) {
                                        val details = sess.collection2.getUpload(
                                            collectionId = sourceColl.collectionId,
                                            uploadId = uploadId
                                        )

                                        currentIndex = index
                                        image = ImageRemote(details.getThumbnailUri)
                                        try {
                                            individualItemProgress = 0.2f
                                            session.awaitNotNull().collection2.copyUpload(
                                                caption = details.upload.caption.raw,
                                                sourceCollectionId = collection().collectionId,
                                                sourceUploadId = uploadId,
                                                destinationCollectionId = dest.collectionId,
                                                anonymous = false,
                                                allowDuplicates = false,
                                            )
                                            individualItemProgress = 0.8f
                                            delay(1000)
                                            individualItemProgress = 1f
                                            delay(1000)
                                        } catch (e: Exception) {
                                            e.report()
                                        }
                                    }
                                },
                                onComplete = {
                                    showToast("Successfully copied ${images.size} ${"item".pluralize(images.size)}")
                                }
                            )

                            close()
                            CollectionState.selectItems.stopSelecting()
                        }
                    }
                }
            )
        }
    }
}
