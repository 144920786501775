package com.picme.views

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.picme.*
import com.picme.actuals.toBlob
import com.picme.components.formColumn
import com.picme.components.showToast
import com.picme.sdk2.GenerateQr
import com.picme.sdk2.QrType
import com.picme.sdk2.caching.InviteCache
import com.picme.views.share.ViewInviteInfo
import com.picme.views.share.downloadOptions
import kotlinx.coroutines.launch
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

@Routable("refer-a-friend")
object ReferAFriend : Page {
    override val title = Constant("Refer A Friend")

    @OptIn(ExperimentalEncodingApi::class)
    override fun ViewWriter.render(): ViewModifiable {
        val qrCodeInfo = Property<ViewInviteInfo?>(null)
        val activatedCount = Property<Int?>(null)

        launch {
            val referral =
                InviteCache.getReferralCode() ?: session.awaitNotNull().collection2.listInviteCodes(null, null)
                    .let { listResponse ->
                        listResponse.inviteCodes.firstOrNull {
                            it.clientInfo()?.type == InviteType.Referral
                        } ?: session.awaitNotNull().authentication.createReferralInviteCode(
                            name = "Referral",
                            clientInformation = serializeQrInfo(
                                ClientInfo(type = InviteType.Referral)
                            ),
                        ).inviteCode
                    }

            qrCodeInfo set ViewInviteInfo(
                inviteCode = referral,
                permission = InviteType.Referral,
                link = "${frontendUrl()}/${referral.sharePath()}",
            )
            InviteCache.setReferralCode(referral)

            activatedCount set session.awaitNotNull().authentication.listMyReferrals().referrals.size
        }
        return formColumn(
            fieldSection = {
                text("Share this code with a friend:")
                centered - activityIndicator {
                    ::exists { qrCodeInfo() == null }
                }
                centered - col {
                    ::exists { qrCodeInfo() != null }
                    spacing = 1.rem
                    val enabled = shared {
                        if (qrCodeInfo()?.inviteCode?.temporarilyDisabled == null) false
                        else !qrCodeInfo()?.inviteCode?.temporarilyDisabled!!
                    }
                    val pngQrCode = sharedSuspending {
                        GenerateQr.getQrCode(
                            hostName = frontendUrl(),
                            path = qrCodeInfo()?.inviteCode?.sharePath() ?: "",
                            QrType.Png
                        ).await()
                    }

                    val fileBlob = shared {
                        Base64.decode(pngQrCode().base64Encoded)
                            .toBlob(pngQrCode().contentType.raw)
                    }

                    centered - sizeConstraints(width = 12.rem, height = 12.rem) - image {
                        exists = false
                        ::exists { pngQrCode().base64Encoded; true }
                        ::source {
                            ImageRaw(fileBlob())
                        }
                    }
                    subtext {
                        align = Align.Center
                        wraps = true
                        wordBreak = WordBreak.BreakAll
                        ::content { qrCodeInfo()?.link?.removePrefix("https://") ?: "" }
                    }

                    centered - row {
                        card - ButtonSemantic.onNext - menuButton {
                            requireClick = true
                            preferredDirection = PopoverPreferredDirection.belowRight
                            icon(PIcon.download, "Download")
                            opensMenu {
                                frame {
                                    downloadOptions(
                                        qrCodeInfo,
                                        pngQrCode,
                                        fileBlob
                                    )
                                }
                            }
                        }
                        card - ButtonSemantic.onNext - button {
                            icon(PIcon.eye, "Preview")
                            ::enabled { enabled() }
                            onClick {
                                if (Platform.current === Platform.Web) {
                                    ExternalServices.openTab(qrCodeInfo()?.link ?: "")
                                } else {
                                    mainPageNavigator.navigate(
                                        QrAccept(
                                            qrCodeInfo()?.inviteCode?.inviteCodeId?.raw ?: ""
                                        )
                                    )
                                }
                            }
                        }
                        card - ButtonSemantic.onNext - button {
                            icon(PIcon.copy, "Copy Invite link")
                            ::enabled { enabled() }
                            onClick {
                                ExternalServices.setClipboardText(qrCodeInfo()?.link ?: "")
                                showToast("Url copied to clipboard")
                            }
                        }
                        card - ButtonSemantic.onNext - button {
                            icon(PIcon.share, "Share")
                            ::enabled { enabled() }
                            onClick {
                                if (qrCodeInfo()?.permission?.name != null && qrCodeInfo()?.link != null) {
                                    ExternalServices.share(
                                        title = qrCodeInfo()!!.permission.name,
                                        url = qrCodeInfo()!!.link
                                    )
                                }
                            }
                        }
                    }
                    space()
                    text {
                        ::exists { activatedCount() != null }
                        ::content { "Referrals: ${activatedCount()}" }
                        align = Align.Center
                    }
                }
            }
        )
    }
}