package com.picme.components

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Edges
import com.lightningkite.kiteui.models.FieldLabelSemantic
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.sdk2.generated.Rights
import com.picme.sdk2.generated.RightsEnum
import com.picme.sdk2.generated.UserInfo
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.PageForCollection
import kotlinx.coroutines.launch

val selectedTab = PersistentProperty("selected-filter-tab", SelectedTab.First)

@Routable("/collection/{urlSafeId}/filters")
class Filters(override val urlSafeId: String) : ControlsStatusBarColor, PageForCollection(), HasSubtitle {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = Filters(urlSafeId)
    override val subtitle: Readable<String> get() = Constant("Filters")

    val filterDate = Property<FilterDate?>(null)
    val filterGuests = Property<List<CollectionUserData>>(listOf())

    override fun ViewWriter.render(): ViewModifiable = scrolling - col {
        paddingByEdge = Edges(0.rem, 1.5.rem)
        spacing = 2.rem
        launch {
            val date = CollectionState.filterDate()
            val guests = CollectionState.filterGuests()
            if (date != null) selectedTab set SelectedTab.First
            if (guests.isNotEmpty()) selectedTab set SelectedTab.Second

            filterDate set date
            filterGuests set guests
        }

        expanding - col {
            paddingByEdge = Edges(0.5.rem, 0.5.rem, 0.5.rem, 0.rem)
            spacing = 1.5.rem
            field("Date Uploaded:") {
                select {
                    bind(
                        edits = filterDate.lens(
                            get = { it?.type },
                            modify = { o, it -> if (o?.type == it) o else it?.let { FilterDate(it) } }
                        ),
                        data = Constant(listOf(null) + DateOptions.entries),
                        render = {
                            it?.toString() ?: "Anytime"
                        }
                    )
                }
            }
            onlyWhen { filterDate()?.type == DateOptions.Custom } - row {
                expanding - fieldTheme - localDateField {
                    content bind filterDate.lens(
                        get = { it?.customStart },
                        modify = { old, value -> old?.copy(customStart = value) }
                    )
                }
                centered - bold - subtext("to")
                expanding - fieldTheme - localDateField {
                    content bind filterDate.lens(
                        get = { it?.customEnd },
                        modify = { old, value -> old?.copy(customEnd = value) }
                    )
                }
            }

            expanding - col {
                spacing = 0.dp
                FieldLabelSemantic.onNext - text("Uploaded by:")
                expanding - frame {
                    val sharees = sharedSuspending {
                        try {
                            sessionNotNull().collection2.listUsers(collection().collection.collectionId).let {
                                (it.users.map {
                                    if (sessionNotNull().authenticatedUser.state.getOrNull()?.userId == it.userId) it.copy(
                                        name = "Me"
                                    ) else it
                                }).sortedByDescending { if (it.name == "Me") 100 else it.participationRights.value }
                                    .distinctBy { it.userId }
                            }
                        } catch (e: Exception) {
                            listOf(
                                CollectionUserData(
                                    userId = sessionNotNull().authenticatedUser().userId,
                                    name = "Me",
                                    rights = Rights.fromRights(setOf(RightsEnum.Read)),
                                    participationRights = Rights.fromRights(setOf(RightsEnum.Read)),
                                )
                            )
                        }
                    }
                    expanding - padded - col {
                        ::exists { !sharees.state().ready }
                        space(); space();space()
                        centered - activityIndicator()
                    }

                    expanding - padded - col {
                        ::exists { sharees.state().getOrNull()?.isEmpty() == true }
                        centered - text("No one has contributed to this collection yet")
                    }
                    expanding - recyclerView {
                        ::exists { sharees.state().getOrNull()?.isNotEmpty() == true }
                        children(items = sharees, id = { it.userId }) { guest ->
                            row {
                                centered - sizeConstraints(width = 1.rem, height = 1.rem) - checkbox {
                                    checked bind shared {
                                        filterGuests().contains(guest())
                                    }.withWrite {
                                        launch {
                                            if (it) filterGuests set filterGuests().plusElement(guest())
                                            else filterGuests set filterGuests().minusElement(guest())
                                        }
                                    }
                                }
                                userAvatar(guest.lens { UserInfo(userId = it.userId, name = it.name) }, size = 1.5.rem)
                                centered - text { ::content { guest().name.anonymousIfEmpty() } }
                            }
                        }
                    }
                }
            }
        }

//        spacingOverrideBeforeNext(0.dp)
        separator()

        col {
            spacing = 2.rem
            actionOrCancelSection(
                onCancel = { pageNavigator.dismiss() },
                constrainedWidth = true,
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Apply")
                        onClick {
                            filterDate()?.let {
                                if (it.type == DateOptions.Custom && (it.customStart == null || it.customEnd == null)) {
                                    showToast("Invalid date range", severity = ToastType.Error); return@let
                                }
                                CollectionState.filterDate set it
                            }
                            CollectionState.filterGuests set filterGuests()
                            pageNavigator.dismiss()
                        }
                    }
                }
            )
        }
    }

}

