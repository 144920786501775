package com.picme.sdk2.caching

import com.picme.sdk2.CachedApi
import com.picme.sdk2.DateOnly
import com.picme.sdk2.DateTime
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.notification.GetUserNotificationEventsResponse
import com.picme.sdk2.generated.notification.MarkNotificationEventReadResponse
import com.picme.sdk2.generated.notification.NotificationDigestEventType
import com.picme.sdk2.generated.notification.NotificationHandlerApi
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone.Companion.UTC
import kotlinx.datetime.toLocalDateTime

class NotificationHandlerCache(val basedOn: NotificationHandlerApi) : CachedApi, NotificationHandlerApi by basedOn {
    private val notifyResponses: HashMap<DateOnly, GetUserNotificationEventsResponse> = hashMapOf()

    override fun clearCache() {
        this.notifyResponses.values.clear()
    }
    override suspend fun getUserNotificationEvents(date: DateOnly): GetUserNotificationEventsResponse {
        if (DateOnly(Clock.System.now().toLocalDateTime(UTC).date) == date) {
            return basedOn.getUserNotificationEvents(date)
        }
        return notifyResponses.getOrPut(date) {
            basedOn.getUserNotificationEvents(date)
        }
    }

    override suspend fun markNotificationEventRead(
        scope: DataScope,
        targetEntityTypeId: RecordTypeId,
        targetEntityId: String,
        eventType: NotificationDigestEventType,
        eventTime: DateTime,
        actorId: String,
        parameters: List<String>?
    ): MarkNotificationEventReadResponse {
        return basedOn.markNotificationEventRead(
            scope,
            targetEntityTypeId,
            targetEntityId,
            eventType,
            eventTime,
            actorId,
            parameters,
        ).also { clearCache() }
    }
}