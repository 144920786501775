package com.picme.views

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.collection2.ListedCollection
import com.picme.sdk2.toSafeEncoded
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

fun ViewWriter.collectionList(closeOnSelect: Boolean, closeDrawer: (() -> Unit)? = null) = col {
    val showSearch = Property(false)
    val searchText = Property("")
    showSearch bind showSearch.withWrite { searchText set "" }

    val selectedTab = Property(SelectedTab.First)
    spacing = 0.5.rem

    sizeConstraints(height = 72.dp) - padded - row {
        padding = 0.5.rem
        centered - buttonTheme - button {
            ::exists { closeDrawer != null }
            icon(PIcon.chevronleft, "Back")
            onClick { closeDrawer?.invoke() }
        }
        centered - h4("Collections")
        expanding - space {}

        centered - buttonTheme - button {
            icon(PIcon.add, "Create collection")
            onClick {
                closePopovers()
                createCollectionModal()
            }
        }
        centered - buttonTheme - button {
            icon(PIcon.search, "Search collection")
            onClick {
                showSearch set !showSearch()
            }
        }
    }

    onlyWhen { showSearch() } - col {
        padding = 0.5.rem
        fieldTheme - row {
            expanding - textInput {
                hint = "Search for a collection"
                content bind searchText
                reactive {
                    if (showSearch()) {
                        requestFocus()
                    }
                }
            }
            centered - button {
                icon { source = PIcon.close.copy(width = 1.rem, height = 1.rem) }
                onClick {
                    showSearch set !showSearch()
                    searchText set ""
                }
            }
        }
    }

    val hasCollections = shared {
        session()?.collection2?.listCollectionsLive()?.all()?.isNotEmpty() == true
    }
    expanding - frame {
        ::exists { !hasCollections() }
        centered - col {
            centered - h4("No collections yet \uD83D\uDE22")
            centered - important - buttonTheme - sizeConstraints(
                width = 16.rem,
                height = 42.dp
            ) - button {
                centered - row {
                    centered - icon(PIcon.add, "Add")
                    centered - h4("Create Collection")
                }
                onClick {
                    closePopovers()
                    createCollectionModal()
                }
            }
        }
    }
    expanding - col {
        ::exists { hasCollections() }
        tabSelect(selectedTab, "My Collections", "Shared With Me")
        spacing = 0.px

        val myCollectionsList = shared {
            session.awaitNotNull().collection2.listCollectionsLive().all().filter {
                ownsCollection(it) && it.collection.name.contains(searchText(), true)
            }
        }
        val sharedWithMeCollections = shared {
            session.awaitNotNull().collection2.listCollectionsLive().all().filter {
                !ownsCollection(it) && it.collection.name.contains(searchText(), true)
            }
        }


        col {
            exists =
                false; ::exists { myCollectionsList().isEmpty() && sharedWithMeCollections().isEmpty() && searchText().isNotEmpty() }
            space(8.0)
            centered - FadedSemantic.onNext - subtext("No collections that match")
        }

        suspend fun clickCol(coll: Readable<ListedCollection>) {
            if (closeOnSelect) closeDrawer?.invoke()
            CollectionState.clearFilters()
            mainPageNavigator.reset(CollectionImageView(coll().collection.collectionId.raw.toSafeEncoded()))
        }

        val currentCollectionId = shared { (mainPageNavigator.currentPage() as? PageForCollection)?.collectionId }

        launch {
            val isInMine =
                myCollectionsList().firstOrNull { it.collection.collectionId == currentCollectionId() } != null
            selectedTab.value = if (isInMine) SelectedTab.First else SelectedTab.Second
        }

        recycler(
            items = myCollectionsList,
            clickListItem = ::clickCol,
            setup = { recycler ->
                reactive {
                    recycler?.exists = selectedTab() == SelectedTab.First && myCollectionsList().isNotEmpty()
                }

                reactive {
                    if (selectedTab() == SelectedTab.First) {
                        val collections = myCollectionsList()
                        launch {
                            delay(16)
                            val currentC = currentCollectionId()?.raw ?: return@launch
                            val currIdx = collections.indexOfFirst { it.collection.collectionId.raw == currentC }
                            recycler?.scrollToIndex(currIdx, align = Align.Center)
                        }
                    }
                }
            }
        )

        recycler(
            items = sharedWithMeCollections,
            clickListItem = ::clickCol,
            setup = { recycler ->
                reactive {
                    recycler?.exists = selectedTab() == SelectedTab.Second && sharedWithMeCollections().isNotEmpty()
                }

                reactive {
                    if (selectedTab() == SelectedTab.Second) {
                        val collections = sharedWithMeCollections()
                        launch {
                            delay(16)
                            val currentC = currentCollectionId()?.raw ?: return@launch
                            val currIdx = collections.indexOfFirst { it.collection.collectionId.raw == currentC }
                            recycler?.scrollToIndex(currIdx, align = Align.Center)
                        }
                    }
                }
            }
        )
    }
}

private fun ViewWriter.recycler(
    items: Readable<List<ListedCollection>>,
    clickListItem: suspend (Readable<ListedCollection>) -> Unit,
    setup: ViewWriter.(recycler1: RecyclerView?) -> Unit
) {
    var recycler: RecyclerView?
    val currentCollectionId = shared { (mainPageNavigator.currentPage() as? PageForCollection)?.collectionId }
    expanding - MenuOptionContainerSemantic.onNext - recyclerView {
        recycler = this
        exists = false;
        spacing = 0.px
        children(items, id = { it.collection.collectionId }) { coll ->
            MenuOptionSemantic.onNext - button {
                dynamicTheme {
                    if (coll().collection.collectionId == currentCollectionId()) SelectedSemantic
                    else null
                }
                val weAreOwner = shared { ownsCollection(coll()) }
                onClick {
                    clickListItem(coll)
                }
                row {
                    sizeConstraints(height = 1.125.rem, width = 1.125.rem) - centered - icon {
                        source = PIcon.folder
                        ::source {
                            if (weAreOwner()) PIcon.folder
                            else PIcon.guests
                        }
                    }

                    expanding - centered - col {
                        spacingAndPadding = 0.dp
                        text { ::content { coll().collection.name } }
                        FadedSemantic.onNext - subtext {
                            ::exists  { !weAreOwner() }
                            ::content { coll().userParticipationRights.rightsOnCollection() }
                        }
                    }
                }
            }
        }
    }
    setup(recycler)
}