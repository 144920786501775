package com.picme.sdk2.caching

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.readable.*
import com.picme.imageIfExists
import com.picme.sdk2.CachedApi
import com.picme.sdk2.generated.authentication.AuthenticationHandlerApi
import com.picme.sdk2.generated.authentication.GetMyUserInfoResponse
import com.picme.sdk2.generated.authentication.SetUserAttributesResponse
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

interface AuthHandlerApiCacheable : CachedApi, AuthenticationHandlerApi {
    suspend fun putProfilePicture(file: FileReference)
    val profileImage: Readable<ImageRemote?>
}

class AuthenticationHandlerCache(
    val basedOn: AuthenticationHandlerApi,
    val clock: Clock = Clock.System,
    val invalidateUser: suspend () -> Unit,
) : AuthHandlerApiCacheable, AuthenticationHandlerApi by basedOn {

    private var cacheLife = 5.minutes
    private var lastUserFetch = Property(Instant.DISTANT_PAST)

    init {
        AppScope.launch { getMyUserInfo() }
        lastUserFetch.addListener {
            AppScope.launch { getMyUserInfo() }
        }
    }

    private val lastUser = LateInitProperty<GetMyUserInfoResponse>()

    override val profileImage: Readable<ImageRemote?> = sharedSuspending {
        lastUser()
        var count = 0
        var image: ImageRemote? = null
        while (count++ < 6) {
            delay(2.seconds)
            image = getMyUserInfo().profilePicture.imageIfExists()
            if (image != null) break
            count += 1
        }
        image
    }

    override fun clearCache() {
        lastUserFetch.value = Instant.DISTANT_PAST
    }


    override suspend fun getMyUserInfo(): GetMyUserInfoResponse {
        return if (clock.now() - lastUserFetch.value > cacheLife) {
            lastUserFetch.value = now()
            val result = basedOn.getMyUserInfo()
            lastUser.value = result
            result
        } else lastUser.await()
    }

    private suspend fun checkForChangedProfileImage() {
        val old = getMyUserInfo().profilePicture
        delay(3.seconds) // The server returns a new URL for the image in s3, but it sometimes isn't uploaded for a couple seconds
        var count = 0
        while (count++ < 100) {
            delay(1.seconds)
            if (old != basedOn.getMyUserInfo().profilePicture) break
        }
        clearCache()
    }

    override suspend fun putProfilePicture(file: FileReference) {
        val uploadInfo = getUserProfileUploadUrl(file.mimeType())
        fetch(
            uploadInfo.profilePictureLocation,
            HttpMethod.PUT,
            body = RequestBodyFile(file)
        )
        checkForChangedProfileImage()
    }

    override suspend fun deleteUserProfilePicture() {
        return basedOn.deleteUserProfilePicture().also {
            checkForChangedProfileImage()
        }
    }

    override suspend fun setUserAttributes(name: String?, setTosRead: Boolean): SetUserAttributesResponse {
        return basedOn.setUserAttributes(name, setTosRead).also {
            lastUser.state.onSuccess {
                lastUser.value = it.copy(
                    userData = it.userData.copy(
                        name = name ?: it.userData.name,
                        termsOfServiceRead = if (setTosRead) true else it.userData.termsOfServiceRead,
                    )
                )
            } ?: run {
                clearCache()
            }
            invalidateUser()
        }
    }
}
