package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.WaitGate
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.atCenterEnd
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.l2.overlayFrame
import com.lightningkite.now
import com.lightningkite.readable.*
import com.picme.actuals.animatePulsating
import com.picme.components.*
import com.picme.sdk2.*
import com.picme.sdk2.caching.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.MimeType
import com.picme.sdk2.generated.UserId
import com.picme.sdk2.generated.UserInfo
import com.picme.sdk2.generated.collection2.*
import com.picme.views.*
import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.random.Random


val Icon.Companion.dropdown
    get() = Icon(
        width = 1.8.rem,
        height = 1.8.rem,
        viewBoxMinX = 0,
        viewBoxMinY = -960,
        viewBoxWidth = 960,
        viewBoxHeight = 960,
        pathDatas = listOf("M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z")
//    strokePathDatas = listOf(StrokePathData(strokeWidth = 2.dp, path = "M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"))
    )

fun String.pluralize(count: Int, pluralForm: String = "${this}s"): String = if (count == 1) this else pluralForm

fun Instant?.renderRelativeToString(): String {
    if (this == null) return ""
    val dt = this.toLocalDateTime(TimeZone.currentSystemDefault())
    val today = now().toLocalDateTime(TimeZone.currentSystemDefault())

    val hour = dt.hour % 12
    val formattedHour = if (hour == 0) 12 else hour // Convert 0 to 12 for 12 AM
    val minute = dt.minute.toString().padStart(2, '0')
    val period = if (dt.hour < 12) "am" else "pm"
    val formattedTime = "$formattedHour:$minute$period"

    val isToday =
        dt.year == today.year && dt.month == today.month && today.dayOfMonth == dt.dayOfMonth
    val isYesterday =
        dt.year == today.year && today.month == dt.month && today.dayOfMonth - 1 == dt.dayOfMonth

    return when {
        isToday -> "$formattedTime today"
        isYesterday -> "$formattedTime yesterday"
        else -> "${this.renderDateToString(size = RenderSize.Abbreviation)} $formattedTime"
    }
}

fun String.validDownloadableName(): String {
    return this.split("/").last().replace(" ", "_").replace("~", "_").replace("+", "_")
}

val Session.isVerifiedAccount
    get() = this.let { shared { it.authenticatedUser().emailVerified || it.authenticatedUser().phoneNumberVerified } }


fun ownsCollection(collection: ListedCollection?): Boolean {
    if (collection == null) return false
    val userId = session.state.raw?.authenticatedUser?.state?.getOrNull()?.userId ?: UserId("")
    return ownsCollection(collection, userId)
}

fun ownsCollection(collection: ListedCollection?, userId: UserId): Boolean {
    if (collection == null) return false
    return userId.raw == collection.collection.creatorUserId.raw
}

fun ownsPCollection(collection: PCollection?): Readable<Boolean> {
    return shared {
        if (collection == null) false
        else {
            session()?.authenticatedUser()?.userId?.let { userId ->
                ownsCollection(
                    session()?.collection2?.getCollectionLive(collection.collectionId)?.await(),
                    userId
                )
            } ?: false
        }
    }
}

fun PCollection.images(filters: UploadCondition = emptyFilter): Paged<ListedUpload> {
    return sessionNotNull.state.raw.collection2.listUploadsLive(this@images.collectionId, filters)
}

fun PCollection.trashedImages(): Paged<ListedUpload> {
    return sessionNotNull.state.raw.collection2.listDeletedUploadsLive(this.collectionId)
}


val imgMap = mutableMapOf<String, Boolean>()
suspend fun String.imageIfExists(): ImageRemote? {
    if (this.isEmpty()) return null
    return imgMap[this]?.let { if (it) ImageRemote(this) else null } ?: (try {
        if (fetch(this).ok) ImageRemote(this)
        else null
    } catch (e: Exception) {
        null
    }).also { imgMap[this] = it != null }
}


fun List<ListedUpload>.toRecyclableInfo(): List<RecyclerInfo> = map {
    RecyclerInfo(
        id = it.uploadId.raw,
        thumbnail = uploadToLocal[it.uploadId] ?: it.thumbnailUrl.let(::ImageRemote),
        mimeType = it.mimeType.raw
    )
}


val isSmallPage = shared {
    AppState.windowInfo().width < 1020.dp
}

expect suspend fun ViewWriter.chooseImagesFromLibrary(onResult: suspend (List<FileReference>) -> Unit)

suspend fun ViewWriter.uploadExternalFiles(
    collId: CollectionId,
    onFinish: (() -> Unit) = {}
) {
    if (Platform.current == Platform.Android) {
        chooseImagesFromLibrary { chosen: List<FileReference> ->
            this.uploadAllExternalFiles(collId, chosen, onFinish)
        }
    } else {
        val chosen = ExternalServices.requestFiles(listOf("image/*", "video/*"))
        if (chosen.isEmpty()) return

        uploadAllExternalFiles(collId, chosen, onFinish)
    }
}

suspend fun ViewWriter.uploadPicFromCamera(
    collId: CollectionId,
    onFinish: () -> Unit = {}
) {
    val chosen = ExternalServices.requestCaptureEnvironment(listOf("image/*")) ?: return // "video/*"

    uploadAllExternalFiles(collId, listOf(chosen), onFinish)
}

suspend fun ViewWriter.uploadExternalFilesFirstTime(
    collId: CollectionId,
    onFinish: (() -> Unit) = {}
) {
    val chosen = ExternalServices.requestFiles(listOf("image/*", "video/*"))
    if (chosen.isEmpty()) return

    val name = (session()?.authenticatedUser()?.name ?: "").ifBlank { askForName().await() }
    if (name.isBlank()) return

    uploadAllExternalFiles(
        collId = collId,
        onFinish = onFinish,
        chosen = chosen,
        waitFor = async {
            if (session.state.raw?.isVerifiedAccount() != true) {
                createAccountIfNewUser(name)
            }
            acceptQrCode()
        }
    )
}

suspend fun ViewWriter.uploadAllExternalFiles(
    collId: CollectionId,
    chosen: List<FileReference>,
    onFinish: (() -> Unit) = {},
    waitFor: Deferred<Unit>? = null
) {

    val useBackgroundUpload = false //Platform.current == Platform.iOS
    if (useBackgroundUpload) {
        try {
            waitFor?.await()
        } catch (_: CancellationException) {
        }
        for (file in chosen) {
            val uploadInfo = session.awaitNotNull().collection2.createUpload(
                collectionId = collId,
                body = CreateUploadBody(
                    filename = file.fileName(),
                    contentType = MimeType(file.mimeType()),
                ),
                allowDuplicates = true
            )
        }
    } else {
        val map = HashMap<FileReference, Deferred<ContinueUpload>>()
        fun prepare(file: FileReference) = map.getOrPut(file) {
            AppScope.async {
                session.awaitNotNull().collection2.createUpload(
                    collectionId = collId,
                    data = RequestBodyFile(file),
                    hashCode = Random.nextLong().toString()  // TODO: Actually hash the file's contents
                )
            }
        }

        var successes = 0
        openProgressModal(
            title = "Uploading",
            execute = {
                itemCount = chosen.size
                image = chosen[0].let(::ImageLocal)
                try {
                    waitFor?.await()
                } catch (_: CancellationException) {
                }
                chosen.indices.forEach { index ->
                    image = chosen[index].let(::ImageLocal)
                    currentIndex = index
                    val c = prepare(chosen[index])
                    chosen.getOrNull(index + 1)?.let(::prepare)
                    chosen.getOrNull(index + 2)?.let(::prepare)
                    chosen.getOrNull(index + 3)?.let(::prepare)
                    try {
                        c.await().invoke { individualItemProgress = it.toFloat() }
                        individualItemProgress = 1f
                        successes++
                    } catch (e: Exception) {
                        e.report("image upload")
                    }
                }
            },
            onComplete = {
                if (successes > 0) {
                    showToast(
                        primary = "Successfully uploaded $successes ${"file".pluralize(successes)}.",
                        secondary = "Files will be available soon.",
                        duration = 1000
                    )
                    onFinish()
                    AppScope.launch {
                        delay(8000)
                        forceRefresh()
                    }

                }
            }
        )
    }
}

fun ViewWriter.restoreImages(collectionId: CollectionId, images: List<ListedUpload>, all: Boolean = false) {
    var successes = 0
    openProgressModal("Restoring", execute = {
        itemCount = images.size
        for ((index, it) in images.withIndex()) {
            currentIndex = index
            image = it.thumbnailUrl.let(::ImageRemote)
            try {
                session.awaitNotNull().collection2.restoreDeletedUploads(
                    collectionId = collectionId,
                    uploadIds = listOf(it.uploadId)
                )
                successes++
            } catch (e: Exception) {
                e.report()
            }
        }
    }, onComplete = {
        if (successes > 0) {
            if (all) showToast("Successfully restored all items")
            else showToast("Successfully restored ${"image".pluralize(images.size)}")
        }
    })
}

fun ViewWriter.deleteImages(collectionId: CollectionId, images: List<ListedUpload>, all: Boolean = false) {
    var successes = 0
    openProgressModal(
        title = "Deleting",
        execute = {
            itemCount = images.size
            images.forEachIndexed { index, it ->
                currentIndex = index
                try {
                    image = it.thumbnailUrl.let(::ImageRemote)
                    session.awaitNotNull().collection2.deleteUpload(
                        collectionId = collectionId,
                        uploadId = it.uploadId
                    )
                    individualItemProgress = 1f
                    successes++

                } catch (e: Exception) {
                    println("FAILED to delete image: $e")
                }
            }
        },
        onComplete = {
            if (successes > 0) {
                if (all) showToast("Successfully deleted all items")
                else showToast("Successfully moved ${"image".pluralize(images.size)} to trash")
            }

        }
    )
}

val successZipLink = Property<String?>(null)
expect suspend fun ViewWriter.collectionDownload(collectionId: CollectionId, uploadIds: List<UploadId>? = null)

suspend fun ViewWriter.collectionShareSeparate(collectionId: CollectionId, uploadIds: Set<UploadId>? = null) {
    val images = session.awaitNotNull().collection2.listUploadsLive(collectionId, emptyFilter).all()
        .filter { if (uploadIds != null) it.uploadId in uploadIds else true }

    openProgressModal(
        title = "Preparing to share files",
        execute = {
            itemCount = images.size
            ExternalServices.share(images.mapIndexed { index, it ->
                currentIndex = index
                image = it.thumbnailUrl.let(::ImageRemote)
                val filename = it.uploadId.raw.validDownloadableName()
                val uploadDetails = session.awaitNotNull().collection2.getUploadLive(
                    collectionId = collectionId,
                    uploadId = it.uploadId
                )
                val downloadURL = uploadDetails().upload.location
                filename to fetch(url = downloadURL, onDownloadProgress = { downloaded, expected ->
                    individualItemProgress = downloaded.toDouble().div(expected).toFloat()
                }).blob()
            })
        },
        onComplete = {}
    )
}

suspend fun ViewWriter.navigateToCollOrLanding(
    notThisCollection: CollectionId? = null
) {
    val coll = session.awaitNotNull().collection2.listCollectionsLive().all().firstOrNull {
        it.collection.collectionId != notThisCollection
    }?.collection
    val navTo = coll?.let {
        CollectionImageView(it.collectionId.raw.toSafeEncoded())
    } ?: NoCollectionsPage

    dialogPageNavigator.clear()
    mainPageNavigator.reset(navTo)
}

suspend fun ViewWriter.acceptQrCode() {
    val invite = acceptingInvite() ?: return
    if (invite.alreadyAccepted) return postAccept()
    val requireFullAuth = (invite.qrCode?.clientInfo()?.type != InviteType.RequestUploads ||
            (invite.legacy?.sharingAuthCode != null))

    val session = session() ?: return
    if (requireFullAuth && !session.isVerifiedAccount()) return postAccept()

    if (invite.qrCode != null) {
        try {
            session.collection2.activateInviteCode(invite.qrCode.inviteCodeId)
        } catch (e: Exception) {
            e.printStackTrace2()
        }

        if (invite.collection != null) {
            val secondaryText = if (invite.qrCode.clientInfo()?.type == InviteType.ShareColl) {
                "You can now view the collection!"
            } else null
            showToast("Invitation accepted", secondaryText)
        }
        markAsAccepted()
    }

    if (invite.legacy != null) {
        val collectionId = invite.collection ?: return
        if (invite.legacy.sharingAuthCode == null) {
            session.collection2.requestConnect(collectionId)
        } else {
            session.collection2.sharingConnect(invite.legacy.sharingAuthCode, collectionId.raw)
        }
        markAsAccepted()
    }

    postAccept()
}

suspend fun markAsAccepted() {
    acceptingInvite set acceptingInvite()?.copy(alreadyAccepted = true)
    if (session() != null) {
        forceRefresh()
    }
}

suspend fun ViewWriter.postAccept() {
    val fullAuth = session()?.isVerifiedAccount() ?: false
    val invite = acceptingInvite() ?: return

    if (fullAuth) {
        if (invite.collection != null) {
            dialogPageNavigator.clear()
            mainPageNavigator.reset(CollectionImageView(invite.collection.raw.toSafeEncoded()))
        } else {
            navigateToCollOrLanding()
        }
        acceptingInvite set null
        return
    }

    val type = invite.qrCode?.clientInfo()?.type


    val nextPage = when (type) {
        InviteType.Referral -> LoginOrSignUp()
        InviteType.ShareColl -> LoginOrSignUp()
        null -> LoginOrSignUp()
        else -> null
    }

    if (nextPage != null) {
        mainPageNavigator.reset(nextPage)
    }
}


suspend fun createAccountIfNewUser(name: String = "") {
    if (sessionRefreshToken() == null) {
        emailOrPhone set ""
        val createPartialRes = unauthApi().authenticationHandler.authenticateAsGuest(
            setTosRead = true,
            userName = name,
            cancel = null,
            accessToken = { null }
        )
        sessionRefreshToken set createPartialRes.authenticated.refreshToken
    }
}

fun ViewWriter.fullPageLoading(exists: Readable<Boolean> = Constant(true)) {
    gravityCentered - col {
        ::exists { exists() }
        gravityCentered - picmeIconDisplay(64.dp)
        ImportantForegroundSemantic.onNext - HeaderSemantic.onNext - text {
            align = Align.Center
            content = "capture experiences"
        }
        animatePulsating()
    }
}

fun String.isValidEmailOrPhone(): Boolean {
    return this.matches(Regex("^[a-zA-Z0-9.!#\$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\$")) ||
            this.isPhone
}

val String.isPhone get() = this.replace(Regex("[^0-9]"), "").length == 10


suspend fun removeCaches() {
    GeneratedQrCache.invalidateAll()
    InviteCache.invalidateAll()
    session()?.let {
        if (it.collection2 is CachedApi) it.collection2.clearCache()
        if (it.ad is CachedApi) it.ad.clearCache()
        if (it.discussion is CachedApi) it.discussion.clearCache()
        if (it.poll is CachedApi) it.poll.clearCache()
        if (it.notification is CachedApi) it.notification.clearCache()
        it.authentication.clearCache()
    }
}

fun formatBytes(bytes: Long): String {
    if (bytes < 1024) return "$bytes B"

    val units = arrayOf("KB", "MB", "GB", "TB", "PB", "EB")
    var value = bytes.toDouble()
    var index = -1

    while (value >= 1024 && index < units.lastIndex) {
        value /= 1024
        index++
    }

    val roundedValue = (value * 100).toInt() / 100.0
    return "$roundedValue ${units[index]}"
}


suspend fun forceRefresh() {
    val api = session()?.collection2
    if (api is CollectionHandler2ApiCacheableLive2) {
        api.forceRefresh()
    }
}

val advertisementBelowUpload = PersistentProperty<Boolean>("ad-below-upload", false)

val UserInfo.isMe get() = this.userId == sessionNotNull.state.getOrNull()?.authenticatedUser?.state?.getOrNull()?.userId


fun <T> List<T>.secondToLastOrNull(): T? {
    return if (size < 2) null else this[size - 2]
}

private fun ViewWriter.askForName(): Deferred<String> {
    val gate = WaitGate()
    val overlay = overlayFrame ?: throw Error("")
    val page = with(overlay) {
        dismissBackground {
            centered - sizeConstraints(maxWidth = 32.rem) - FatCardSemantic.onNext - col {
                frame {
                    centered - h4 {
                        content = "What's your name?"
                    }
                    atCenterEnd - buttonTheme - button {
                        icon(PIcon.close, "Close")
                        onClick {
                            createUserName set ""
                            gate.permit = true
                        }
                    }
                }
                FadedSemantic.onNext - text {
                    content = "Enter your name or generate a random one to remain anonymous."
                    align = Align.Center
                }

                space()

                askForNameForm(
                    Action(
                        title = "Submit",
                        action = {
                            if (createUserName().isNotBlank()) {
                                gate.permit = true
                            }
                        }
                    )
                )

                frame {
                    spacing = 0.dp
                    ::exists { !WelcomeTOSLanding.acknowledged() }
                    eula("By clicking Continue, you agree to our")
                }
            }
        }
    }

    return async {
        gate.await()
        overlay.removeChild(page)
        createUserName()
    }
}
