package com.picme

import com.lightningkite.kiteui.navigation.*
import com.picme.views.likes.ItemLikesPage
import com.picme.views.share.GuestView
import com.picme.views.share.ShareView
import com.picme.views.PasswordAndSecurity
import com.picme.views.Slideshow
import com.picme.views.LandingPage
import com.picme.views.MobileUnverifiedUserLanding
import com.picme.views.NoCollectionsPage
import com.picme.views.ReferAFriend
import com.picme.views.CollectionImageView
import com.picme.views.FinishCreateAccount
import com.picme.views.QrAccept
import com.picme.views.notifications.NotificationPage
import com.picme.views.CreateAccount
import com.picme.views.ViewTrash
import com.picme.views.ProfilePage
import com.picme.views.comments.ItemCommentsPage
import com.picme.views.comments.CollectionCommentsPage
import com.picme.views.LoginOrSignUp
import com.picme.views.LogInPage
import com.picme.views.LegacyUpload
import com.picme.views.LegacyShare
import com.picme.views.QrCodeOld
import com.picme.views.CollectionSettings
import com.picme.views.ResetPassword
import com.picme.views.ConfirmResetPassword
import com.picme.views.Plan
import com.picme.views.WelcomeTOSLanding
import com.picme.views.ConfirmEmail
import com.picme.components.Filters
import com.picme.components.ImageViewPager
import com.picme.sso.ErrorPageGoogle
import com.picme.sso.ErrorPageApple
import com.picme.sso.GoogleSSOLanding
import com.picme.sso.AppleSSOLanding
import com.picme.installrefer.InstallReferStartPage
import com.picme.installrefer.InstallReferFinishPage
import kotlinx.serialization.ExperimentalSerializationApi


@OptIn(ExperimentalSerializationApi::class)
val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "collections") return@label null
            if (it.segments[2] != "items") return@label null
            if (it.segments[3] != "likes") return@label null
            ItemLikesPage(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap("item", it.parameters, this.item)
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "guests") return@label null
            GuestView(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "share") return@label null
            ShareView(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "password-and-security") return@label null
            PasswordAndSecurity
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "slideshow") return@label null
            Slideshow(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            LandingPage(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "collection-unverified") return@label null
            MobileUnverifiedUserLanding
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "collection") return@label null
            NoCollectionsPage
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "refer-a-friend") return@label null
            ReferAFriend
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "collection") return@label null
            CollectionImageView(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "create-account-finish") return@label null
            FinishCreateAccount
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "in") return@label null
            QrAccept(
                codeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "notifications") return@label null
            NotificationPage
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "create-account") return@label null
            CreateAccount
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "trash") return@label null
            ViewTrash(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "account") return@label null
            ProfilePage
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "collections") return@label null
            if (it.segments[2] != "items") return@label null
            if (it.segments[3] != "comments") return@label null
            ItemCommentsPage(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap("item", it.parameters, this.item)
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collections") return@label null
            if (it.segments[2] != "comments") return@label null
            CollectionCommentsPage(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "login-or-signup") return@label null
            LoginOrSignUp(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "log-in") return@label null
            LogInPage
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "up") return@label null
            LegacyUpload(
                urlSafeCollId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "up") return@label null
            LegacyShare(
                urlSafeCollId = UrlProperties.decodeFromString(it.segments[1]),
                sharingAuthCode = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "qr") return@label null
            QrCodeOld(
                qrCodeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "settings") return@label null
            CollectionSettings(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "reset-password") return@label null
            ResetPassword
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "confirm-reset-password") return@label null
            ConfirmResetPassword
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "plan") return@label null
            Plan
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "welcome") return@label null
            WelcomeTOSLanding
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "confirm-email") return@label null
            ConfirmEmail(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "filters") return@label null
            Filters(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "collection") return@label null
            if (it.segments[2] != "images") return@label null
            ImageViewPager(
                urlSafeId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
                UrlProperties.decodeFromStringMap("imageId", it.parameters, this.imageId)
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "sso-google") return@label null
            if (it.segments[1] != "error") return@label null
            ErrorPageGoogle(
                code = UrlProperties.decodeFromString(it.segments[2]),
                message = UrlProperties.decodeFromString(it.segments[3]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 4) return@label null
            if (it.segments[0] != "sso-apple") return@label null
            if (it.segments[1] != "error") return@label null
            ErrorPageApple(
                code = UrlProperties.decodeFromString(it.segments[2]),
                message = UrlProperties.decodeFromString(it.segments[3]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "sso-google") return@label null
            GoogleSSOLanding(
                access = UrlProperties.decodeFromString(it.segments[1]),
                refresh = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "sso-apple") return@label null
            AppleSSOLanding(
                access = UrlProperties.decodeFromString(it.segments[1]),
                refresh = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "install-refer-start") return@label null
            InstallReferStartPage
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "install-refer-finish") return@label null
            InstallReferFinishPage(
            ).apply {
                UrlProperties.decodeFromStringMap("lastViewedCollection", it.parameters, this.lastViewedCollection)
                UrlProperties.decodeFromStringMap("shouldOpenQrCodeId", it.parameters, this.shouldOpenQrCodeId)
                UrlProperties.decodeFromStringMap("refreshToken", it.parameters, this.refreshToken)
            }
        },
    ),
    renderers = mapOf(
        ItemLikesPage::class to label@{
            if (it !is ItemLikesPage) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.item.value, "item", p)
            RouteRendered(UrlLikePath(
                segments = listOf("collections", UrlProperties.encodeToString(it.urlSafeId), "items", "likes"),
                parameters = p
            ), listOf(it.item))
        },
        GuestView::class to label@{
            if (it !is GuestView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "guests"),
                parameters = p
            ), listOf())
        },
        ShareView::class to label@{
            if (it !is ShareView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "share"),
                parameters = p
            ), listOf())
        },
        PasswordAndSecurity::class to label@{
            if (it !is PasswordAndSecurity) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("password-and-security"),
                parameters = p
            ), listOf())
        },
        Slideshow::class to label@{
            if (it !is Slideshow) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "slideshow"),
                parameters = p
            ), listOf())
        },
        LandingPage::class to label@{
            if (it !is LandingPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        MobileUnverifiedUserLanding::class to label@{
            if (it !is MobileUnverifiedUserLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection-unverified"),
                parameters = p
            ), listOf())
        },
        NoCollectionsPage::class to label@{
            if (it !is NoCollectionsPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection"),
                parameters = p
            ), listOf())
        },
        ReferAFriend::class to label@{
            if (it !is ReferAFriend) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("refer-a-friend"),
                parameters = p
            ), listOf())
        },
        CollectionImageView::class to label@{
            if (it !is CollectionImageView) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId)),
                parameters = p
            ), listOf())
        },
        FinishCreateAccount::class to label@{
            if (it !is FinishCreateAccount) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("create-account-finish"),
                parameters = p
            ), listOf())
        },
        QrAccept::class to label@{
            if (it !is QrAccept) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("in", UrlProperties.encodeToString(it.codeId)),
                parameters = p
            ), listOf())
        },
        NotificationPage::class to label@{
            if (it !is NotificationPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("notifications"),
                parameters = p
            ), listOf())
        },
        CreateAccount::class to label@{
            if (it !is CreateAccount) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("create-account"),
                parameters = p
            ), listOf())
        },
        ViewTrash::class to label@{
            if (it !is ViewTrash) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "trash"),
                parameters = p
            ), listOf())
        },
        ProfilePage::class to label@{
            if (it !is ProfilePage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("account"),
                parameters = p
            ), listOf())
        },
        ItemCommentsPage::class to label@{
            if (it !is ItemCommentsPage) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.item.value, "item", p)
            RouteRendered(UrlLikePath(
                segments = listOf("collections", UrlProperties.encodeToString(it.urlSafeId), "items", "comments"),
                parameters = p
            ), listOf(it.item))
        },
        CollectionCommentsPage::class to label@{
            if (it !is CollectionCommentsPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collections", UrlProperties.encodeToString(it.urlSafeId), "comments"),
                parameters = p
            ), listOf())
        },
        LoginOrSignUp::class to label@{
            if (it !is LoginOrSignUp) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("login-or-signup"),
                parameters = p
            ), listOf())
        },
        LogInPage::class to label@{
            if (it !is LogInPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("log-in"),
                parameters = p
            ), listOf())
        },
        LegacyUpload::class to label@{
            if (it !is LegacyUpload) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("up", UrlProperties.encodeToString(it.urlSafeCollId)),
                parameters = p
            ), listOf())
        },
        LegacyShare::class to label@{
            if (it !is LegacyShare) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("up", UrlProperties.encodeToString(it.urlSafeCollId), UrlProperties.encodeToString(it.sharingAuthCode)),
                parameters = p
            ), listOf())
        },
        QrCodeOld::class to label@{
            if (it !is QrCodeOld) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("qr", UrlProperties.encodeToString(it.qrCodeId)),
                parameters = p
            ), listOf())
        },
        CollectionSettings::class to label@{
            if (it !is CollectionSettings) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "settings"),
                parameters = p
            ), listOf())
        },
        ResetPassword::class to label@{
            if (it !is ResetPassword) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("reset-password"),
                parameters = p
            ), listOf())
        },
        ConfirmResetPassword::class to label@{
            if (it !is ConfirmResetPassword) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("confirm-reset-password"),
                parameters = p
            ), listOf())
        },
        Plan::class to label@{
            if (it !is Plan) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("plan"),
                parameters = p
            ), listOf())
        },
        WelcomeTOSLanding::class to label@{
            if (it !is WelcomeTOSLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("welcome"),
                parameters = p
            ), listOf())
        },
        ConfirmEmail::class to label@{
            if (it !is ConfirmEmail) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("confirm-email"),
                parameters = p
            ), listOf())
        },
        Filters::class to label@{
            if (it !is Filters) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "filters"),
                parameters = p
            ), listOf())
        },
        ImageViewPager::class to label@{
            if (it !is ImageViewPager) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.imageId.value, "imageId", p)
            RouteRendered(UrlLikePath(
                segments = listOf("collection", UrlProperties.encodeToString(it.urlSafeId), "images"),
                parameters = p
            ), listOf(it.imageId))
        },
        ErrorPageGoogle::class to label@{
            if (it !is ErrorPageGoogle) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sso-google", "error", UrlProperties.encodeToString(it.code), UrlProperties.encodeToString(it.message)),
                parameters = p
            ), listOf())
        },
        ErrorPageApple::class to label@{
            if (it !is ErrorPageApple) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sso-apple", "error", UrlProperties.encodeToString(it.code), UrlProperties.encodeToString(it.message)),
                parameters = p
            ), listOf())
        },
        GoogleSSOLanding::class to label@{
            if (it !is GoogleSSOLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sso-google", UrlProperties.encodeToString(it.access), UrlProperties.encodeToString(it.refresh)),
                parameters = p
            ), listOf())
        },
        AppleSSOLanding::class to label@{
            if (it !is AppleSSOLanding) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("sso-apple", UrlProperties.encodeToString(it.access), UrlProperties.encodeToString(it.refresh)),
                parameters = p
            ), listOf())
        },
        InstallReferStartPage::class to label@{
            if (it !is InstallReferStartPage) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("install-refer-start"),
                parameters = p
            ), listOf())
        },
        InstallReferFinishPage::class to label@{
            if (it !is InstallReferFinishPage) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.lastViewedCollection.value, "lastViewedCollection", p)
            UrlProperties.encodeToStringMap(it.shouldOpenQrCodeId.value, "shouldOpenQrCodeId", p)
            UrlProperties.encodeToStringMap(it.refreshToken.value, "refreshToken", p)
            RouteRendered(UrlLikePath(
                segments = listOf("install-refer-finish"),
                parameters = p
            ), listOf(it.lastViewedCollection, it.shouldOpenQrCodeId, it.refreshToken))
        },
    ),
)
