package com.picme.sso

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.readable.Constant
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.readable.AppScope
import com.picme.*
import com.picme.sdk2.Session
import com.picme.sdk2.generated.UserAuthenticated
import com.picme.sdk2.unauthApi
import com.picme.views.LoginOrSignUp
import kotlinx.coroutines.launch

@Routable("/sso-google/{access}/{refresh}")
class GoogleSSOLanding(val access: String, val refresh: String) : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        return frame {
            AppScope.launch {
                val userAuth = unauthApi().authenticationHandler.refreshUserAuthentication(
                    refresh
                ) { access }.successfulAuthentication?.authenticatedUser

                if (userAuth == null) {
                    mainPageNavigator.reset(LoginOrSignUp(false))
                } else {
//                    val newSession = Session(
//                        unauthApi(), UserAuthenticated(
//                            accessToken = access,
//                            tokenType = "access_token",
//                            refreshToken = refresh,
//                            expiresInSeconds = 100
//                        ), userAuth
//                    )
//                    println(newSession.collection2.listCollectionsLive().all())
//                    session set newSession
                    sessionRefreshToken set refresh
                    navigateToCollOrLanding()
                }
            }

            fullPageLoading()
        }
    }

}