package com.picme.sdk2.generated.discussion

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object DiscussionHandlerEndpointsSerializer : KSerializer<DiscussionHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("DiscussionHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: DiscussionHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): DiscussionHandlerEndpoints {
        val string = decoder.decodeString()
        return DiscussionHandlerEndpoints(string)
    }
}


@Serializable(with = DiscussionHandlerEndpointsSerializer::class)
data class DiscussionHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Patches a previously-posted discussion message. **/
    suspend fun patchDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId,
        body: PatchDiscussionMessageBody,
        accessToken: suspend () -> String? = { null }
    ): PatchDiscussionMessageResponse = fetch(
        url = "$url/dm/${scope.toParamString()}/${discussionOrEntityTypeId.toParamString()}/${discussionOrEntityId.toParamString()}/${discussionMessageId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes a discussion message. **/
    suspend fun deleteDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId,
        accessToken: suspend () -> String? = { null }
    ): DeleteDiscussionMessageResponse = fetch(
        url = "$url/dm/${scope.toParamString()}/${discussionOrEntityTypeId.toParamString()}/${discussionOrEntityId.toParamString()}/${discussionMessageId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Creates a discussion message. **/
    suspend fun createDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        body: CreateDiscussionMessageBody,
        accessToken: suspend () -> String? = { null }
    ): CreateDiscussionMessageResponse = fetch(
        url = "$url/dm/${scope.toParamString()}/${discussionOrEntityTypeId.toParamString()}/${discussionOrEntityId.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Lists discussion messages in the specified discussion. **/
    suspend fun listDiscussionMessages(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        continuation: String?,
        accessToken: suspend () -> String? = { null }
    ): ListDiscussionMessagesResponse = fetch(
        url = "$url/dm/${scope.toParamString()}/${discussionOrEntityTypeId.toParamString()}/${discussionOrEntityId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "continuation" to continuation.toParamString(),
        ).filter { it.value != "null" },
    )
/** Patches a standalone discussion. **/
    suspend fun patchDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
        body: PatchDiscussionBody,
        accessToken: suspend () -> String? = { null }
    ): PatchDiscussionResponse = fetch(
        url = "$url/d/i/${scope.toParamString()}/${discussionId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Gets the details for the specified standalone discussion. **/
    suspend fun getDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
        accessToken: suspend () -> String? = { null }
    ): GetDiscussionResponse = fetch(
        url = "$url/d/i/${scope.toParamString()}/${discussionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Deletes the specified standalone discussion. **/
    suspend fun deleteDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteDiscussionResponse = fetch(
        url = "$url/d/i/${scope.toParamString()}/${discussionId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Creates a standalone discussion. **/
    suspend fun createDiscussion(
        scope: DataScope,
        body: CreateDiscussionBody,
        accessToken: suspend () -> String? = { null }
    ): CreateDiscussionResponse = fetch(
        url = "$url/d/i/${scope.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Lists standalone discussions that the user has rights to. **/
    suspend fun listDiscussions(
        scope: DataScope,
        filterQuery: DiscussionQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListDiscussionsResponse = fetch(
        url = "$url/d/i/${scope.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filterQuery" to filterQuery.toParamString(),
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )
}

