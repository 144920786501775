package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.AffirmativeSemantic
import com.lightningkite.kiteui.models.AffirmativeSemantic.withBack
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.atTopCenter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.readable.AppScope
import com.lightningkite.readable.Property
import com.lightningkite.readable.invoke
import com.picme.PIcon
import com.picme.ToastSemantic
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

fun ViewWriter.toast() = atTopCenter - padded - onlyWhen { currentToast() != null } - col {
    ToastSemantic.onNext - row {
        centered - icon {
            ::exists { currentToast()?.severity == ToastType.Success }
            source = PIcon.select
            themeChoice += AffirmativeSemantic
        }
        centered - icon {
            ::exists { currentToast()?.severity == ToastType.Error }
            themeChoice += DangerSemantic
            source = PIcon.close
        }
        centered - col {
            spacing = 0.rem
            text {
                ::content { currentToast()?.messagePrimary ?: "..." }
            }
            FieldLabelSemantic.onNext - text {
                ::exists { currentToast()?.messageSecondary != null }
                ::content {
                    currentToast()?.messageSecondary ?: ""
                }
            }
        }
        centered - button {
            icon {
                opacity = 0.5
                source = PIcon.close
            }
            onClick {
                currentToast set null
            }
        }
    }
}

enum class ToastType { Success, Error }

private data class CurrentToast(
    val messagePrimary: String,
    val messageSecondary: String? = null,
    val severity: ToastType = ToastType.Success
)

private val currentToast = Property<CurrentToast?>(null)


fun showToast(
    primary: String,
    secondary: String? = null,
    severity: ToastType = ToastType.Success,
    duration: Long = 5000
) {
    AppScope.launch {
        currentToast set CurrentToast(
            messagePrimary = primary,
            messageSecondary = secondary,
            severity = severity
        )
        delay(duration)
        currentToast set null
    }
}
