package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.authPage
import com.picme.components.styledPassword
import com.picme.sdk2.unauthApi


@Routable("reset-password")
object ResetPassword : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        val showErrorText = Property(false)
        return authPage("Forgot Password", true) {

            FadedSemantic.onNext - subtext("Enter the email address associated with your account and we'll send you a new verification code that will allow you to reset your password.")

            onlyWhen(false) { showErrorText() } - danger - text {
                content = "Invalid email. Check your email and try again."
                align = Align.Center
            }

            field("Email or Phone") {
                textInput {
                    hint = "Email or Phone"
                    content bind emailOrPhone
                    keyboardHints = KeyboardHints.email
                }
            }

            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
            important - buttonTheme - button {
                centered - h6("Send Verification Code")
                onClick {
                    showErrorText.value = false
                    try {
                        unauthApi().authenticationHandler.requestPasswordReset(emailOrPhone().lowercase())
                        pageNavigator.navigate(ConfirmResetPassword)
                    } catch (e: Exception) {
                        showErrorText.value = true
                    }
                }
                ::enabled { emailOrPhone().isValidEmailOrPhone() }
            }
        }
    }
}


@Routable("confirm-reset-password")
object ConfirmResetPassword : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        val verificationCode = Property("")
        val newPassword = Property("")
        val errorText = Property("")
        val passwordError = Property<String?>(null)
        return authPage("Forgot Password", true) {
            FadedSemantic.onNext - subtext("Enter the verification code sent to your email and the new password for your account.")

            onlyWhen(false) { errorText().isNotEmpty() } - danger - text {
                ::content{ errorText() }
                align = Align.Center
            }

            field("Verification Code") {
                textInput {
                    hint = "Verification Code"
                    content bind verificationCode
                    keyboardHints = KeyboardHints.id
                }
            }

            val submitAction = Action("Submit") {
                passwordError set null
                errorText set ""
                if (newPassword.await().length < 8) {
                    passwordError set "Password must be at least 8 characters long"
                    return@Action
                }
                try {
                    unauthApi().authenticationHandler.continuePasswordReset(
                        emailOrPhoneNumber = emailOrPhone().lowercase(),
                        confirmationCode = verificationCode(),
                        newPassword = newPassword()
                    )
                    pageNavigator.reset(LogInPage)
                } catch (e: Exception) {
                    errorText set "An error has occurred. Make sure your verification code is correct and try again"
                }
            }

            styledPassword(
                label = "New Password",
                new = true,
                password = newPassword,
                passwordError = passwordError,
                action = submitAction
            )

            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
            important - buttonTheme - button {
                centered - text("Reset Password")
                action = submitAction
            }
        }
    }
}
