package com.picme

import com.lightningkite.kiteui.Throwable_report
import com.lightningkite.kiteui.navigation.PageNavigator
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.root
import com.picme.iap.iapSetup
import com.picme.views.frontendUrl
import kotlin.js.json

fun main() {

    com.picme.sentry.init(
        json(
            "dsn" to "https://8462b661e5bfba3e3775d3bf5b9ff626@sentry24.lightningkite.com/15"
        )
    )

    Throwable_report = { ex, ctx ->
        ex.printStackTrace2()
        if (frontendUrl().contains("localhost")) {
            ex.printStackTrace2()
        } else {
            com.picme.sentry.captureException(ex)
        }
    }
    root(appTheme) {
        //context.iapSetup("strp_PVuRXRnTvpIPypEPJSDPWzDUlDO")
        app(PageNavigator { AutoRoutes }, PageNavigator { AutoRoutes })
    }
}
