package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.exceptions.ExceptionHandler
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.*
import com.lightningkite.readable.*
import com.picme.components.ImageViewPager
import com.picme.components.picmeIconDisplay
import com.picme.components.toast
import com.picme.components.userInfoPopover
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.*
import com.picme.views.comments.CommentsPage
import com.picme.views.likes.ItemLikesPage
import com.picme.views.notifications.NotificationPage
import com.picme.views.share.ShareView
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

interface UnauthPage
interface MaybeAuthenticatedPage // Either Authenticated or unauthenticated users should be able to reach these pages
interface IncludeTopBar
interface NoTopBar
interface CannotBeCovered
interface UnpaddedPage
interface AllowPartialExpansion
interface ShowsLiveUpload {
    val item: Property<UploadId?>
}

interface HasSubtitle {
    val subtitle: Readable<String>
    val description: Readable<String?> get() = Constant(null)
}

interface ControlsStatusBarColor {
    val statusBarColor: Readable<Color> get() = Constant(appMaterialTheme.background.closestColor())
}

interface HasActions {
    val actions: List<PicmeAction>
}

interface HasClickableTitle {
    suspend fun ViewWriter.onTitleClick()
}

var ViewWriter.smallPageNavigator by rContextAddonInit<PageNavigator>()

fun ViewWriter.appLarge(): ViewModifiable {
    return coordinatorFrame {
        overlayFrame = this
        OuterSemantic.onNext - row {
            val collectionNavOpen = Property(true)
            onlyWhen { collectionNavOpen() && pageNavigator.currentPage() is PageForCollection } - frame {
                sizeConstraints(
                    width = 360.dp
                ) - collectionList(closeOnSelect = false) {
                    collectionNavOpen.value = false
                }
            }
            separator { ::exists { collectionNavOpen() } }
            expanding - OuterSemantic.onNext - col {
                val showToolbar = shared {
                    includeTopBar(this@col.pageNavigator.currentPage())()
                }
                sizeConstraints(height = 72.dp) - bar - row {
                    exists = false
                    ::exists { showToolbar() }

                    onlyWhen { pageNavigator.stack().size <= 1 } - centered - sizeConstraints(
                        minWidth = 36.dp,
                        minHeight = 36.dp
                    ) - buttonTheme - button {
                        centered - icon { source = PIcon.menu }
                        onClick {
                            collectionNavOpen.value = !collectionNavOpen.value
                        }
                    }
                    onlyWhen { pageNavigator.stack().size > 1 } - buttonTheme - button {
                        centered - icon(PIcon.chevronleft, "Back")
                        onClick {
                            pageNavigator.goBack()
                        }
                    }

                    val title = menuBarTitle()

                    centered - frame {
                        ::exists{ title().isEmpty() }
                        picmeIconDisplay()
                    }
                    expanding - space { ::exists { title().isEmpty() } }

                    renderTopActions { (mainPageNavigator.currentPage() as? HasActions)?.actions }
                    atEnd - userInfoPopover()
                }
                separator { exists = false; ::exists { showToolbar() } }
                expanding - OuterSemantic.onNext - row {
                    expanding - col {
                        spacing = 0.px
                        subtitleBar()
                        expanding - navigatorView(this.pageNavigator)
                    }
                    separator { ::exists { smallPageNavigator.currentPage() != null } }
                    sidePanelNav(smallPageNavigator)
                }
            }
        }

        navigatorViewDialog()
        toast()
    }
}

fun ViewWriter.appSmall(): ViewModifiable {
    return coordinatorFrame {
        overlayFrame = this
        spacing = 1.dp
        padding = 1.dp
        expanding - OuterSemantic.onNext - col {
            val showToolbar = shared {
                includeTopBar(this@col.pageNavigator.currentPage())()
            }
            sizeConstraints(height = 72.dp) - bar - row {
                ::exists { showToolbar() }

                onlyWhen { pageNavigator.stack().size <= 1 } - centered - sizeConstraints(
                    minWidth = 36.dp,
                    minHeight = 36.dp
                ) - buttonTheme - button {
                    centered - icon { source = PIcon.menu }
                    onClick {
                        leftSlidingPanel(ratio = 1f) { it ->
                            DialogSemantic.onNext - unpadded - collectionList(closeOnSelect = true) { it.close() }
                        }
                    }
                }
                onlyWhen { pageNavigator.stack().size > 1 } - buttonTheme - button {
                    centered - icon(PIcon.chevronleft, "Back")
                    onClick {
                        pageNavigator.goBack()
                    }
                }

                val title = menuBarTitle()

                centered - frame {
                    ::exists{ title().isEmpty() }
                    picmeIconDisplay()
                }
                expanding - space { ::exists { title().isEmpty() } }

                atEnd - userInfoPopover()
            }
            separator { ::exists { showToolbar() } }
            expanding - coordinatorFrame {
                coordinatorFrame = this
                OuterSemantic.onNext - col {
                    ignoreInteraction = true
                    subtitleBar()
                    expanding - navigatorView(this.pageNavigator)
                    spacingOverrideBeforeNext((-42).dp)
                    expanding - onlyWhen { smallPageNavigator.currentPage() != null && pageNavigator.currentPage() is CannotBeCovered } - frame()
                    separator()
                    renderBottomActions { (mainPageNavigator.currentPage() as? HasActions)?.actions }
                }

                bottomSheetNav(smallPageNavigator)
            }
        }
        navigatorViewDialog()

        toast()
    }
}

private fun RowOrCol.subtitleBar() {
    val subtitle = shared { (pageNavigator.currentPage() as? HasSubtitle)?.subtitle?.invoke() }
    val description = shared { (pageNavigator.currentPage() as? HasSubtitle)?.description?.invoke() }
    onlyWhen { subtitle() != null } - ActiveTaskBarSemantic.onNext - col {
        spacing = 0.25.rem
        text {
            align = Align.Center
            ::content { subtitle() ?: "" }
        }
        onlyWhen { description() != null } - subtext {
            align = Align.Center
            ::content { description() ?: "" }
        }
    }
}

private fun RowOrCol.menuBarTitle(): Readable<String> {
    val title = shared { pageNavigator.currentPage()?.title?.invoke() ?: "..." }

    expanding - button {
        align(Align.Stretch, Align.Center) - h4 {
            ::content { title() }
            align = Align.Start
            ellipsis = true
            wraps = false
        }
        onClick {
            (mainPageNavigator.currentPage() as? HasClickableTitle)?.run { onTitleClick() }
        }
    }
    return title
}

fun ViewWriter.app(main: PageNavigator, dialog: PageNavigator): ViewModifiable {
    prepareModelsApps()
    val loadingInitial = shared { !session.state().ready && pageNavigator.currentPage() !is NoCollectionsPage }

    reactive {
        val color =
            ((dialog.currentPage() ?: main.currentPage()) as? ControlsStatusBarColor)?.statusBarColor?.invoke()
                ?: Color.white
        setStatusBarColor(color)
    }

    return frame {
        padding = 0.dp
        mainPageNavigator = main
        dialog.let {
            dialogPageNavigator = it
        }
        main.bindToPlatform(context)
        pageNavigator = main
        smallPageNavigator = PageNavigator { AutoRoutes }

        val delayedMainPageNav = mainPageNavigator.stack.debounce(100L)
        reactive {
            val m = delayedMainPageNav()
            val currMain = m.lastOrNull()
            val prevMain = m.secondToLastOrNull()

            when {
                session() == null -> {
                    smallPageNavigator.clear()
                }

                currMain is PageForCollection -> {
                    val s = smallPageNavigator.stack.value
                    val previousSmall = s.secondToLastOrNull()
                    val currentSmall = (s.lastOrNull() as? PageForCollection) ?: return@reactive

                    val shouldHideSmallNavigator = listOf(
                        (currentSmall is CommentsPage && currMain is CollectionImageView && prevMain is ImageViewPager),
                        (currentSmall is ItemLikesPage && currMain is CollectionImageView)
                    ).any { it }

                    val isDifferentCollection = currentSmall.collectionId != currMain.collectionId

                    val shouldKeepSmallNavigator = listOf(
                        (currentSmall is ShareView && !isDifferentCollection),
                        (previousSmall is NotificationPage),
                        (isDifferentCollection)
                    ).any { it }

                    val regen = currentSmall.forOtherCollection(currMain.urlSafeId)

                    if (regen != null && shouldKeepSmallNavigator && !shouldHideSmallNavigator) {
                        smallPageNavigator.reset(regen)
                    } else {
                        smallPageNavigator.clear()
                    }
                }

                else -> {
                    smallPageNavigator.clear()
                }
            }
        }

        this += object : ExceptionHandler {
            override val priority: Float
                get() = 10f

            override fun handle(view: RView, working: Boolean, exception: Exception): (() -> Unit) {
                if (exception is ConnectionException && exception.cause is CancellationException) return {}
                else {
                    exception.report()
                    throw exception
                }
            }
        }



        onRemove(
            logoutTrigger.addListener {
                AppScope.launch {
                    dialogPageNavigator.dismiss()
                    pageNavigator.reset(LoginOrSignUp())
                }
            }
        )

        reactiveSuspending {
            val currPage = pageNavigator.currentPage()
            if (loadingInitial()) return@reactiveSuspending
            if (session() == null && (currPage !is UnauthPage && currPage !is MaybeAuthenticatedPage) && Platform.current == Platform.Web) {
                pageNavigator.reset(LoginOrSignUp())
                return@reactiveSuspending
            }
            val shouldNotSeeLoginPages = currPage is UnauthPage && session()?.isVerifiedAccount() ?: false

            if (shouldNotSeeLoginPages && currPage !is MaybeAuthenticatedPage) {
                navigateToCollOrLanding()
            }
        }


        fullPageLoading(loadingInitial)

        var last: ViewModifiable? = null
        reactive {
            last?.let { removeChild(it.rView) }
            last = when {
                loadingInitial() -> null
                isSmallPage() -> appSmall()
                else -> appLarge()
            }
        }
    }
}


fun includeTopBar(curr: Page?): Readable<Boolean> {
    return shared {
        if (curr is NoTopBar) return@shared false
        if (curr is IncludeTopBar) return@shared true

        if (curr is NoCollectionsPage) return@shared (session()?.isVerifiedAccount() == true)

        return@shared (curr !is UnauthPage)
    }
}

fun ViewWriter.selectItemsBar(select: SelectItems) {
    onlyWhen { select.isSelecting() } - ActiveTaskBarSemantic.onNext - row {
        reactive {
            val stack = mainPageNavigator.stack()
            val currentCollection = (stack.lastOrNull() as? PageForCollection)?.urlSafeId ?: return@reactive
            val prevCollection = (stack.secondToLastOrNull() as? PageForCollection)?.urlSafeId ?: return@reactive

            if (currentCollection != prevCollection) {
                select.stopSelecting()
            }
        }
        centered - bold - text("Select one or more items")
        expanding - space()
        centered - buttonTheme - unpadded - button {
            icon {
                source = PIcon.close
            }
            onClick { select.stopSelecting() }
        }
    }
}

private fun ViewWriter.dialogWrap(
    page: Page,
    showHandle: Boolean,
    nav: PageNavigator,
    moreSetup: RowOrCol.() -> Unit = {}
) = col {
    val unpaddedContent = page is UnpaddedPage
    padding = if (unpaddedContent) 0.rem else 0.75.rem
    spacing = 0.25.rem
    col {
        padding = if (unpaddedContent) 0.75.rem else 0.rem
        spacing = 0.25.rem
        frame {
            paddingByEdge = Edges(horizontal = 0.75.rem, vertical = 0.rem)
            atCenterStart - row {
                centered - buttonTheme - button {
                    icon(PIcon.back, "Back")
                    ::exists { nav.canGoBack() }
                    onClick {
                        nav.goBack()
                    }
                }
                centered - SubwindowTitleSemantic.onNext - text {
                    ::content { (page as? HasSubtitle)?.subtitle?.invoke() ?: page.title() }
                }
            }
            if (showHandle && page is AllowPartialExpansion) centered - coordinatorDragHandle()
            atCenterEnd - frame {
                centered - buttonTheme - button {
                    icon(PIcon.close, "Close")
                    onClick {
                        nav.clear()
                    }
                }
            }
        }
        separator()
    }
    expanding - padded - with(page) { render() }
    moreSetup()
}

private fun CoordinatorFrame.bottomSheetNav(nav: PageNavigator) {
    var old: BottomSheetControl? = null
    reactive {
        old?.close()
        old = null
        nav.currentPage()?.let { page ->
            bottomSheet(
                partialRatio = if (page is AllowPartialExpansion) 0.5f else 0.9999f,
                startState = if (page is AllowPartialExpansion) BottomSheetState.PARTIALLY_EXPANDED else BottomSheetState.EXPANDED
            ) {
                with(this@bottomSheet.split()) {
                    this@with.pageNavigator = nav
                    old = it
                    DialogSemantic.onNext - this@with.dialogWrap(page, true, nav) {
                        onRemove {
                            if (nav.currentPage.state.getOrNull() === page) {
                                this@bottomSheetNav.launch {
                                    delay(16)
                                    nav.clear()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun RowOrCol.sidePanelNav(nav: PageNavigator): ViewModifiable {
    val isShowingStuff = Property(false)
    reactiveSuspending {
        val v = nav.currentPage() != null
        if (v) delay(16)
        isShowingStuff set v
    }
    return onlyWhen { isShowingStuff() } - NavSemantic.onNext - frame {
        padding = 0.dp
        sizeConstraints(width = 360.dp) - swapView {
            val n = nav
            debugName = "sidePane"
            ignoreInteraction = true
            var lastStack = n.stack.value
            this@swapView.swapping(
                transition = {
                    val newStack = n.stack.value
                    val transitionSet = ScreenTransitions.HorizontalSlide
                    when {
                        newStack.size - lastStack.size > 0 -> transitionSet.forward
                        newStack.size - lastStack.size < 0 && newStack.firstOrNull() == lastStack.firstOrNull() -> transitionSet.reverse
                        else -> transitionSet.neutral
                    }.also { lastStack = newStack }
                },
                current = { n.currentPage<Page?>() },
                views = { screen ->
                    with(split()) {
                        this.pageNavigator = n
                        if (screen != null)
                            dialogWrap(screen, false, nav)
                        else null
                    }
                }
            )
        }
    }
}
