package com.picme.sso

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.direct.unpadded
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.warning
import com.lightningkite.readable.Constant
import com.picme.UnauthPage
import com.picme.components.authPage


@Routable("/sso-google/error/{code}/{message}")
class ErrorPageGoogle(val code: String, val message: String) : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        return expanding - unpadded - frame {
            authPage("An error has occurred") {
                warning - col {
                    text(message)
                }
            }
        }
    }
}

@Routable("/sso-apple/error/{code}/{message}")
class ErrorPageApple(val code: String, val message: String) : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        return expanding - unpadded - frame {
            authPage("An error has occurred") {
                warning - col {
                    text(message)
                }
            }
        }
    }
}