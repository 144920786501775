package com.picme.views.likes

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.FieldLabelSemantic
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.now
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.UserInfo
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.sdk2.generated.poll.PollSelection
import com.picme.views.PageForCollection
import com.picme.views.comments.ItemCommentsPage
import com.picme.views.comments.ScopedEntity
import kotlinx.coroutines.launch


@Routable("collections/{urlSafeId}/items/likes")
class ItemLikesPage(override val urlSafeId: String): PageForCollection(), AllowPartialExpansion, HasSubtitle, ShowsLiveUpload {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = ItemLikesPage(urlSafeId)
    override val subtitle: Readable<String>
        get() = Constant("Likes")

    constructor(urlSafeId: String, item: UploadId): this(urlSafeId) {
        this.item.value = item
    }

    @QueryParameter
    override val item: Property<UploadId?> = Property(null)
    val details: Readable<GetUploadResponse2> = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = collection().collection.collectionId,
            uploadId = item() ?: return@shared null
        )()
    }.waitForNotNull
    val control = details.lens {
        LikeControl(
            ScopedEntity(
                DataScope(it.upload.uploadGlobalId.scope),
                it.upload.uploadId.raw,
                RecordTypeId(it.upload.uploadGlobalId.typeId)
            )
        ).also { AppScope.launch { it.fetch() } }
    }

    override fun ViewWriter.render(): ViewModifiable {
        return col {
            spacingAndPadding = 0.dp

            expanding - recyclerView {
                children(
                    items = shared {
                        control().selections()?.sortedByDescending { it.choiceTime.epochSeconds } ?: listOf()
                    },
                    id = { it.userInfo.userId },
                    render = { user ->
                        row {
                            userAvatar(user.lens { it.userInfo })
                            col {
                                spacing = 0.dp
                                text {
                                    ::content { user().userInfo.name }
                                }
                                FieldLabelSemantic.onNext - subtext {
                                    ::content { user().choiceTime.renderRelativeToString() }
                                }
                            }
                            expanding - space()
                            centered - buttonTheme - unpadded - menuButton {
                                ::exists { user().userInfo.userId == sessionNotNull().authenticatedUser().userId }
                                requireClick = true
                                centered - icon(PIcon.dotsvertical, "More Vert")
                                opensMenu {
                                    MenuOptionContainerSemantic.onNext - col {
                                        MenuOptionSemantic.onNext - button {
                                            row {
                                                icon(PIcon.trash, "")
                                                text("Delete")
                                            }
                                            onClick {
                                                closePopovers()
                                                control().removeLike()
                                            }
                                        }
                                    }
                                }
                            }
                            space()
                        }
                    }
                )
            }
        }
    }
}
class LikeControl(private val scope: ScopedEntity) {
    private val _selections: Property<List<PollSelection>?> = Property(null)
    val selections: Readable<List<PollSelection>?> get() = _selections

    suspend fun fetch() {
        sessionNotNull().poll.listPollSelections(
            scope = scope.scope,
            pollOrEntityId = scope.discussionOrEntityId,
            pollOrEntityTypeId = scope.discussionOrEntityTypeId,
            continuation = null
        ).selections.filter { it.choiceId == ChoiceId.Like.toString() }.also {
            _selections set it
        }
    }

    suspend fun likeItem() {
        sessionNotNull().poll.putMyPollSelection(
            scope = scope.scope,
            pollOrEntityId = scope.discussionOrEntityId,
            pollOrEntityTypeId = scope.discussionOrEntityTypeId,
            selectedChoiceId = ChoiceId.Like.toString()
        )
        _selections set (_selections() ?: listOf()) + PollSelection(
            choiceId = ChoiceId.Like.toString(),
            userInfo = UserInfo(
                userId = sessionNotNull().authenticatedUser().userId,
                name = "Me",
                getProfilePicture = sessionNotNull().authentication.getMyUserInfo().profilePicture
            ),
            choiceTime = now(),
        )
    }

    suspend fun removeLike() {
        sessionNotNull().poll.putMyPollSelection(
            scope = scope.scope,
            pollOrEntityId = scope.discussionOrEntityId,
            pollOrEntityTypeId = scope.discussionOrEntityTypeId,
            selectedChoiceId = ""
        )
        _selections set selections()?.filterNot {
            it.userInfo.userId.raw == sessionNotNull().authenticatedUser().userId.raw
        }
    }
}


/**
 * In order to avoid confusion and keep things small when we add more reactions, we think this
 * should be decoupled from any literal meaning and should probably just use the numeric ID of an enum
 * on the client side, perhaps with a short prefix indicating it's a reaction,
 * perhaps "R", so R0 for like, R1, R2, R3, R4, etc. for future reaction types.  This will allow for more
 * sensible remapping in the future if needed. *
 * - James
 */
enum class ChoiceId {
    Like {
        override fun toString(): String = "R0"
    }
}


