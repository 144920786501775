package com.picme.components

import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.Property
import com.lightningkite.readable.Writable
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.Readable
import com.lightningkite.readable.bind
import com.lightningkite.readable.reactive
import com.picme.*
import com.picme.DangerForegroundSemantic
import com.picme.views.ResetPasswordDialog.passwordError
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


class StyledPassword(
    val field: TextField,
    val text: TextView,
    val usePlatformSavedPasswords: Writable<Boolean>,
    val error: Writable<String?> = Property(null),
) {
    val content by field::content
    var hint by field::hint
    var label by text::content
}

inline fun ViewWriter.styledPassword(
    label: String,
    new: Boolean,
    password: Writable<String>,
    passwordError: Readable<String?>,
    action: Action? = null,
    requestFocus: Boolean = false,
) {
    field(label) {
        val showPassword = Property(false)
        row {
            centered - expanding - textInput {
                hint = label
                content bind password
                keyboardHints = KeyboardHints.password
                if(requestFocus) this.requestFocus()
                ::keyboardHints {
                    if (showPassword()) KeyboardHints.paragraph
                    else if(new) KeyboardHints.newPassword else KeyboardHints.password
                }
                reactive {
                    passwordError()?.let { throw PlainTextException(it, "Error") }
                }
                action?.let {
                    this.action = it
                }
            }
            centered - buttonTheme - unpadded - button {
                icon {
                    ::source {
                        val icon = if (showPassword()) PIcon.eye else PIcon.eyeoff
                        icon.copy(width = 1.rem, height = 1.rem)
                    }
                }
                onClick { showPassword.value = !showPassword() }
            }
        }
    }
}

@ViewDsl
inline fun ViewWriter.styledPasswordActual(noinline setup: StyledPassword.() -> Unit) {
    val showPassword = Property(false)
    val tf: TextField
    val label: TextView

    val usePlatformSavedPasswords = Property(true)
    val error = Property<String?>(null)

    col {
        spacing = 0.px
        h4 {
            dynamicTheme { if (error() != null) DangerForegroundSemantic else null }
            label = this
        }
        // TODO: Warning
        fieldTheme - row {
            dynamicTheme { if (error() != null) DangerForegroundSemantic else null }
            expanding - centered - textInput {
                tf = this
                ::keyboardHints {
                    if (showPassword()) KeyboardHints.paragraph
                    else if (usePlatformSavedPasswords()) KeyboardHints.password else KeyboardHints.newPassword
                }
            }
            centered - themeFromLast {
                it.copy(
                    outline = Color.white,
                    cornerRadii = CornerRadii.RatioOfSize(2f)
                )
            } - button {
                icon {
                    ::source {
                        val icon = if (showPassword()) PIcon.eye else PIcon.eyeoff
                        icon.copy(width = 1.5.rem, height = 1.5.rem)
                    }
                }
                onClick { showPassword.value = !showPassword() }
            }
        }

        danger - subtext {
            ::visible { error() != null }
            ::content { error() ?: "" }
        }

        setup(StyledPassword(tf, label, usePlatformSavedPasswords, error))
    }
}

@OptIn(ExperimentalContracts::class)
@ViewDsl
inline fun ViewWriter.styledPassword(noinline setup: StyledPassword.() -> Unit = {}) {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }; styledPasswordActual(setup)
}
