package com.picme.views

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.frame
import com.picme.UnauthPage

import com.picme.sdk2.toSafeEncoded
import com.picme.session
import com.picme.sessionRefreshToken
import kotlinx.coroutines.launch


@Routable("/")
class LandingPage : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable {
        suspend fun handleWebFlow(isLoggedIn: Boolean) {
            if (isLoggedIn) {
                val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                if (firstCollection != null) {
                    pageNavigator.reset(CollectionImageView(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                } else {
                    pageNavigator.reset(NoCollectionsPage)
                }
            } else {
                pageNavigator.reset(LoginOrSignUp())
            }
        }

        suspend fun handleMobileFlow(isLoggedIn: Boolean) {
            if (!WelcomeTOSLanding.acknowledged()) {
                pageNavigator.replace(WelcomeTOSLanding)
            } else if (isLoggedIn) {
                // TODO: Last collection viewed
                val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                if (firstCollection != null) {
                    pageNavigator.reset(CollectionImageView(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                } else {
                    pageNavigator.reset(NoCollectionsPage)
                }
            } else {
                pageNavigator.reset(NoCollectionsPage)
            }
        }

        launch {
            val loggedIn = !sessionRefreshToken().isNullOrEmpty()
            when (Platform.current) {
                Platform.Web -> handleWebFlow(loggedIn)
                Platform.iOS, Platform.Android -> handleMobileFlow(loggedIn)
                Platform.Desktop -> throw RuntimeException("Desktop not yet supported")
            }
        }
        //Could provide a splash page here or loading page, but need to work out the details with
        //Jeff if he wants it.
        return frame {}
    }
}
