package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.picme.*
import com.picme.actuals.interstitialAd
import com.picme.views.*
import com.picme.views.notifications.NotificationPage
import kotlinx.coroutines.delay

fun ViewWriter.userInfoPopover() =
    centered - sizeConstraints(width = 48.dp, height = 48.dp) - important - buttonTheme - unpadded - menuButton {
        requireClick = true

        val profileImg = shared { session()?.authentication?.profileImage() }

        val nameOfUser = shared {
            sessionNotNull().authenticatedUser().name.ifBlank { createUserName() }
                .replaceFirstChar { it.titlecase() }
        }
        val firstLetterOfName = shared { nameOfUser().take(1) }

        centered - h3 {
            ::exists {
                firstLetterOfName().isNotEmpty() && profileImg() == null
            }
            ::content { firstLetterOfName() }
        }
        centered - icon {
            ::exists { firstLetterOfName().isEmpty() && profileImg() == null }
            source = PIcon.account
        }
        RoundedImageSemantic.onNext - image {
            ::exists { session()?.authentication?.profileImage() != null }
            scaleType = ImageScaleType.Crop
            refreshOnParamChange = true
            ::source { session()?.authentication?.profileImage() }
        }

        atTopEnd - NotificationBadgeSemantic.onNext - frame {
            icon {
                ::source { Icon.filledNotification() }
            }
        }


        preferredDirection = PopoverPreferredDirection.belowLeft
        opensMenu {
            sizeConstraints(width = 20.rem) - col {
                frame {
                    spacing = 0.2.rem
                    centered - unpadded - frame {
                        ::exists { profileImg() == null }
                        themeChoice = ImportantSemantic + RoundedImageSemantic
                        centered - sizeConstraints(width = 4.rem, height = 4.rem) - frame {
                            centered - icon {
                                ::exists { firstLetterOfName().isEmpty() }
                                source = PIcon.account
                            }
                        }
                        centered - bold - textSize(2.5.rem) - text {
                            ::exists { firstLetterOfName().isNotEmpty() }
                            ::content { firstLetterOfName() }
                        }
                    }

                    centered - sizeConstraints(
                        width = 4.rem,
                        height = 4.rem
                    ) - RoundedImageSemantic.onNext - image {
                        exists = false
                        ::exists { profileImg() != null }
                        scaleType = ImageScaleType.Crop
                        ::source { profileImg() }
                        refreshOnParamChange = true
                    }

                }


                centered - button {
                    h4 {
                        ::exists { nameOfUser().isNotBlank() }
                        ::content { nameOfUser() }
                        align = Align.Center
                    }
                    onClick {
                        val initialName = session.state.raw?.authenticatedUser?.state?.raw?.name ?: return@onClick
                        closePopovers()
                        dialogGeneric { close ->
                            val editName = Property(initialName)
                            field("Name") {
                                textInput {
                                    hint = "Name"
                                    content bind editName
                                    keyboardHints = KeyboardHints.title
                                }
                            }
                            actionOrCancelSection(close, actionButton = {
                                important - buttonTheme - button {
                                    centered - text("Save")
                                    onClick {
                                        if (editName() != session()?.authenticatedUser()?.name) {
                                            session.awaitNotNull().authentication.setUserAttributes(
                                                setTosRead = true,
                                                name = editName(),
                                            )
                                        }
                                        close()
                                    }
                                }
                            })
                        }
                    }
                }

                separator()

                MenuOptionContainerSemantic.onNext - col {
                    MenuOptionSemantic.onNext - button {
                        onClick {
                            closePopovers()
                            ensureVerifiedAccount { smallPageNavigator.reset(NotificationPage) }
                        }
                        row {
                            centered - icon {
                                source = PIcon.notifications
                            }
                            centered - text { content = "Notifications" }
                            centered - icon {
                                themeChoice += ImportantForegroundSemantic
                                ::source { Icon.filledNotification() }
                            }
                        }

                    }
                    MenuOptionSemantic.onNext - button {
                        row {
                            centered - icon(PIcon.account, "")
                            centered - text("Account")
                        }
                        onClick {
                            closePopovers()
                            ensureVerifiedAccount { smallPageNavigator.reset(ProfilePage) }
                        }
                    }
                    MenuOptionSemantic.onNext - button {
                        ::exists { session()?.isVerifiedAccount() == true }
                        row {
                            centered - icon(PIcon.security, "")
                            centered - text("Password & Security")
                        }
                        onClick {
                            closePopovers()
                            smallPageNavigator.reset(PasswordAndSecurity)
                        }
                    }
//                            MenuOptionSemantic.onNext - link {
//                                row {
//                                    centered - icon(PIcon.subscription, "")
//                                    centered - text("Subscription")
//                                }
//                                resetsStack = true
//                                onNavigator = smallPageNavigator
//                                onNavigate { closePopovers() }
//                                to = { Plan }
//                            }
                    MenuOptionSemantic.onNext - button {
                        ::exists { session()?.isVerifiedAccount() == true }
                        row {
                            centered - icon(PIcon.qrcode, "")
                            centered - text("Refer a Friend")
                        }
                        onClick {
                            closePopovers()
                            smallPageNavigator.reset(ReferAFriend)
                        }

                    }
                    MenuOptionSemantic.onNext - button {
                        ::exists { session()?.isVerifiedAccount() == true }
                        row {
                            centered - icon(PIcon.logout, "")
                            centered - text("Logout")
                        }
                        onClick {
                            closePopovers()
                            logout()
                        }
                    }
                    spacingOverrideBeforeNext(1.rem)
                    centered - loginOrSignupButton { closePopovers() }
                    warning - padded - card - col {
                        ::exists { devSettings() }
                        bold - text { ::content { "Backend: ${selectedBackend()}" } }
                        subtext("This will only appear when Developer settings are enabled")
                    }

                    MenuOptionSemantic.onNext - button {
                        ::exists { devSettings() }
                        row {
                            centered - icon(Icon.imageStack, "")
                            centered - text("Test Ad (Dev mode only)")
                        }
                        onClick {
                            closePopovers()
                            interstitialAd()?.show(this@button)
                        }
                    }
                    MenuOptionSemantic.onNext - button {
                        ::exists { devSettings() }
                        row {
                            centered - icon(Icon.imageStack, "")
                            centered - text("Test Upload Screen (Dev mode only)")
                        }
                        onClick {
                            closePopovers()
                            openProgressModal(
                                title = "Test",
                                onComplete = {},
                                execute = {
                                    itemCount = 20
                                    individualItemProgress = 0.1f
                                    delay(50000)
                                }
                            )
                        }
                    }
                }
            }
        }
    }


