package com.picme.views

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.usesTouchscreen
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.readable.*
import com.picme.*
import com.picme.actuals.dropFile
import com.picme.actuals.preventDrop
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.caching.CollectionHandler2ApiCacheableLive2
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.PatchCollectionBody
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.comments.CollectionCommentsPage
import com.picme.views.share.ShareView
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

@Routable("/collection/{urlSafeId}")
class CollectionImageView(override val urlSafeId: String) : PageForCollection(), HasActions, HasClickableTitle,
    CannotBeCovered {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = CollectionImageView(urlSafeId)
    override val title = shared { collection().collection.name }

    override suspend fun ViewWriter.onTitleClick() {
        if (ownsPCollection(collection().collection)()) {
            editTitleDialog(collection().collection)
        }
    }

    override fun ViewWriter.render(): ViewModifiable {
        return expanding - col {
            padding = 0.dp
            preventDrop()
            launch {
                while (true) {
                    delay(8000)
                    val api = session()?.collection2
                    if (api is CollectionHandler2ApiCacheableLive2) {
                        api.checkRefreshCollImages(collectionId)
                    }
                }
            }

            val paged = sharedSuspending {
                CollectionState.filterGuests()
                CollectionState.filterDate()
                collection().collection.images(CollectionState.filters())
            }

            ActiveTaskBarSemantic.onNext - row {
                ::exists { CollectionState.filtersOn() && !CollectionState.selectItems.isSelecting() }
                centered - bold - text {
                    ::content {
                        val guestStr = CollectionState.filterGuests().ifEmpty { null }
                            ?.let { " for ${it.size} ${"guest".pluralize(it.size)}" } ?: ""

                        val dateStr = CollectionState.filterDate()?.let {
                            " from ${
                                when (it.type) {
                                    DateOptions.Today -> "today"
                                    DateOptions.Yesterday -> "yesterday"
                                    DateOptions.ThisWeek -> "this week"
                                    DateOptions.Last7Days -> "the last 7 Days"
                                    DateOptions.ThisMonth -> "this month"
                                    DateOptions.Last30Days -> "the last 30 days"
                                    DateOptions.Custom -> "a custom date range"
                                }
                            }"
                        } ?: ""
                        "Photos$guestStr$dateStr"
                    }
                }
                expanding - space()
                centered - buttonTheme - unpadded - button {
                    bold - text("Clear")
                    onClick { CollectionState.clearFilters() }
                }
            }
            selectItemsBar(CollectionState.selectItems)

            val empty = shared { (!paged().pullingMore() && paged().all().isEmpty()) }
            expanding - frame {
                expanding - col {
                    centered - col {
                        ::exists { empty() && !CollectionState.filtersOn() }
                        space(6.0)
                        subtext("Click \"+\" to upload your first photo")
                    }

                    centered - col {
                        ::exists { empty() && CollectionState.filtersOn() }
                        space(6.0)
                        subtext("No matching photos")
                        SemiImportantSemantic.onNext - buttonTheme - button {
                            centered - text("Clear Filters")
                            onClick { CollectionState.clearFilters() }
                        }
                    }
                    centered - col {
                        ::exists { !paged().all.state().ready }
                        space()
                        activityIndicator { }
                    }

                    imageRecyclerView(
                        colImages = sharedSuspending {
                            paged().all().toRecyclableInfo()
                        },
                        setup = {
                            reactiveSuspending {
                                collection().collection.images().requireIndexLoaded = lastVisibleIndex() + 50
                            }
                        },
                        navToImage = { img ->
                            ImageViewPager(urlSafeId).apply {
                                imageId.value = img.id
                            }
                        },
                        selectedImages = CollectionState.selectItems
                    )

                }
                atBottomEnd - padded - onlyWhen { isSmallPage() } - frame {
                    ignoreInteraction = true
                    padding = 20.dp
                    sizeConstraints(
                        width = 64.dp,
                        height = 64.dp
                    ) - important - buttonTheme - if (Platform.Companion.current == Platform.Android || Platform.Companion.current == Platform.iOS) menuButton {
                        centered - icon {
                            source = PIcon.brandedAdd.copy(
                                width = 2.5.rem,
                                height = 2.5.rem,
                            )
                        }
                        opensMenu {
                            val collectionId1 = collection.lens { it.collection.collectionId }
                            sizeConstraints(width = 250.dp) - MenuOptionContainerSemantic.onNext - col {
                                MenuOptionSemantic.onNext - button {
                                    onClick {
                                        uploadExternalFiles(collectionId1())
                                        closePopovers()
                                    }
                                    row {
                                        centered - icon {
                                            source = PIcon.image; description =
                                            "Choose image from photo library"
                                        }
                                        centered - h5 { content = "Photo Library" }
                                    }
                                }
                                MenuOptionSemantic.onNext - button {
                                    onClick {
                                        uploadPicFromCamera(collectionId1())
                                        closePopovers()
                                    }
                                    row {
                                        centered - icon {
                                            source = PIcon.camera; description =
                                            "Take picture or video with camera"
                                        }
                                        centered - h5 { content = "Take Photo" }
                                    }
                                }
                            }
                        }
                    } else button {
                        centered - icon {
                            source = PIcon.brandedAdd.copy(width = 2.5.rem, height = 2.5.rem)
                        }
                        onClick {
                            uploadExternalFiles(
                                collection.state.getOrNull()?.collection?.collectionId ?: return@onClick
                            )
                        }
                    }
                }
                atBottom - onlyWhen {
                    Platform.Companion.current == Platform.Web && !isSmallPage()
                } - sizeConstraints(
                    minHeight = 140.dp
                ) - padded - dropFile(collectionId)
            }
        }
    }

    val weAreOwner = shared { ownsPCollection(collection().collection)() }
    val notVerified = shared { session()?.isVerifiedAccount() == false }
    override val actions: List<PicmeAction> = listOf(
        PicmeAction(
            title = "Close",
            icon = PIcon.close,
            shown = { CollectionState.selectItems.isSelecting() },
            count = { CollectionState.selectItems.selected().size },
            action = { CollectionState.selectItems.stopSelecting() }
        ),

        PicmeAction(
            title = "Select Actions",
            icon = { PIcon.more },
            shown = { CollectionState.selectItems.isSelecting() },
            group = listOf(
                PicmeAction(
                    title = "Copy",
                    icon = PIcon.copy,
                    enabled = { CollectionState.selectItems.selected().isNotEmpty() },
                    action = {
                        openCopyMenu(
                            collection.lens { it.collection },
                            CollectionState.selectItems.selected().map { UploadId(it.id) }
                        )
                    }
                ),

                PicmeAction(
                    title = "Download",
                    icon = PIcon.download,
                    enabled = { CollectionState.selectItems.selected().isNotEmpty() },
                    action = {
                        val message = when (Platform.Companion.current) {
                            Platform.Web -> "Selected items will be downloaded to your device as a zip file."
                            Platform.iOS -> "Selected items will be saved to your Camera Roll."
                            else -> "Selected items will be saved to your device."
                        }
                        showConfirmDialog("Confirm Download", message, "Download") {
                            collectionDownload(
                                collectionId,
                                CollectionState.selectItems.selected().map { UploadId(it.id) })
                            CollectionState.selectItems.stopSelecting()
                        }
                    }
                ),

                PicmeAction(
                    title = "Move to Trash",
                    icon = PIcon.trash,
                    shown = { (weAreOwner() || notVerified()) },
                    enabled = { CollectionState.selectItems.selected().isNotEmpty() },
                    action = {
                        showConfirmDialog(
                            title = "Confirm Delete",
                            content = "Selected items will be deleted from the collection.",
                            confirmLabel = "Delete",
                            onConfirm = {
                                val selected = CollectionState.selectItems.selected().map { it.id }
                                val images =
                                    collection().collection.images().all().filter { it.uploadId.raw in selected }
                                CollectionState.selectItems.stopSelecting()
                                deleteImages(collectionId, images)
                            }
                        )
                    }
                ),
            )
        ),

        PicmeAction(
            title = "Select",
            icon = PIcon.select,
            shown = { !CollectionState.selectItems.isSelecting() },
            action = { CollectionState.selectItems.startSelecting() }
        ),
        PicmeAction(
            title = "Comments",
            icon = PIcon.caption,
            shown = { !CollectionState.selectItems.isSelecting() },
            action = {
                ensureVerifiedAccount {
                    smallPageNavigator.reset(CollectionCommentsPage(urlSafeId))
                }
            }
        ),
        PicmeAction(
            title = "Filter",
            icon = {
                if (CollectionState.filtersOn()) {
                    PIcon.filterfunnelon
                } else {
                    PIcon.filterfunnel
                }
            },
            shown = { !CollectionState.selectItems.isSelecting() },
            action = {
                ensureVerifiedAccount {
                    smallPageNavigator.reset(Filters(urlSafeId))
                }
            }
        ),
        PicmeAction(
            title = "Share",
            icon = PIcon.share,
            shown = { !CollectionState.selectItems.isSelecting() && weAreOwner() },
            action = {
                ensureVerifiedAccount {
                    smallPageNavigator.reset(ShareView(urlSafeId))
                }
            }
        ),
        PicmeAction(
            title = "More",
            icon = PIcon.more,
            shown = { !CollectionState.selectItems.isSelecting() },
            menu = {
                this@PicmeAction.collectionActionsMenu(collectionId)
            },
        ),
    )
}


private fun ViewWriter.editTitleDialog(collection: PCollection) {
    val collName = Property("")
    launch {
        collName set collection.name
    }
    dialogGeneric { close ->
        col {
            h4 {
                content = "Collection Name"
                align = Align.Center
            }
            val action = Action(
                "Save",
                action = {
                    session.awaitNotNull().collection2.patchCollection(
                        collectionId = collection.collectionId,
                        body = PatchCollectionBody(
                            name = Retainable(collName()),
                        )
                    )
                    close()
                }
            )
            fieldTheme - textInput {
                content bind collName
                hint = "Name"
                requestFocus()
                this.action = action
            }

            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)
            actionOrCancelSection(
                onCancel = close,
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Save")
                        ::enabled { collName().isNotEmpty() }
                        this.action = action
                    }
                },
            )
        }
    }
}
