package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.IncludeTopBar
import com.picme.UnauthPage

@Routable("collection-unverified")
object MobileUnverifiedUserLanding : Page, UnauthPage, IncludeTopBar {
    override val title = Constant("")
    override fun ViewWriter.render(): ViewModifiable { 
        return frame {
            reactiveScope {
                pageNavigator.reset(WelcomeTOSLanding)
            }
        }
//        frame {
//            val hideOnStartFlag = Property(false)
//            reactiveScope {
//                val collections = session()?.collection2?.listCollectionsLive()?.all()
//                if (collections?.isNotEmpty() == true) {
//                    navigator.navigate(CollectionDetail(SafeIds.encode(collections.first().collection.collectionId.raw)))
//                } else {
//                    hideOnStartFlag.set(true)
//                }
//            }
//
//            atTopCenter - col {
//                ::exists { hideOnStartFlag() }
//
//                image {
//                    source = Resources.photographer
//                }
//                centered - fadedForeground - text("To get started, create a collection")
//                space()
//                buttonTheme - important - button {
//                    centered - row {
//                        icon { source = PIcon.add }
//                        centered - h4("Create Collection")
//                        onClick { navigator.dialog.navigate(CreateCollection) }
//                    }
//                }
//                space()
//                space()
//                loginOrSignupButton()
//            } in sizeConstraints(width = 22.rem)
//        }
    }
}