package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayFrame
import com.picme.*
import com.picme.actuals.AdCache
import com.picme.actuals.advertisement
import com.picme.actuals.loadInterstitialAd
import com.picme.sdk2.generated.AdSize
import kotlinx.coroutines.Job
import kotlinx.coroutines.async
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.seconds


interface ProgressHandle {
    var individualItemProgress: Float
    var itemCount: Int
    var currentIndex: Int
    var image: ImageSource
}

fun ViewWriter.openProgressModal(
    title: String,
    execute: suspend ProgressHandle.() -> Unit,
    onComplete: suspend () -> Unit
) {
    val overlayFrame = overlayFrame ?: return

    val currentItemProgress = Property(0f)
    val currentItemIndex = Property(0)
    val itemCount = Property(1)
    val currentImage = Property<ImageSource>(Resources.picmelogo)

    val isAdLoaded = Property<Boolean?>(false)

    var cancellable: Job? = null

    val page = with(overlayFrame) {
        dismissBackground {
            onClick { }
            padded - frame {
                centered - sizeConstraints(width = 24.rem) - FatCardSemantic.onNext - col {
                    centered - h4("Progress")
                    row {
                        atTop - sizeConstraints(
                            width = 6.rem,
                            height = 6.rem
                        ) - image {
                            scaleType = ImageScaleType.Crop
                            ::source { currentImage() }
                        }
                        expanding - col {
                            val secondsSinceStart = Property(0)
                            launch {
                                while (true) {
                                    delay(1000)
                                    secondsSinceStart.set(secondsSinceStart() + 1)
                                }
                            }
                            text {
                                ::content {
                                    title + ".".repeat(secondsSinceStart() % 4)
                                }
                            }
                            sizeConstraints(minHeight = 14.dp) - progressBar {
                                themeChoice += ThemeDerivation {
                                    it.copy(
                                        id = "progress",
                                        foreground = it[ImportantSemantic].theme.background,
                                        background = Color.fromHexString("#d9d9d9"),
                                        cornerRadii = CornerRadii.ForceConstant(0.5.rem)
                                    ).withBack
                                }
                                ::ratio {
                                    currentItemIndex().plus(currentItemProgress())
                                        .div(itemCount()).times(0.95f).plus(0.05f)
                                }
                            }
                            subtext {
                                ::content {
                                    if (itemCount() == 1) currentItemProgress().times(100).toInt().toString()
                                        .plus("%")
                                    else "${currentItemIndex().plus(1)} of ${itemCount()}"
                                }
                            }
                            atEnd - sizeConstraints(width = 8.rem) - SemiImportantSemantic.onNext - buttonTheme - button {
                                centered - text("Cancel")
                                onClick {
                                    cancellable?.cancel()
                                }
                            }
                        }
                    }

                    if (advertisementBelowUpload.value) {
                        separator { }
                        centered - bold - text("Advertisement")
                        col {
                            spacingAndPadding = 0.dp
                            // This prevents web from showing a broken link before the ad is loaded in. On iOS, the ad view
                            // must be visible in order for ad mob to load the ad
                            val showWhitespaceInsteadOfAd = shared {
                                isAdLoaded() == false && Platform.current == Platform.Web
                            }
                            sizeConstraints(width = 300.dp, height = 250.dp) - advertisement {
                                ::exists { !showWhitespaceInsteadOfAd() }
                                adUnitId = when (Platform.current) {
//                                Platform.Android -> "ca-app-pub-6829519054466679/5376859530"
                                    Platform.Android -> "ca-app-pub-3940256099942544/6300978111" // test adUnitId
                                    Platform.iOS -> "ca-app-pub-6829519054466679/9487311157"
                                    else -> "ca-app-pub-3940256099942544/2435281174" // Test adUnitId
                                }
                                isAdLoaded bind shared { adLoaded() }.withWrite { }
                                launch {
                                    if (Platform.current == Platform.Web) {
                                        while (true) {
                                            isAdLoaded.set(false)
                                            adImgSrc = null
                                            adImgSrc = AdCache.getAd(AdSize.Small, "progress-modal")
                                            isAdLoaded.set(true)
                                            delay(3_000)
                                        }
                                    }
                                }
                            }
                            // empty space so that when the add is loading there is a blank space
                            sizeConstraints(width = 300.dp, height = 250.dp) - col {
                                ::exists { showWhitespaceInsteadOfAd() }
                            }
                        }
                    }
                }
            }
        }
    }

    cancellable = AppScope.launch {
        try {

            // wait for isAdLoaded to be true
            // if it is null ad failed to load skip loading the add
            var maxDelay = 1000
            var timeWaitingForAdToLoade = 0
            while (isAdLoaded() == false && timeWaitingForAdToLoade < maxDelay) {
                delay(100)
                timeWaitingForAdToLoade += 100
            }
//            delay(2000)

            object : ProgressHandle {
                override var individualItemProgress: Float by currentItemProgress
                override var itemCount: Int by itemCount
                override var currentIndex: Int by currentItemIndex
                override var image: ImageSource by currentImage

            }.execute()
            println("after execute")
            delay(1_500)
            println("After delay")
        } finally {
            println("Finally")
            AppScope.async { onComplete() }
            println("After async global")
            overlayFrame.removeChild(page)
            println("After remove child")

        }
    }
}


