package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Session
import com.picme.sdk2.unauthApi
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.invoke
import com.picme.LinkSemantic


@Routable("log-in")
object LogInPage : Page, UnauthPage {
    override val title get() = Constant("")
    override fun ViewWriter.render(): ViewModifiable {
        val password = Property("")

        val submitError = Property(false)
        val emailError: Property<String?> = Property(null)
        val passwordError: Property<String?> = Property(null)

        val loginAction = Action("Login") {
//            sessionRefreshToken.value = null
            passwordError set null
            emailError set null
            submitError set false
            if (emailOrPhone().isBlank()) {
                emailError set "Required"
            } else if (!emailOrPhone().isValidEmailOrPhone()) {
                emailError set "Please enter a valid email or phone number"
            } else if (password().isBlank() || password().length < 8) {
                passwordError set "Password must be at least 8 characters long"
            } else {
                try {
                    unauthApi().authenticationHandler.startAuthentication(
                        emailOrPhoneNumber = emailOrPhone().lowercase(),
                        password = password()
                    ).let { startAuth ->
                        val auth = startAuth.successfulAuthentication ?: return@let

                        sessionRefreshToken set if (session() != null) {
                            session()!!.authentication.mergeIntoAccount(
                                targetAccountEmailOrPhoneNumber = emailOrPhone().lowercase(),
                                targetAccountPassword = password()
                            ).let { merged -> merged.successfulAuthentication!!.authenticated.refreshToken }
                        } else {
                            auth.authenticated.refreshToken
                        }

                        val shouldAccept =
                            acceptingInvite()?.qrCode?.clientInfo()?.type?.let {
                                it != InviteType.Referral
                            } ?: false

                        if (shouldAccept) acceptQrCode()
                        else {
                            if (startAuth.successfulAuthentication.authenticatedUser.name.isBlank()) {
                                pageNavigator.navigate(FinishCreateAccount)
                            } else {
                                navigateToCollOrLanding()
                            }
                            acceptingInvite set null
                        }

                    }
                } catch (e: Exception) {
                    e.printStackTrace2()
                    submitError set true
                }
            }
        }

        return authPage("Log in", true) {
            onlyWhen { submitError() } - danger - text {
                content = "Invalid email/password combination. Check your password and try again."
                align = Align.Center
            }

            reactiveSuspending {
                if (emailOrPhone().isValidEmailOrPhone()) {
                    emailError set null
                }
            }

            col {
                field("Email or Phone") {
                    textInput {
                        hint = "Email or Phone"
                        content bind emailOrPhone
                        keyboardHints = KeyboardHints.email
                        reactive {
                            emailError()?.let { throw PlainTextException(it, "Error") }
                        }
                    }
                }
                styledPassword(
                    "Password",
                    new = false,
                    password = password,
                    passwordError = passwordError,
                    action = loginAction
                )
            }

            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)

            actionOrCancelSection(
                onCancel = { pageNavigator.reset(LoginOrSignUp()) },
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Log In")
                        action = loginAction
                    }
                }
            )
            centered - LinkSemantic.onNext - button {
                text("Forgot Password?")
                onClick { pageNavigator.navigate(ResetPassword) }
            }
        }
    }
}