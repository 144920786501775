package com.picme.sdk2.caching

import com.lightningkite.readable.AppScope
import com.picme.sdk2.CachedApi
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.poll.*
import com.picme.views.comments.ScopedEntity

class PollHandlerCache(val basedOn: PollHandlerApi) : CachedApi, PollHandlerApi by basedOn {
    private val polls: HashMap<ScopedEntity, Deferred<ListPollSelectionsResponse>> = hashMapOf()
    private val myPolls: HashMap<ScopedEntity, Deferred<GetPollSelectionResponse>> = hashMapOf()

    override fun clearCache() {
        polls.clear()
        myPolls.clear()
    }

    override suspend fun listPollSelections(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        continuation: String?
    ): ListPollSelectionsResponse {
        return polls.getOrPut(ScopedEntity(scope, pollOrEntityId, pollOrEntityTypeId)) {
            AppScope.async { basedOn.listPollSelections(scope, pollOrEntityTypeId, pollOrEntityId, continuation) }
        }.await()
    }

    override suspend fun patchPoll(scope: DataScope, pollId: PollId, body: PatchPollBody): PatchPollResponse {
        return basedOn.patchPoll(scope, pollId, body).also { clearCache() }
    }

    override suspend fun deletePoll(scope: DataScope, pollId: PollId): DeletePollResponse {
        return basedOn.deletePoll(scope, pollId).also { clearCache() }
    }

    override suspend fun getMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String
    ): GetPollSelectionResponse {
        return myPolls.getOrPut(ScopedEntity(scope, pollOrEntityId, pollOrEntityTypeId)) {
            AppScope.async { basedOn.getMyPollSelection(scope, pollOrEntityTypeId, pollOrEntityId) }
        }.await()

    }

    override suspend fun createPoll(scope: DataScope, body: CreatePollBody): CreatePollResponse {
        return basedOn.createPoll(scope, body).also { clearCache() }
    }

    override suspend fun putMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        selectedChoiceId: String
    ) {
        return basedOn.putMyPollSelection(scope, pollOrEntityTypeId, pollOrEntityId, selectedChoiceId).also {
            clearCache()
        }
    }
}