package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.FadedSemantic
import com.picme.LinkSemantic


fun ViewWriter.eula(prefaceText: String) {
    col {
        spacing = 0.rem
        centered - FadedSemantic.onNext - subtext(prefaceText)
        compact - centered - row {
            LinkSemantic.onNext - externalLink {
                subtext("Terms of Use")
                to = "https://www.picme.com/terms-of-use.html"
                newTab = true
            }
            FadedSemantic.onNext - centered - subtext("and")

            LinkSemantic.onNext - externalLink {
                subtext("Privacy Policy")
                to = "https://www.picme.com/privacy-policy.html"
                newTab = true
            }
        }
    }
}