package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.errorText
import com.lightningkite.readable.invoke
import com.picme.*
import com.picme.views.LoginOrSignUp
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

fun ViewWriter.actionOrCancelSection(
    onCancel: suspend () -> Unit,
    constrainedWidth: Boolean = false,
    actionButton: RowOrCol.() -> Unit,
) {
    if (constrainedWidth) {
        centered - sizeConstraints(width = 16.rem) - row {
            spacing = 1.5.rem
            expanding - SemiImportantSemantic.onNext - buttonTheme - button {
                this.onClick("onCancel", null, onCancel)
                centered - text("Cancel")
            }
            expanding - actionButton()
        }
    } else {
        row {
            expanding - SemiImportantSemantic.onNext - buttonTheme - button {
                this.onClick("onCancel", null, onCancel)
                centered - text("Cancel")
            }
            expanding - actionButton()
        }
    }
}


@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.unStyledField(label: String, content: ViewWriter.() -> ViewModifiable): ViewModifiable {
    contract { callsInPlace(content, InvocationKind.EXACTLY_ONCE) }
    return col {
        spacing = 0.px
        FieldLabelSemantic.onNext - text(label)
        content()
        SubtextSemantic.onNext - errorText()
    }
}


inline fun ViewWriter.formColumn(
    fieldSection: RowOrCol.() -> Unit = {},
    noinline actionSection: (RowOrCol.() -> Unit)? = null
): ViewModifiable {
    return scrolling - col {
        paddingByEdge = Edges(0.rem, 1.5.rem)
        spacing = 2.rem

        col {
            padding = 0.5.rem
            spacing = 1.5.rem
            fieldSection(this)
        }

        separator() {
            exists = actionSection != null
        }

        col {
            spacing = 2.rem
            exists = actionSection != null
            actionSection?.let { it(this) }
        }
    }
}

fun ViewWriter.loginOrSignupButton(onClick: () -> Unit = {}) = SemiImportantSemantic.onNext - buttonTheme - button {
    ::exists{ (session()?.isVerifiedAccount() != true) }
    onClick {
        onClick()
        emailOrPhone set ""
        dialogPageNavigator.clear()
        mainPageNavigator.navigate(LoginOrSignUp(true))
    }

    paddingByEdge = Edges(1.5.rem, 0.25.rem)
    centered - text {
        content = "Log in / Sign up"
    }
}


suspend fun ViewWriter.ensureVerifiedAccount(action: suspend () -> Unit) {
    if (session()?.isVerifiedAccount() == true) action()
    else {
        dialogGeneric { close ->
            centered - h4("Create Your Free Account")
            text("Sign up now to access Notifications, Sharing, Guests, Comments, Likes, and more!")

            actionOrCancelSection(close, actionButton = {
                important - buttonTheme - button {
                    centered - text("Sign Up")
                    onClick {
                        mainPageNavigator.reset(LoginOrSignUp())
                        close()
                    }
                }
            })
            space()
            centered - row {
                padding = 0.dp
                spacing = 0.5.rem
                text("Already have an account?")
                NaturalLinkSemantic.onNext - link {
                    to = { LoginOrSignUp() }
                    onNavigate { close() }
                    text("Log In")
                }
            }

        }
    }
}

