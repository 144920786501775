package com.picme.views

import com.lightningkite.kiteui.RequestBodyFile
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.FieldLabelSemantic
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.reactive.Action
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.collection2.PatchCollectionBody
import kotlinx.coroutines.launch

@Routable("/collection/{urlSafeId}/settings")
class CollectionSettings(override val urlSafeId: String) : ControlsStatusBarColor, PageForCollection(), HasSubtitle {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = CollectionSettings(urlSafeId)
    override val subtitle: Readable<String> get() = Constant("Settings")

    private val collName = Property("")
    private val message = Property("")
    private val imageId = Property("")

    override fun ViewWriter.render(): ViewModifiable {
        val chosenImage = Property(ImageUploadInfo())
        launch {
            val collection = collection()
            collName set collection.collection.name
            message set collection.collection.message
            imageId set (collection().collection.coverPhotoVersionStamp?.raw ?: "")
            chosenImage set ImageUploadInfo(original = collection().getCoverPhotoUri.imageIfExists())
        }

        return formColumn(
            fieldSection = {
                field("Collection Name") {
                    textInput {
                        hint = "Name"
                        content bind collName
                        action = Action("Done", Icon.info) { }
                    }
                }

                unStyledField("Collection Image") {
                    imagePlaceholderPicker(chosenImage, "Choose Image")
                }

                field("Message to Guest Uploaders") {
                    sizeConstraints(height = 5.rem) - textArea {
                        hint = "Message (optional)"
                        content bind message
                        action = Action("Done", Icon.info) { }
                    }
                }
            },
            actionSection = {
                actionOrCancelSection(
                    onCancel = { pageNavigator.dismiss() },
                    constrainedWidth = true,
                    actionButton = {
                        important - buttonTheme - button {
                            centered - text("Save")
                            ::enabled { collName().isNotEmpty() }
                            onClick {
                                chosenImage().newImage?.let {
                                    session.awaitNotNull().collection2.putCollectionCoverPhoto(
                                        collectionId = collectionId,
                                        body = RequestBodyFile(it),
                                        tempUri = null,
                                        onProgress = {}
                                    )
                                }
                                if (chosenImage().deleteOriginal && imageId().isNotBlank()) {
                                    session.awaitNotNull().collection2.deleteCollectionCoverPhoto(
                                        collectionId = collectionId,
                                        photoId = imageId()
                                    )
                                }

                                session.awaitNotNull().collection2.patchCollection(
                                    collectionId = collectionId,
                                    body = PatchCollectionBody(
                                        name = Retainable(collName()),
                                        message = Retainable(message())
                                    )
                                )
                                pageNavigator.dismiss()
                            }
                        }
                    }
                )

                centered - danger - button {
                    text { content = "Delete collection" }
                    onClick {
                        showConfirmDialog(
                            title = "Delete Collection",
                            content = "Are you sure you want to permanently delete this collection?",
                            warning = "This action cannot be undone.",
                            confirmLabel = "Delete",
                            onConfirm = {
                                session.awaitNotNull().collection2.deleteCollection(collectionId)
                                navigateToCollOrLanding(notThisCollection = collectionId)
                            }
                        )
                    }
                }
            }

        )

    }
}
