package com.picme.components

import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.buttonTheme
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.important
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.readable.*
import com.picme.*
import com.picme.sdk2.generated.collection2.CreateCollectionBody
import com.picme.sdk2.toSafeEncoded
import com.picme.views.CollectionImageView

fun ViewWriter.createCollectionModal() {
    dialogGeneric { close ->
        val collName = Property("")

        h4 {
            content = "Create Collection"
            align = Align.Center
        }

        col {
            spacing = 2.rem
            val action = Action(
                "Create Collection",
                action = {
                    // Silently create an unverified account when creating a collection without authentication
                    createAccountIfNewUser()

                    val created = session.awaitNotNull().collection2.createCollection(
                        CreateCollectionBody(name = collName())
                    )

                    createInitialQrCodes(created.collection)
                    session.awaitNotNull().collection2.forceRefresh()
                    collName set ""
                    mainPageNavigator.reset(CollectionImageView(created.collection.collectionId.raw.toSafeEncoded()))
                    close()
                }
            )

            field("Collection Name") {
                textInput {
                    content bind collName
                    hint = "Name"
                    this.action = action
                    requestFocus()
                }
            }

            actionOrCancelSection(
                onCancel = { close() },
                actionButton = {
                    important - buttonTheme - button {
                        this.action = action
                        centered - text("Continue")
                        ::enabled { collName().isNotEmpty() }
                    }
                }
            )
        }
    }
}