package com.picme.sdk2.generated.notification

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object NotificationHandlerEndpointsSerializer : KSerializer<NotificationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("NotificationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: NotificationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): NotificationHandlerEndpoints {
        val string = decoder.decodeString()
        return NotificationHandlerEndpoints(string)
    }
}


@Serializable(with = NotificationHandlerEndpointsSerializer::class)
data class NotificationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Updates the Firebase Cloud Messaging project configuration. **/
    suspend fun updateFirebaseCloudMessagingProjectConfig(
        config: FirebaseCloudMessagingProjectConfig,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/firebase-project",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    suspend fun getFirebaseCloudMessagingProjectConfig(
        accessToken: suspend () -> String? = { null }
    ): GetFirebaseCloudMessagingProjectConfigResponse = fetch(
        url = "$url/config/firebase-project",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    suspend fun updateFirebaseCloudMessagingAppConfig(
        config: FirebaseCloudMessagingAppConfig,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/config/firebase-app",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "config" to config.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    suspend fun getFirebaseCloudMessagingAppConfig(
        accessToken: suspend () -> String? = { null }
    ): GetFirebaseCloudMessagingAppConfigResponse = fetch(
        url = "$url/config/firebase-app",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets a summary of the ambient user's notification events for the specified time range. **/
    suspend fun getUserNotificationSummaryByRange(
        startTime: DateTime,
        endTime: DateTime,
        accessToken: suspend () -> String? = { null }
    ): GetUserNotificationSummaryResponse = fetch(
        url = "$url/summary-range",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "startTime" to startTime.toParamString(),
            "endTime" to endTime.toParamString(),
        ).filter { it.value != "null" },
    )
/** Marks the specified notification event as read. **/
    suspend fun markNotificationSummaryRead(
        date: DateOnly,
        scope: DataScope,
        targetEntityTypeId: RecordTypeId,
        targetEntityId: String,
        eventType: NotificationDigestEventType,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/summary/read",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "date" to date.toParamString(),
            "scope" to scope.toParamString(),
            "targetEntityTypeId" to targetEntityTypeId.toParamString(),
            "targetEntityId" to targetEntityId.toParamString(),
            "eventType" to eventType.toParamString(),
        ).filter { it.value != "null" },
    )
/** Sets one or more notification subscription configurations. **/
    suspend fun putNotificationSubscriptionConfigurations(
        subscriptions: List<NotificationSubscription>,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/notification",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "subscriptions" to subscriptions.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists the user's notification subscription configurations. **/
    suspend fun listNotificationSubscriptionConfigurations(
        accessToken: suspend () -> String? = { null }
    ): ListNotificationSubscriptionConfigurationsResponse = fetch(
        url = "$url/notification",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets the ambient user's notification events for the specified time range. **/
    suspend fun getUserNotificationEventsByRange(
        startTime: DateTime,
        endTime: DateTime,
        accessToken: suspend () -> String? = { null }
    ): GetUserNotificationEventsResponse = fetch(
        url = "$url/event-range",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "startTime" to startTime.toParamString(),
            "endTime" to endTime.toParamString(),
        ).filter { it.value != "null" },
    )
/** Marks the specified notification event as read. **/
    suspend fun markNotificationEventRead(
        scope: DataScope,
        targetEntityTypeId: RecordTypeId,
        targetEntityId: String,
        eventType: NotificationDigestEventType,
        eventTime: DateTime,
        actorId: String,
        parameters: List<String>?,
        accessToken: suspend () -> String? = { null }
    ): MarkNotificationEventReadResponse = fetch(
        url = "$url/event/read",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "scope" to scope.toParamString(),
            "targetEntityTypeId" to targetEntityTypeId.toParamString(),
            "targetEntityId" to targetEntityId.toParamString(),
            "eventType" to eventType.toParamString(),
            "eventTime" to eventTime.toParamString(),
            "actorId" to actorId.toParamString(),
            "parameters" to parameters.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets a summary of the ambient user's notification events for the specified date. **/
    suspend fun getUserNotificationSummary(
        date: DateOnly,
        accessToken: suspend () -> String? = { null }
    ): GetUserNotificationSummaryResponse = fetch(
        url = "$url/summary",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "date" to date.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets the ambient user's notification events for the specified date. **/
    suspend fun getUserNotificationEvents(
        date: DateOnly,
        accessToken: suspend () -> String? = { null }
    ): GetUserNotificationEventsResponse = fetch(
        url = "$url/event",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "date" to date.toParamString(),
        ).filter { it.value != "null" },
    )
}

