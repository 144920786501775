package com.picme.actuals

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.mimeType
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import kotlinx.coroutines.launch
import org.w3c.dom.DragEvent
import org.w3c.dom.get

actual fun ViewWriter.dropFile(
    collId: CollectionId,
    onDrop: suspend (validFiles: List<FileReference>, invalidFiles: List<FileReference>) -> Unit
): RView {
    val dropState = Property(false)
    return stack {
        expanding - card - ThemeDerivation {
            it.copy(
                outline = Color.darkPurple.applyAlpha(1.0f),
                outlineWidth = 2.dp,
                cornerRadii = CornerRadius.ForceConstant(1.rem),
            ).withoutBack
        }.onNext - stack {
            native.addEventListener("drop") { event ->
                event.preventDefault()
                val transferItems = (event as DragEvent).dataTransfer?.items
                launch {
                    transferItems?.let { itemsNN ->
                        val items = (0..itemsNN.length).map { index -> itemsNN[index] }
                            .mapNotNull { it }
                        val files = items.mapNotNull { it.getAsFile() }

                        val invalidFiles = files.filter {
                            val mimeType = it.mimeType()
                            val type = mimeType.split("/")[0]
                            type != "image" && type != "video"
                        }

                        val validFiles = files.filter {
                            val mimeType = it.mimeType()
                            val type = mimeType.split("/")[0]
                            type == "image" || type == "video"
                        }

                        onDrop(validFiles, invalidFiles)
                        dynamicTheme {
                            ThemeDerivation {
                                it.copy(background = Color.white).withoutBack
                            }
                        }
                    }
                }
            }
            native.addEventListener("dragenter") { event ->
                event.preventDefault()
                launch { dropState set true }
                dynamicTheme {
                    ThemeDerivation {
                        it.copy(background = Color.darkPurple.applyAlpha(0.1f)).withBack
                    }
                }
            }
            native.addEventListener("dragleave") { event ->
                event.preventDefault()
                launch { dropState set false }
                dynamicTheme {
                    ThemeDerivation {
                        it.copy(background = Color.white).withoutBack
                    }
                }
            }
            native.addEventListener("dragover") { event ->
                event.preventDefault()
            }
            centered - col {
                themeChoice += ThemeDerivation {
                    it.copy(
                        foreground = Color.darkGray,
                        spacing = 0.2.rem
                    ).withoutBack
                }
                centered - sizeConstraints(width = 3.0.rem, height = 3.0.rem) - button {
                    image { source = Vectors.logoWithAdd }
                    onClick {
                        uploadExternalFiles(collId)
                    }
                }

                centered - row {
                    spacing = 0.dp
                    centered - text("To Upload, click the button or drag and drop here")
                }
            }

        }
    }
}

actual fun RView.preventDrop() {
    native.addEventListener("drop") { event ->
        event.preventDefault()
    }
    native.addEventListener("dragover") { event ->
        event.preventDefault()
    }
}