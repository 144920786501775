package com.picme.sdk2.generated.poll

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object PollHandlerEndpointsSerializer : KSerializer<PollHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("PollHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: PollHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): PollHandlerEndpoints {
        val string = decoder.decodeString()
        return PollHandlerEndpoints(string)
    }
}


@Serializable(with = PollHandlerEndpointsSerializer::class)
data class PollHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Submits a poll selection for the currently-authenticated user. **/
    suspend fun putMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        selectedChoiceId: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/poll/${scope.toParamString()}/${pollOrEntityTypeId.toParamString()}/${pollOrEntityId.toParamString()}/myselection",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "selectedChoiceId" to selectedChoiceId.toParamString(),
        ).filter { it.value != "null" },
    )
/** Gets any poll selection for the currently-authenticated user. **/
    suspend fun getMyPollSelection(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        accessToken: suspend () -> String? = { null }
    ): GetPollSelectionResponse = fetch(
        url = "$url/poll/${scope.toParamString()}/${pollOrEntityTypeId.toParamString()}/${pollOrEntityId.toParamString()}/myselection",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Lists the poll selection details. **/
    suspend fun listPollSelections(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        continuation: String?,
        accessToken: suspend () -> String? = { null }
    ): ListPollSelectionsResponse = fetch(
        url = "$url/poll/${scope.toParamString()}/${pollOrEntityTypeId.toParamString()}/${pollOrEntityId.toParamString()}/selections",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "continuation" to continuation.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists the poll results summary. **/
    suspend fun listPollResultSummary(
        scope: DataScope,
        pollOrEntityTypeId: RecordTypeId,
        pollOrEntityId: String,
        accessToken: suspend () -> String? = { null }
    ): ListPollResultSummaryResponse = fetch(
        url = "$url/poll/${scope.toParamString()}/${pollOrEntityTypeId.toParamString()}/${pollOrEntityId.toParamString()}/summary",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Patches a standalone poll. **/
    suspend fun patchPoll(
        scope: DataScope,
        pollId: PollId,
        body: PatchPollBody,
        accessToken: suspend () -> String? = { null }
    ): PatchPollResponse = fetch(
        url = "$url/poll/i/${scope.toParamString()}/${pollId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes the specified standalone poll. **/
    suspend fun deletePoll(
        scope: DataScope,
        pollId: PollId,
        accessToken: suspend () -> String? = { null }
    ): DeletePollResponse = fetch(
        url = "$url/poll/i/${scope.toParamString()}/${pollId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Creates a standalone poll. **/
    suspend fun createPoll(
        scope: DataScope,
        body: CreatePollBody,
        accessToken: suspend () -> String? = { null }
    ): CreatePollResponse = fetch(
        url = "$url/poll/i/${scope.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Lists standalone polls that the user has rights to. **/
    suspend fun listPolls(
        scope: DataScope,
        filter: PollQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListPollsResponse = fetch(
        url = "$url/poll/i/${scope.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filter" to filter.toParamString(),
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )
}

