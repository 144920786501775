package com.picme.sdk2.generated.discussion

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class DiscussionHandlerApiLive(val endpoints: DiscussionHandlerEndpoints, val accessToken: suspend () -> String?): DiscussionHandlerApi {
/** Patches a previously-posted discussion message. **/
    override suspend fun patchDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId,
        body: PatchDiscussionMessageBody,
    ): PatchDiscussionMessageResponse = endpoints.patchDiscussionMessage(
        scope = scope,
        discussionOrEntityTypeId = discussionOrEntityTypeId,
        discussionOrEntityId = discussionOrEntityId,
        discussionMessageId = discussionMessageId,
        body = body,
        accessToken = accessToken,
    )
/** Deletes a discussion message. **/
    override suspend fun deleteDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        discussionMessageId: DiscussionMessageId,
    ): DeleteDiscussionMessageResponse = endpoints.deleteDiscussionMessage(
        scope = scope,
        discussionOrEntityTypeId = discussionOrEntityTypeId,
        discussionOrEntityId = discussionOrEntityId,
        discussionMessageId = discussionMessageId,
        accessToken = accessToken,
    )
/** Creates a discussion message. **/
    override suspend fun createDiscussionMessage(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        body: CreateDiscussionMessageBody,
    ): CreateDiscussionMessageResponse = endpoints.createDiscussionMessage(
        scope = scope,
        discussionOrEntityTypeId = discussionOrEntityTypeId,
        discussionOrEntityId = discussionOrEntityId,
        body = body,
        accessToken = accessToken,
    )
/** Lists discussion messages in the specified discussion. **/
    override suspend fun listDiscussionMessages(
        scope: DataScope,
        discussionOrEntityTypeId: RecordTypeId,
        discussionOrEntityId: String,
        continuation: String?,
    ): ListDiscussionMessagesResponse = endpoints.listDiscussionMessages(
        scope = scope,
        discussionOrEntityTypeId = discussionOrEntityTypeId,
        discussionOrEntityId = discussionOrEntityId,
        continuation = continuation,
        accessToken = accessToken,
    )
/** Patches a standalone discussion. **/
    override suspend fun patchDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
        body: PatchDiscussionBody,
    ): PatchDiscussionResponse = endpoints.patchDiscussion(
        scope = scope,
        discussionId = discussionId,
        body = body,
        accessToken = accessToken,
    )
/** Gets the details for the specified standalone discussion. **/
    override suspend fun getDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
    ): GetDiscussionResponse = endpoints.getDiscussion(
        scope = scope,
        discussionId = discussionId,
        accessToken = accessToken,
    )
/** Deletes the specified standalone discussion. **/
    override suspend fun deleteDiscussion(
        scope: DataScope,
        discussionId: DiscussionId,
    ): DeleteDiscussionResponse = endpoints.deleteDiscussion(
        scope = scope,
        discussionId = discussionId,
        accessToken = accessToken,
    )
/** Creates a standalone discussion. **/
    override suspend fun createDiscussion(
        scope: DataScope,
        body: CreateDiscussionBody,
    ): CreateDiscussionResponse = endpoints.createDiscussion(
        scope = scope,
        body = body,
        accessToken = accessToken,
    )
/** Lists standalone discussions that the user has rights to. **/
    override suspend fun listDiscussions(
        scope: DataScope,
        filterQuery: DiscussionQuery?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
    ): ListDiscussionsResponse = endpoints.listDiscussions(
        scope = scope,
        filterQuery = filterQuery,
        itemsPerPage = itemsPerPage,
        continuation = continuation,
        forUserId = forUserId,
        accessToken = accessToken,
    )
}
