package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.direct.onClick
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.invoke
import com.picme.*
import com.picme.GridSize
import com.picme.MenuOptionSemantic
import com.picme.PIcon
import com.picme.components.*

@Routable("/collection/{urlSafeId}/trash")
class ViewTrash(override val urlSafeId: String) : ControlsStatusBarColor, PageForCollection(), HasActions, HasSubtitle {
    override val subtitle: Readable<String> get() = Constant("Trash")
    override val description: Readable<String> get() = Constant("Items expire after 30 days")
    override fun forOtherCollection(urlSafeId: String) = ViewTrash(urlSafeId)
    val selectTrash = SelectItems()

    override fun ViewWriter.render(): ViewModifiable {
        return unpadded - frame {
            val collection =
                shared { session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection }
            val trashImages = shared {
                session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all()
            }

            centered - activityIndicator {
                reactiveScope {
                    exists = true
                    trashImages()
                    exists = false
                }
            }
            centered - FadedSemantic.onNext - text {
                exists = false
                ::exists { trashImages().isEmpty() }
                content = "Trash is empty"
            }
            expanding - unpadded - col {
                spacingAndPadding = 0.dp
                exists = false
                ::exists { trashImages().isNotEmpty() }

                selectItemsBar(selectTrash)

                expanding - imageRecyclerView(
                    colImages = shared { trashImages().toRecyclableInfo() },
                    selectedImages = selectTrash,
                    setup = {
                        reactiveSuspending {
                            collection().trashedImages().requireIndexLoaded = lastVisibleIndex() + 50
                        }
                    }
                )
            }
        }
    }

    val weAreOwner = shared { ownsPCollection(collection().collection)() }
    val trashImages = shared {
        session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all()
    }
    override val actions: List<PicmeAction> = listOf(
        PicmeAction(
            title = "Close",
            icon = PIcon.close,
            shown = { selectTrash.isSelecting() },
            count = { selectTrash.selected().size },
            action = { selectTrash.stopSelecting() }
        ),
        PicmeAction(
            title = "Restore",
            icon = PIcon.restore,
            shown = { selectTrash.isSelecting() && weAreOwner() },
            enabled = { selectTrash.selected().isNotEmpty() },
            action = {
                showConfirmDialog(
                    title = "Confirm Restore",
                    content = "Selected items will be restored to the collection.",
                    confirmLabel = "Restore",
                    onConfirm = {
                        val images = trashImages().filter {
                            it.uploadId.raw in selectTrash.selected().map { it.id }
                        }
                        selectTrash.stopSelecting()
                        restoreImages(collectionId, images)
                    }
                )
            }
        ),
        PicmeAction(
            title = "Delete",
            icon = PIcon.trash,
            shown = { selectTrash.isSelecting() && weAreOwner() },
            enabled = { selectTrash.selected().isNotEmpty() },
            action = {
                showConfirmDialog(
                    title = "Confirm Delete",
                    content = "Selected items will be permanently deleted.",
                    confirmLabel = "Delete",
                    warning = "This action cannot be undone.",
                    onConfirm = {
                        val images = trashImages().filter {
                            it.uploadId.raw in selectTrash.selected().map { it.id }
                        }
                        selectTrash.stopSelecting()
                        deleteImages(collectionId, images)
                    }
                )
            }
        ),
        PicmeAction(
            title = "Select",
            icon = PIcon.select,
            shown = { !selectTrash.isSelecting() },
            action = { selectTrash.startSelecting() }
        ),
        PicmeAction(
            title = "More",
            icon = PIcon.more,
            shown = { !selectTrash.isSelecting() },
            menu = {
                sizeConstraints(width = 20.rem) - MenuOptionContainerSemantic.onNext - col {
                    MenuOptionSemantic.onNext - button {
                        row {
                            icon {
                                description = ""
                                ::source {
                                    when (gridSize()) {
                                        GridSize.Small -> PIcon.expand
                                        GridSize.Large -> PIcon.contract
                                    }
                                }
                            }
                            text {
                                ::content {
                                    when (gridSize()) {
                                        GridSize.Small -> "Large grid size"
                                        GridSize.Large -> "Small grid size"
                                    }
                                }
                            }
                        }
                        onClick {
                            closePopovers()
                            gridSize set when (gridSize.value) {
                                GridSize.Large -> GridSize.Small
                                GridSize.Small -> GridSize.Large
                            }
                        }
                    }

                    MenuOptionSemantic.onNext - button {
                        row {
                            icon(PIcon.restore, "")
                            text("Restore all items")
                        }
                        onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Restore",
                                content = "All items will be restored to the collection.",
                                confirmLabel = "Restore",
                                onConfirm = {
                                    restoreImages(
                                        this@ViewTrash.collectionId,
                                        session.awaitNotNull().collection2.listDeletedUploadsLive(
                                            this@ViewTrash.collectionId
                                        ).all()
                                    )
                                }
                            )
                        }
                    }

                    MenuOptionSemantic.onNext - button {
                        row {
                            icon(PIcon.trash, "")
                            text("Delete all items")
                        }
                        onClick {
                            closePopovers()
                            showConfirmDialog(
                                title = "Confirm Delete",
                                content = "All items will be permanently deleted.",
                                confirmLabel = "Delete",
                                warning = "This action cannot be undone.",
                                onConfirm = {
                                    deleteImages(
                                        this@ViewTrash.collectionId,
                                        session.awaitNotNull().collection2.listDeletedUploadsLive(
                                            this@ViewTrash.collectionId
                                        ).all(),
                                        all = true
                                    )
                                }
                            )
                        }
                    }
                }
            }
        )
    )
}