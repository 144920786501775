package com.picme.actuals

import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.mimeType
import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.Property
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.components.ToastType
import com.picme.components.showToast
import com.picme.sdk2.generated.CollectionId
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.launch
import org.w3c.dom.DragEvent
import org.w3c.dom.get
import kotlin.reflect.KSuspendFunction4

actual fun ViewWriter.dropFile(collId: CollectionId): RView {
    val dropState = Property(false)
    return frame {

        suspend fun onDrop1(validFiles: List<FileReference>, invalidFiles: List<FileReference>) {
            if (validFiles.isNotEmpty()) {
                uploadAllExternalFiles(collId, validFiles)
            }
            if (invalidFiles.isNotEmpty()) {
                showToast(
                    "Upload failed for ${invalidFiles.size} ${"file".pluralize(invalidFiles.size)}",
                    "Invalid file type(s)",
                    ToastType.Error
                )
            }
        }

        expanding - DropTargetSemantic.onNext - frame {
            dynamicTheme {
                if (dropState()) SelectedSemantic else null
            }
            native.addEventListener("drop") { event ->
                event.preventDefault()
                val transferItems = (event as DragEvent).dataTransfer?.items
                launch {
                    transferItems?.let { itemsNN ->
                        val items = (0..itemsNN.length).map { index -> itemsNN[index] }
                            .mapNotNull { it }
                        val files = items.mapNotNull { it.getAsFile() }

                        val invalidFiles = files.filter {
                            val mimeType = it.mimeType()
                            val type = mimeType.split("/")[0]
                            type != "image" && type != "video"
                        }

                        val validFiles = files.filter {
                            val mimeType = it.mimeType()
                            val type = mimeType.split("/")[0]
                            type == "image" || type == "video"
                        }

                        onDrop1(validFiles, invalidFiles)
                    }
                }
            }
            native.addEventListener("dragenter") { event ->
                event.preventDefault()
                launch { dropState set true }
            }
            native.addEventListener("dragleave") { event ->
                event.preventDefault()
                launch { dropState set false }
            }
            native.addEventListener("dragover") { event ->
                event.preventDefault()
            }
            centered - col {
                centered - sizeConstraints(
                    width = 64.dp,
                    height = 64.dp
                ) - buttonTheme - important - button {
                    centered - icon {
                        source = PIcon.brandedAdd.copy(width = 2.5.rem, height = 2.5.rem)
                    }
                    onClick {
                        uploadExternalFiles(collId)
                    }
                }
                centered - text("Drop files here or click the button to upload")
            }

        }
    }
}

actual fun RView.preventDrop() {
    native.addEventListener("drop") { event ->
        event.preventDefault()
    }
    native.addEventListener("dragover") { event ->
        event.preventDefault()
    }
}