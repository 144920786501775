package com.picme.sdk2.caching

import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.reactive.*
import com.picme.sdk2.json
import kotlinx.datetime.Clock
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer
import kotlin.time.Duration

abstract class PagedHelperCache<T, ID>(
    val clock: Clock,
    val externalStorage: ExternalStorage,
    val serializer: KSerializer<T>,
    val cacheLife: Duration,
    val log: Console? = null
) : Paged<T> {
    data class Response<T>(val next: String? = null, val list: List<T>)

    abstract val comparator: Comparator<T>
    abstract fun id(t: T): ID
    abstract suspend fun next(token: String? = null): Response<T>
    open suspend fun detail(id: ID): T = TODO()

    private var lastRepull = clock.now()
    fun quietRefreshIfNeeded() {
        if (clock.now() - lastRepull > cacheLife) {
            quietRefresh()
            log?.info("Quit refresh is needed.")
        }
    }

    private val nextContinuation = Property<String?>("")
    private val data: LateInitProperty<List<T>> = LateInitProperty()

    private var tombstone: MutableList<ID> = mutableListOf()

    init {
        externalStorage.get("cache")
            ?.let { json.decodeFromString(ListSerializer(serializer), it) }
            ?.let { data.value = it }
        data.addListener {
            data.state.onSuccess {
                externalStorage.set("cache", json.encodeToString(ListSerializer(serializer), it))
            }
        }
    }

    private val loading = Property(false)
    override val pullingMore: Readable<Boolean>
        get() = loading
    override val all: Readable<List<T>>
        get() = data
    override val done: Readable<Boolean>
        get() = shared { nextContinuation.invoke() == null }
    private var lastUpdatedIndex: Int = -1
    override var requireIndexLoaded: Int = 0
        set(value) {
            field = value
            log?.info("requireIndexLoaded: $field")
            startPullIfNeeded()
        }

    private fun startPullIfNeeded() {
        if (loading.value) run { log?.info("No need to pull, already loading"); return }
        val cont = nextContinuation.value ?: run { log?.info("No need to pull, no continuation"); return }
        if (lastUpdatedIndex >= requireIndexLoaded) run { log?.info("No need to pull, index high enough $lastUpdatedIndex vs $requireIndexLoaded"); return }
        loading.value = true
        launchGlobal {
            try {
                log?.info("Attempting pull of ${cont}")
                val result = next(cont.takeUnless { it == "" })
                log?.info("Got continuation '${result.next}'")
                nextContinuation.value = result.next
                if (data.state.ready) {
                    val lastCanonical = data.value.getOrNull(lastUpdatedIndex)
                    val lastItemInData = result.list.lastOrNull()
                    data.value = data.value.filter {
                        (lastCanonical?.let { lc -> comparator.compare(it, lc) <= 0 } ?: false) ||
                                (result.next != null && lastItemInData != null && comparator.compare(
                                    it,
                                    lastItemInData
                                ) > 0)
                    }.plus(result.list).sortedWith(comparator).distinctBy(::id)
                    lastUpdatedIndex = data.value.indexOf(lastItemInData)
                } else {
                    data.value = result.list.sortedWith(comparator).distinctBy(::id)
                    lastUpdatedIndex = result.list.lastIndex
                }
                loading.value = false
                lastRepull = clock.now()
                startPullIfNeeded()
            } catch (e: Exception) {
                loading.value = false
            }
        }
    }

    fun startup() {
        startPullIfNeeded()
    }

    fun forceRefresh() {
        nextContinuation.value = ""
        lastUpdatedIndex = -1
        data.unset()
        startPullIfNeeded()
    }

    fun quietRefresh() {
        nextContinuation.value = ""
        lastUpdatedIndex = -1
        startPullIfNeeded()
    }

    fun update(id: ID, replacement: T?) {
        if (data.state.ready) {
            if (replacement == null) {
                tombstone.add(id)
            }
            data.value = data.value.filter { id(it) != id }.plus(listOfNotNull(replacement))
                .sortedWith(comparator).distinctBy(::id)
        }
    }

    fun update(id: ID, modifier: (T) -> T) {
        if (data.state.ready) {
            data.value = data.value.map { if (id(it) == id) modifier(it) else it }
        }
    }

    fun updateClear() {
        data.value = listOf()
    }

    fun updateInsertMany(newItems: List<T>) {
        data.value = data.value.plus(newItems).sortedWith(comparator).distinctBy(::id)
    }

    fun single(id: ID): Readable<T> {
        return shared {
            val match = data().find { id(it) == id }
            if (match != null) return@shared match
            if (tombstone.contains(id)) throw ReactiveLoading
            val pulled = async(id) { detail(id) }
            return@shared pulled
        }
    }
}