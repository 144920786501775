package com.picme.components

import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.FileReference
import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.ImagePlaceholderSemantic


data class ImageUploadInfo(
    val newImage: FileReference? = null,
    val deleteOriginal: Boolean = false,
    val original: ImageRemote? = null
)

fun ViewWriter.imagePlaceholderPicker(chosenImage: Property<ImageUploadInfo>, chooseImageText: String): ViewModifiable {
    val isImage = shared { chosenImage().newImage != null || chosenImage().original != null }
    return row {
        sizeConstraints(width = 5.rem, height = 5.rem) - centered - ImagePlaceholderSemantic.onNext - frame {
            ImagePlaceholderSemantic.onNext - image {
                ::exists { isImage() }
                refreshOnParamChange = true
                scaleType = ImageScaleType.Crop
                ::source { chosenImage().newImage?.let(::ImageLocal) ?: chosenImage().original }
            }
        }

        centered - col {
            atStart - compact - buttonTheme - button {
                onClick {
                    ExternalServices.requestFile(listOf("image/*")).let {
                        if (it == null) return@let
                        chosenImage set ImageUploadInfo(newImage = it)
                    }
                }
                centered - text(chooseImageText)
            }
            atStart - onlyWhen { isImage() } - compact - buttonTheme - button {
                onClick {
                    chosenImage set ImageUploadInfo(deleteOriginal = true)
                }
                centered - text("Reset")
            }
        }
    }
}