package com.picme.installrefer

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.Property
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.frame
import com.picme.UnauthPage

import com.picme.navigateToCollOrLanding
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sessionRefreshToken
import com.picme.views.PageForCollection
import com.picme.views.QrAccept
import kotlinx.coroutines.launch

@Routable("/install-refer-finish")
class InstallReferFinishPage : Page, UnauthPage {

    @QueryParameter("token")
    val refreshToken = Property<String?>(null)

    @QueryParameter("collection")
    val lastViewedCollection = Property<PCollection?>(null)

    @QueryParameter("qr")
    val shouldOpenQrCodeId = Property<String?>(null)

    override fun ViewWriter.render(): ViewModifiable {
        launch {
            // For security, only set a token and collection when none are already set (i.e. on a fresh install)
            // Otherwise, malicious actors could trick users into uploading photos to an account that is not their own
            if (sessionRefreshToken().isNullOrEmpty() && mainPageNavigator.currentPage() !is PageForCollection) {
                sessionRefreshToken.value = refreshToken()

                val codeId = shouldOpenQrCodeId()
                if (!codeId.isNullOrEmpty()) {
                    pageNavigator.replace(QrAccept(codeId))
                } else {
                    navigateToCollOrLanding()
                }
            } else {
                navigateToCollOrLanding()
            }
        }
        return frame()
    }
}