package com.picme.views

import com.lightningkite.kiteui.*


suspend fun getBlob(url: String): Blob {
//    https://stackoverflow.com/questions/65191708/chrome-browser-and-cors-issue-when-caching-present-in-request-headers
    return fetch(
        url, method = HttpMethod.GET,
        httpHeaders().apply {
            append("mode", "cors")
            append("cache", "no-cache")
            append("Cache-Control", "no-cache")
        },
    ).blob()
}
