package com.picme.sdk2.generated.notification

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class NotificationHandlerApiLive(val endpoints: NotificationHandlerEndpoints, val accessToken: suspend () -> String?): NotificationHandlerApi {
/** Updates the Firebase Cloud Messaging project configuration. **/
    override suspend fun updateFirebaseCloudMessagingProjectConfig(
        config: FirebaseCloudMessagingProjectConfig,
    ): Unit = endpoints.updateFirebaseCloudMessagingProjectConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    override suspend fun getFirebaseCloudMessagingProjectConfig(
    ): GetFirebaseCloudMessagingProjectConfigResponse = endpoints.getFirebaseCloudMessagingProjectConfig(
        accessToken = accessToken,
    )
/** Updates the Firebase Cloud Messaging project configuration. **/
    override suspend fun updateFirebaseCloudMessagingAppConfig(
        config: FirebaseCloudMessagingAppConfig,
    ): Unit = endpoints.updateFirebaseCloudMessagingAppConfig(
        config = config,
        accessToken = accessToken,
    )
/** Gets the Firebase Cloud Messaging projecct configuration needed by the client. **/
    override suspend fun getFirebaseCloudMessagingAppConfig(
    ): GetFirebaseCloudMessagingAppConfigResponse = endpoints.getFirebaseCloudMessagingAppConfig(
        accessToken = accessToken,
    )
/** Gets a summary of the ambient user's notification events for the specified time range. **/
    override suspend fun getUserNotificationSummaryByRange(
        startTime: DateTime,
        endTime: DateTime,
    ): GetUserNotificationSummaryResponse = endpoints.getUserNotificationSummaryByRange(
        startTime = startTime,
        endTime = endTime,
        accessToken = accessToken,
    )
/** Marks the specified notification event as read. **/
    override suspend fun markNotificationSummaryRead(
        date: DateOnly,
        scope: DataScope,
        targetEntityTypeId: RecordTypeId,
        targetEntityId: String,
        eventType: NotificationDigestEventType,
    ): Unit = endpoints.markNotificationSummaryRead(
        date = date,
        scope = scope,
        targetEntityTypeId = targetEntityTypeId,
        targetEntityId = targetEntityId,
        eventType = eventType,
        accessToken = accessToken,
    )
/** Sets one or more notification subscription configurations. **/
    override suspend fun putNotificationSubscriptionConfigurations(
        subscriptions: List<NotificationSubscription>,
    ): Unit = endpoints.putNotificationSubscriptionConfigurations(
        subscriptions = subscriptions,
        accessToken = accessToken,
    )
/** Lists the user's notification subscription configurations. **/
    override suspend fun listNotificationSubscriptionConfigurations(
    ): ListNotificationSubscriptionConfigurationsResponse = endpoints.listNotificationSubscriptionConfigurations(
        accessToken = accessToken,
    )
/** Gets the ambient user's notification events for the specified time range. **/
    override suspend fun getUserNotificationEventsByRange(
        startTime: DateTime,
        endTime: DateTime,
    ): GetUserNotificationEventsResponse = endpoints.getUserNotificationEventsByRange(
        startTime = startTime,
        endTime = endTime,
        accessToken = accessToken,
    )
/** Marks the specified notification event as read. **/
    override suspend fun markNotificationEventRead(
        scope: DataScope,
        targetEntityTypeId: RecordTypeId,
        targetEntityId: String,
        eventType: NotificationDigestEventType,
        eventTime: DateTime,
        actorId: String,
        parameters: List<String>?,
    ): MarkNotificationEventReadResponse = endpoints.markNotificationEventRead(
        scope = scope,
        targetEntityTypeId = targetEntityTypeId,
        targetEntityId = targetEntityId,
        eventType = eventType,
        eventTime = eventTime,
        actorId = actorId,
        parameters = parameters,
        accessToken = accessToken,
    )
/** Gets a summary of the ambient user's notification events for the specified date. **/
    override suspend fun getUserNotificationSummary(
        date: DateOnly,
    ): GetUserNotificationSummaryResponse = endpoints.getUserNotificationSummary(
        date = date,
        accessToken = accessToken,
    )
/** Gets the ambient user's notification events for the specified date. **/
    override suspend fun getUserNotificationEvents(
        date: DateOnly,
    ): GetUserNotificationEventsResponse = endpoints.getUserNotificationEvents(
        date = date,
        accessToken = accessToken,
    )
}
