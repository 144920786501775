package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.AffirmativeSemantic.withoutBack
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.readable.Constant
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.dialogGeneric

@Routable("plan")
object Plan : Page {
    override val title = Constant("Subscription")
    override fun ViewWriter.render(): ViewModifiable = scrolling - col {
        col {
            padding = 2.rem
            spacing = 3.rem

            centered - bold - text("Your current plan is:")

            centered - h1 {
                content = "FREE"
                themeChoice += ThemeDerivation {
                    it.withoutBack(
                        font = it.font.copy(
                            weight = 700,
                            size = 2.rem
                        )
                    )
                }
            }
            spacingOverrideBeforeNext(0.25.rem)
            centered - text("0$/month")

            centered - bold - text("Plan Details")

            col {
                listOf(
                    ("Ads" to "Yes"),
                    ("# Collections" to "10"),
                    ("# Photos per Collection" to "10"),
                    ("# Guests per Collection" to "50"),
                    ("Download Photos" to "No")
                ).forEach {
                    row {
                        FadedSemantic.onNext - text(it.first)
                        expanding - space()
                        sizeConstraints(width = 2.rem) - text(it.second)
                    }
                }
            }
        }
        space(2.0)

        separator()

        space()
        row {
            expanding - space()
            important - buttonTheme - button {
                paddingByEdge = Edges(1.5.rem, 0.25.rem)
                text("Upgrade")
                onClick {
                    dialogGeneric {
                        col {
                            h4("Coming soon!")
                            text("Additional subscription plans will be offered soon.")
                        }
                    }
                }
            }
            space()
        }


    }
}