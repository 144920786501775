package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.readable.Constant
import com.lightningkite.readable.invoke
import com.lightningkite.readable.reactiveSuspending
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.frame
import com.lightningkite.kiteui.views.launch
import com.lightningkite.kiteui.views.reactiveScope
import com.picme.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionSharingAuthorizationCode
import com.picme.sdk2.toSafeDecoded
import com.picme.sdk2.unauthApi
import kotlinx.coroutines.launch


@Routable("up/{urlSafeCollId}")
class LegacyUpload(val urlSafeCollId: String) : Page, UnauthPage, NoTopBar {
    override fun ViewWriter.render(): ViewModifiable { 
        return frame {
            launch {
                acceptingInvite set AcceptInvite(
                    legacy = LegacyAccept(),
                    collection = CollectionId(urlSafeCollId.toSafeDecoded())
                )
                mainPageNavigator.reset(LegacyInvite)
            }
        }
    }
}


@Routable("up/{urlSafeCollId}/{sharingAuthCode}")
class LegacyShare(val urlSafeCollId: String, val sharingAuthCode: String) : Page, UnauthPage, NoTopBar {
    override fun ViewWriter.render(): ViewModifiable { 
        acceptingInvite.value = AcceptInvite(
            legacy = LegacyAccept(CollectionSharingAuthorizationCode(sharingAuthCode)),
            collection = CollectionId(urlSafeCollId.toSafeDecoded())
        )
        mainPageNavigator.reset(LegacyInvite)
        return frame {}
    }
}

@Routable("qr/{qrCodeId}")
class QrCodeOld(val qrCodeId: String) : Page, UnauthPage, NoTopBar {
    override fun ViewWriter.render(): ViewModifiable { 
        mainPageNavigator.reset(QrAccept(qrCodeId))
        return frame {}
    }
}


object LegacyInvite : Page, UnauthPage, NoTopBar {
    override fun ViewWriter.render(): ViewModifiable { 
        return frame {
            reactiveSuspending {
                createAccountIfNewUser()
                val collId = acceptingInvite()?.collection ?: return@reactiveSuspending
                val collection = unauthApi().collectionHandler2.getCollection(collId)

                if (acceptingInvite()?.legacy?.sharingAuthCode != null) {
                    pageNavigator.reset(ShareAccept(collection, ""))
                }
                if (acceptingInvite()?.legacy?.sharingAuthCode == null) {
                    pageNavigator.reset(RequestUploadsAccept(collection, ""))
                }
                session()
            }

            fullPageLoading()
        }
    }
}