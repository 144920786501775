package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.contains
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.CornerRadii
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.picme.*
import com.picme.components.*
import com.picme.emailOrPhone
import com.picme.session
import com.picme.sdk2.unauthApi


@Routable("create-account")
object CreateAccount : Page, UnauthPage {
    override fun ViewWriter.render(): ViewModifiable { 
        val password = Property("")
        val check = Property(false)
        val passwordError: Property<String?> = Property(null)
        return authPage("Create new account?", true) {
            subtext("We couldn't find an account with that email/phone number. To create an account, enter a password and click Continue for a verification code.")

            field("Email or Phone") {
                textInput {
                    hint = "Email or Phone"
                    content bind emailOrPhone
                    keyboardHints = KeyboardHints.email
                }
            }

            row {
                ::exists { emailOrPhone().isPhone }
                checkbox {
                    checked bind check
                }
                expanding - subtext("By clicking this box, I agree that PicMe may send me information about its services, offers, and my account by text message. Message frequency may vary and standard messaging rates may apply.")
            }

            val submitAction = Action("Submit") {
                if (password().length < 8) {
                    passwordError set "Password must be at least 8 characters long"
                    return@Action
                }
                session()?.authentication?.signUpUser(
                    emailOrPhoneNumber = emailOrPhone().lowercase(),
                    password = password(),
                    setTosRead = true,
                    referrerUserId = null,
                    userName = ""
                ) ?: unauthApi().authenticationHandler.signUpUser(
                    emailOrPhoneNumber = emailOrPhone().lowercase(),
                    password = password(),
                    setTosRead = true,
                    referrerUserId = null,
                    userName = "",
                    accessToken = { null }
                )
                showToast("Verification code sent")
                pageNavigator.navigate(ConfirmEmail().also {
                    it.userPassword = password()
                })
            }

            styledPassword(label = "Password", new = true, password = password, passwordError = passwordError, action = submitAction)

            spacingOverrideBeforeNext(spaceBetweenFieldsAndAction)

            actionOrCancelSection(
                onCancel = { pageNavigator.goBack() },
                actionButton = {
                    important - buttonTheme - button {
                        centered - text("Continue")
                        action = submitAction
                    }
                }
            )

            eula("By clicking Continue, I agree to the PicMe")
        }
    }
}