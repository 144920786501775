package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogPageNavigator
import com.lightningkite.kiteui.navigation.pageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.RecyclerViewPagingPlacer
import com.lightningkite.kiteui.views.l2.childrenMultipleTypes
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.readable.*
import com.lightningkite.readable.Action
import com.picme.*
import com.picme.actuals.deviceCanShareFiles
import com.picme.actuals.loadInterstitialAd
import com.picme.collectionDownload
import com.picme.components.ImageViewPager.ImageDisplayInfo
import com.picme.components.ImageViewPager.ImageDisplayInfoType
import com.picme.sdk2.Retainable
import com.picme.sdk2.SafeIds
import com.picme.sdk2.caching.uploadToLocal
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.collection2.*
import com.picme.views.PageForCollection
import com.picme.views.comments.ItemCommentsPage
import com.picme.views.comments.ScopedEntity
import com.picme.views.getBlob
import com.picme.views.likes.ItemLikesPage
import com.picme.views.likes.LikeControl
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

@Routable("/collection/{urlSafeId}/images")
class ImageViewPager(
    override val urlSafeId: String,
) : PageForCollection(), ControlsStatusBarColor, CannotBeCovered, HasActions, HasClickableTitle {

    companion object {
        private val swipeCounter = Property(0)
    }

    override val statusBarColor: Readable<Color> = Constant(Color.picmeAuthGrey)

    val images: Readable<List<ImageDisplayInfo>> = sharedSuspending {
        collection().collection.images().all().toImageDisplayInfo(collectionId)
    }

    constructor(urlSafeId: String, image: String) : this(urlSafeId) {
        this.imageId.value = image
    }

    @QueryParameter("image")
    val imageId = LateInitProperty<String>()

    val weAreOwner = shared { ownsPCollection(collection().collection)() }
    val notVerified = sharedSuspending { session()?.isVerifiedAccount() == false }
    val details: Readable<GetUploadResponse2> = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = collection().collection.collectionId,
            uploadId = imageId().let(::UploadId)
        )()
    }
    val likeControl = details.lens {
        LikeControl(
            ScopedEntity(
                DataScope(it.upload.uploadGlobalId.scope),
                it.upload.uploadId.raw,
                RecordTypeId(it.upload.uploadGlobalId.typeId)
            )
        ).also { AppScope.launch { it.fetch() } }
    }
    val haveLiked = shared {
        val me = sessionNotNull().authenticatedUser().userId.raw
        likeControl().selections()?.any { it.userInfo.userId.raw == me } == true
    }

    override suspend fun ViewWriter.onTitleClick() {
        val myId = sessionNotNull.state.raw.authenticatedUser.state.raw.userId
        if (!weAreOwner() && details().upload.uploaderUserId != myId) return
        dialogGeneric { close ->
            col {
                spacing = 2.rem
                val dirtyCaption = Property("")

                reactive {
                    dirtyCaption.value = details().upload.caption.raw
                }

                val action = Action("Save", action = {
                    session()?.collection2?.patchUpload(
                        collectionId = collection().collection.collectionId,
                        uploadId = details().upload.uploadId,
                        body = PatchUploadBody(caption = Retainable(dirtyCaption()))
                    )
                    close()
                })

                field("Title") {
                    textInput {
                        hint = "Title"
                        content bind dirtyCaption
                        keyboardHints = KeyboardHints.title
                        this.requestFocus()
                        this.action = action
                    }
                }
                actionOrCancelSection(
                    onCancel = close,
                    actionButton = {
                        important - buttonTheme - button {
                            centered - text("Save")
                            this.action = action
                        }
                    },
                )
            }
        }
    }

    override val title: Readable<String> = shared {
        val permittedLen = isSmallPage.lens { if (it) 50 else 100 }
        val title = details().upload.caption.raw.let {
            if (it.length <= permittedLen()) it
            else {
                "${it.substring(0, permittedLen())}..."
            }
        }
        title.ifBlank {
            if (details().upload.mimeType.raw.contains("video")) {
                "Video"
            } else "Photo"
        }
    }

    override val actions = listOfNotNull(
        PicmeAction(
            title = "Move to Trash",
            icon = PIcon.trash,
            shown = { (weAreOwner() || notVerified()) },
            action = {
                showConfirmDialog(
                    title = "Confirm Delete",
                    content = "This item will be deleted from the collection.",
                    confirmLabel = "Delete",
                    onConfirm = {
                        deleteImage()
                    }
                )
            }
        ),
        PicmeAction(
            title = "Comments",
            icon = { PIcon.caption },
            action = {
                ensureVerifiedAccount {
                    val collectionId = collection().collection.collectionId
                    val imageId = imageId()
                    val s = ItemCommentsPage(SafeIds.encode(collectionId.raw), UploadId(imageId))
                    smallPageNavigator.reset(s)
                }
            }
        ),
        PicmeAction(
            title = "Likes",
            icon = {
                if (haveLiked())
                    PIcon.likeFilled
                else
                    PIcon.like
            },
            count = { likeControl().selections()?.size ?: 0 },
            action = {
                ensureVerifiedAccount {
                    if (!haveLiked()) {
                        likeControl().likeItem()
                    } else {
                        val collectionId = collection().collection.collectionId
                        val imageId = imageId()
                        smallPageNavigator.reset(
                            ItemLikesPage(SafeIds.encode(collectionId.raw), UploadId(imageId))
                        )
                    }
                }
            }
        ),
        PicmeAction(
            title = "Share",
            icon = PIcon.share,
            shown = { deviceCanShareFiles() },
            action = {
                val copy = getBlob(details().getDetailsUri)
                ExternalServices.share(listOf(imageId().validDownloadableName() to copy))
            }
        ),
        PicmeAction(
            title = "More",
            icon = PIcon.more,
            menu = {
                frame {
                    MenuOptionContainerSemantic.onNext - col {
                        MenuOptionSemantic.onNext - button {
                            ::exists {
                                shared {
                                    this@ImageViewPager.collection().collection.let { ownsPCollection(it)() }
                                }()
                            }
                            row {
                                centered - icon(PIcon.copy, "")
                                centered - text("Copy to another collection")
                            }
                            onClick {
                                openCopyMenu(
                                    this@ImageViewPager.collection.lens { it.collection },
                                    listOf(UploadId(this@ImageViewPager.imageId()))
                                )
                                closePopovers()
                            }
                        }

                        MenuOptionSemantic.onNext - button {
                            ::exists {
                                shared {
                                    this@ImageViewPager.collection().collection.let { ownsPCollection(it)() }
                                }()
                            }
                            dynamicTheme {
                                if (this@ImageViewPager.details().upload.mimeType.raw.contains("video")) DisabledSemantic else null
                            }
                            row {
                                centered - icon(PIcon.folder, "")
                                centered - text("Use as Collection Image")
                            }
                            onClick {
                                if (this@ImageViewPager.details().upload.mimeType.raw.contains("video")) return@onClick
                                val uri = this@ImageViewPager.details().getDetailsUri
                                val copy = getBlob(uri)
                                val coll = this@ImageViewPager.collection().collection
                                session()!!.collection2.putCollectionCoverPhoto(
                                    collectionId = coll.collectionId,
                                    body = RequestBodyBlob(content = copy),
                                    tempUri = uri,
                                    onProgress = {}
                                )
                                closePopovers()
                            }
                        }

                        MenuOptionSemantic.onNext - button {
                            row {
                                icon(PIcon.download, "")
                                text("Download")
                            }
                            onClick {
                                closePopovers()
                                val imageToDownload = this@ImageViewPager.imageId()
                                val loadedAd = sharedProcess { emit(loadInterstitialAd()) }
                                openProgressModal(
                                    title = "Downloading file",
                                    execute = {
                                        try {
                                            individualItemProgress = 0.25f
                                            val filename = imageToDownload.validDownloadableName()
                                            val details = session.awaitNotNull().collection2.getUploadLive(
                                                collectionId = this@ImageViewPager.collection().collection.collectionId,
                                                uploadId = imageToDownload.let(
                                                    ::UploadId
                                                )
                                            )()
                                            image = details.getThumbnailUri.let(::ImageRemote)
                                            val url =
                                                details().getDetailsUri
                                            ExternalServices.download(filename, url, DownloadLocation.Pictures) {
                                                individualItemProgress = it
                                            }
                                            individualItemProgress = 0.5f
                                            delay(1000)
                                            individualItemProgress = 0.75f
                                            delay(1000)

                                        } catch (e: Exception) {
                                            e.printStackTrace()
                                            showToast("Download failed. Check PicMe's Camera Roll access in Settings.")
                                        }

                                    }, onComplete = {
                                        showToast(
                                            when (Platform.current) {
                                                Platform.iOS -> "File saved to Camera Roll"
                                                else -> "File downloaded"
                                            }
                                        )
                                        loadedAd()?.show(this)
                                    }
                                )
                            }
                        }
                        MenuOptionSemantic.onNext - button {
                            row {
                                icon(PIcon.info, "")
                                text("Info")
                            }
                            onClick {
                                closePopovers()
                                smallPageNavigator.reset(
                                    PhotoInformation(
                                        this@ImageViewPager.collection.lens { it.collection },
                                        this@ImageViewPager.details,
                                        shared {
                                            this@ImageViewPager.images()
                                                .first { it.id == this@ImageViewPager.imageId() }
                                        }
                                    )
                                )
                            }
                        }
                    }
                }
            }
        )
    )

    suspend fun ViewWriter.deleteImage() {
        val images = collection().collection.images().all()
        val isLastImage = images.size == 1
        deleteImages(collection().collection.collectionId, images.filter { it.uploadId.raw == imageId() })
        if (isLastImage) dialogPageNavigator.clear()
        // TODO: move this out to the VP itself - we should autokick if it's empty there
    }

    override fun ViewWriter.render(): ViewModifiable = unpadded - col {
        val stableImageId = imageId.debounce(100.milliseconds)
        reactive {
            (smallPageNavigator.currentPage() as? ShowsLiveUpload)?.item?.value = stableImageId().let(::UploadId)
        }
        spacingAndPadding = 0.dp
        themeChoice = ViewPagerButtonSemantic

        val details: Readable<GetUploadResponse2> = shared {
            session.awaitNotNull().collection2.getUploadLive(
                collectionId = collection().collection.collectionId,
                uploadId = imageId().let(::UploadId)
            )()
        }

        centered - padded - subtext {
            ::content {
                val d = details()
                listOfNotNull(
                    "Uploaded",
                    d.upload.uploadTime.takeUnless { it.toEpochMilliseconds() <= 0L }
                        ?.renderDateToString(size = RenderSize.Abbreviation),
                    d.uploader.uploaderName.takeUnless { it.isBlank() }?.let { "by $it" }
                ).joinToString(" ")
            }
        }
        viewPagerWithAds(images, imageId)
    }


    fun ViewWriter.viewPagerWithAds(
        imagesWithAds: Readable<List<ImageDisplayInfo>>,
        incomingImage: Writable<String>,
    ) {
        var startingIndex = -1
        unpadded - weight(1f) - horizontalRecyclerView {
            placer = RecyclerViewPagingPlacer()
            snapToElements = Align.Center
            scrollSnapStop = true

            with(outerFrame) {
                if (!Platform.usesTouchscreen) {
                    atCenterStart - button {
                        ::exists { centerIndex() != 0 }
                        icon(Icon.chevronLeft.copy(width = 3.rem, height = 3.rem), "Previous")
                        onClick { centerIndex set centerIndex() - 1 }
                    }
                    atCenterEnd - button {
                        ::exists { centerIndex() < imagesWithAds().size - 1 }
                        icon(Icon.chevronRight.copy(width = 3.rem, height = 3.rem), "Next")
                        onClick { centerIndex set centerIndex() + 1 }
                    }
                }
            }

            val centerIndexDelayed = centerIndex.debounce(0.3.seconds)
            reactive {
                centerIndexDelayed()
                swipeCounter.value = (swipeCounter.value % 20) + 1
            }

            reactiveSuspending {
                if (swipeCounter() == 20) {
                    loadInterstitialAd()?.show(this@viewPagerWithAds)
                }
            }

            reactiveSuspending {
                if (startingIndex == -1) {
                    startingIndex = imagesWithAds().map { it.id }.indexOf(incomingImage())
                    centerIndex.set(startingIndex)
                }
            }
            reactiveSuspending {
                val updatedIndex = centerIndex()
                val image = imagesWithAds().getOrNull(updatedIndex)
                image?.let {
                    incomingImage.set(image.id)
                }
            }
            childrenMultipleTypes(imagesWithAds, id = ImageDisplayInfo::id) {
                elementsMatching { it.type == ImageDisplayInfoType.Video } renderedAs { data ->
                    video {
                        ::source { data().viewVideo() }
                        showControls = true
                    }
                }
                elementsMatching { it.type == ImageDisplayInfoType.Image } renderedAs { data ->
                    HiddenClickStatesSemantid.onNext - link {
                        image {
                            showLoadingIndicator = false
                            this.reactiveScope {
                                source = data().thumbnail
                                data().viewImage()?.let { source = it }
                            }
                            scaleType = ImageScaleType.Fit
                        }
                        onNavigator = dialogPageNavigator
                        ::to { data().originalImage()?.let { { FullScreenZoomableImage(it) } } }
                    }
                }
            }
        }
    }


    data class ImageDisplayInfo(
        val id: String,
        val type: ImageDisplayInfoType,
        val thumbnail: ImageSource,
        val originalImage: Readable<ImageSource?> = Constant(null),
        val viewImage: Readable<ImageSource?> = Constant(null),
        val originalVideo: Readable<VideoSource?> = Constant(null),
        val viewVideo: Readable<VideoSource?> = Constant(null),
        val mimeType: String
    )

    enum class ImageDisplayInfoType { Video, Image }
}

class FullScreenZoomableImage(val source: ImageSource) : Page {
    override fun ViewWriter.render(): ViewModifiable {
        return FullScreenImageSemantic.onNext - frame {
            launch {
                delay(10.milliseconds)
                smallPageNavigator.clear()
            }
            zoomableImage {
                showLoadingIndicator = false
                this.source = this@FullScreenZoomableImage.source
                scaleType = ImageScaleType.Fit
            }
            atTopEnd - important - button {
                icon(PIcon.close, "Close")
                onClick { dialogPageNavigator.dismiss() }
            }
        }
    }
}

class PhotoInformation(
    val collection: Readable<PCollection>,
    val imageDetails: Readable<GetUploadResponse2?>,
    val currImage: Readable<ImageDisplayInfo>
) : Page {
    override val title: Readable<String>
        get() = Constant("Photo Information")

    override fun ViewWriter.render(): ViewModifiable {
        val close = suspend { pageNavigator.dismiss(); Unit }
        val detailsNotNull = imageDetails.lens { it ?: GetUploadResponse2() }

        val caption = Property("")
        val dirtyCaption = Property("")

        reactive {
            val c = detailsNotNull().upload.caption.raw
            caption.value = c
            dirtyCaption.value = c
        }

        return formColumn(
            fieldSection = {
                field("Title") {
                    textInput {
                        ::enabled {
                            val weAreOwner = ownsPCollection(collection())
                            val myId = sessionNotNull.state.raw.authenticatedUser.state.raw.userId
                            (weAreOwner() || imageDetails()?.upload?.uploaderUserId == myId)
                        }
                        hint = "Title"
                        content bind dirtyCaption
                        keyboardHints = KeyboardHints.title
                    }
                }

                unStyledField("Upload Date") {
                    text {
                        ::content {
                            detailsNotNull().upload.uploadTime
                                .takeUnless { it.toEpochMilliseconds() <= 0L }
                                ?.renderDateToString(size = RenderSize.Abbreviation)
                                ?: "A long time ago"
                        }
                    }
                }
                unStyledField("Uploaded by") {
                    ::exists { detailsNotNull().uploader.uploaderName != "" }
                    text {
                        ::content { detailsNotNull().uploader.uploaderName }
                    }
                }
                unStyledField("File Name") {
                    ::exists { detailsNotNull().upload.originalFilename != "" }
                    text {
                        ::content { detailsNotNull().upload.originalFilename }
                    }
                }
                unStyledField("File Size") {
                    ::exists { detailsNotNull().upload.originalFilename != "" }
                    text {
                        ::content { formatBytes(detailsNotNull().upload.bytes) }
                    }
                }

            },
            actionSection = {
                actionOrCancelSection(
                    onCancel = close,
                    constrainedWidth = true,
                    actionButton = {
                        important - buttonTheme - button {
                            centered - text("Save")
                            action = Action("Save", action = {
                                session()?.collection2?.patchUpload(
                                    collectionId = collection().collectionId,
                                    uploadId = currImage().id.let(::UploadId),
                                    body = PatchUploadBody(caption = Retainable(dirtyCaption()))
                                )
                                caption set dirtyCaption()
                                close()
                            })
                        }
                    },
                )
            }
        )
    }
}


fun List<ListedUpload>.toImageDisplayInfo(collectionId: CollectionId): List<ImageDisplayInfo> = map {
    val details = shared {
        session.awaitNotNull().collection2.getUploadLive(
            collectionId = collectionId,
            uploadId = it.uploadId
        )()
    }
    ImageDisplayInfo(
        id = it.uploadId.raw,
        thumbnail = uploadToLocal[it.uploadId] ?: it.thumbnailUrl.let(::ImageRemote),
        originalImage = shared {
            if (!it.mimeType.raw.contains("video")) details().getDetailsUri.let(::ImageRemote) else null
        },
        originalVideo = shared {
            if (it.mimeType.raw.contains("video")) details().getDetailsUri.let(::VideoRemote) else null
        },
        viewImage = shared {
            if (!it.mimeType.raw.contains("video")) details().let {
                it.getViewUri
                    .takeUnless { it.isBlank() }
                    ?: it.getDetailsUri
            }.let(::ImageRemote) else null
        },
        viewVideo = shared {
            if (it.mimeType.raw.contains("video")) details().let {
                it.getViewUri
                    .takeUnless { it.isBlank() }
                    ?: it.getDetailsUri
            }.let(::VideoRemote) else null
        },
        type = if (it.mimeType.raw.contains("video")) ImageDisplayInfoType.Video else ImageDisplayInfoType.Image,
        mimeType = it.mimeType.raw
    )
}