package com.picme

import com.lightningkite.kiteui.Console
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Rect
import com.lightningkite.kiteui.models.Size
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.l2.RecyclerViewAnchor
import com.lightningkite.kiteui.views.l2.RecyclerViewPlaceable
import com.lightningkite.kiteui.views.l2.RecyclerViewPlacerGrid
import com.lightningkite.kiteui.views.l2.estimateJumpAnchor
import kotlin.math.abs


class RecyclerViewGridPlacerSizeBasedColumns(val columnsFromViewport: (Rect) -> Int, val ratio: Double? = null) :
    RecyclerViewPlacerGrid {
    constructor(columns: Int, ratio: Double? = null):this({ columns }, ratio)
    var log: Console? = null //ConsoleRoot.tag("RecyclerViewPlacerVerticalGrid")
    override fun withOrthogonalCount(count: Int): RecyclerViewPlacerGrid = RecyclerViewGridPlacerSizeBasedColumns(count)

    override fun place(
        dataRange: IntRange,
        anchor: RecyclerViewAnchor?,
        previousViewport: Rect,
        existingCells: List<RecyclerViewPlaceable>,
        getNewCell: (Int, Size) -> RecyclerViewPlaceable,
        viewport: Rect,
        overdraw: Rect,
        paddingTop: Double,
        paddingLeft: Double,
        paddingRight: Double,
        paddingBottom: Double,
        spacing: Double,
    ) {
        val columns = columnsFromViewport(viewport).coerceAtLeast(1)
        val oldColumns = columnsFromViewport(previousViewport).coerceAtLeast(1)
        val cellSize = (viewport.right - viewport.left - paddingLeft - paddingRight - (columns - 1) * spacing) / columns
        val constrain = Size(
            width = cellSize,
            height = 10000.0
        )
        val cellOffsets = (0..<columns).map {
            paddingLeft + it * spacing + it * cellSize
        }

        val (anchorRowY, anchorRowIndex) = (anchor?.let {
            when(it) {
                is RecyclerViewAnchor.FuzzyIndex -> {
                    val averageRowHeight = ratio?.let { cellSize * it } ?: existingCells.sumOf { it.bottom - it.top } / existingCells.size
                    val focusRowIndex = it.index.coerceIn(dataRange.first.toDouble(), dataRange.last.toDouble()).div(columns).toInt().times(columns)
                    val partialIndexOffset = it.index.rem(oldColumns) / oldColumns * averageRowHeight
                    viewport.top + viewport.height * it.ratioOfFocus - partialIndexOffset to focusRowIndex
                }
                is RecyclerViewAnchor.SpecificElement -> {
                    val currentIndex = it.index.coerceIn(dataRange).div(oldColumns).times(oldColumns)
                    val cells = (0..<oldColumns).map {
                        if (currentIndex + it in dataRange) getNewCell(
                            currentIndex + it,
                            constrain
                        ) else null
                    }
                    val max = ratio?.let { cellSize * it } ?: cells.maxOf { it?.size?.height ?: 0.0 }
                    when (it.align) {
                        Align.Start -> viewport.top + paddingTop
                        Align.End -> viewport.bottom - max - paddingBottom
                        else -> viewport.centerY - max / 2
                    } to currentIndex
                }
            }
        } ?: existingCells.asSequence().minByOrNull {
            it.centerX +
                    abs(viewport.top - it.top) +
                    (if(it.top <= overdraw.top) 10000 else 0)
        }?.let {
            log?.log("Using existing cells for anchor: ${it.top} to ${it.index} => ${it.index.div(oldColumns).times(oldColumns)}")
            it.top to it.index.div(oldColumns).times(oldColumns)
        } ?: run {
            // approximate anchor
            val it = estimateJumpAnchor(existingCells, true, viewport) ?: return@run null
            log?.log("estimateJumpAnchor got $it")
            val averageRowHeight = ratio?.let { cellSize * it } ?: existingCells.sumOf { it.bottom - it.top } / existingCells.size
            val focusRowIndex = it.index.coerceIn(dataRange.first.toDouble(), dataRange.last.toDouble()).div(columns).toInt().times(columns)
            val partialIndexOffset = it.index.rem(oldColumns) / oldColumns * averageRowHeight
            viewport.top + viewport.height * it.ratioOfFocus - partialIndexOffset to focusRowIndex
        } ?: run {
            log?.log("estimateJumpAnchor was dumped; no existin cells?")
            (viewport.top + paddingTop to dataRange.first.div(oldColumns).times(oldColumns))
        }).let {
            // anchor correction for out of bounds
            if(it.second < dataRange.first - oldColumns) {
                log?.log("Anchor ignored due to out-of-range")
                (viewport.top + paddingTop to dataRange.first.div(oldColumns).times(oldColumns))
            } else if(it.second > dataRange.last + oldColumns) {
                log?.log("Anchor ignored due to out-of-range")
                val currentIndex = dataRange.last.div(oldColumns).times(oldColumns)
                val cells = (0..<oldColumns).map {
                    if (currentIndex + it in dataRange) getNewCell(
                        currentIndex + it,
                        constrain
                    ) else null
                }
                val max = ratio?.let { cellSize * it } ?: cells.maxOf { it?.size?.height ?: 0.0 }
                (viewport.bottom - max - paddingBottom to currentIndex)
            } else it
        }
        log?.log("ANCHOR $anchorRowY gets index ${anchorRowIndex}")

        // Place downwards, one row at a time
        var currentY = anchorRowY
        var currentIndex = anchorRowIndex
        while (currentY < overdraw.bottom && currentIndex <= dataRange.last) {
            val cells = (0..<columns).map {
                if (currentIndex + it in dataRange) getNewCell(
                    currentIndex + it,
                    constrain
                ) else null
            }
            val max = ratio?.let{ cellSize * it } ?: cells.maxOf { it?.size?.height ?: 0.0 }
            for (i in 0..<columns) {
                cells[i]?.place(cellOffsets[i], currentY, cellOffsets[i] + cellSize, currentY + max)
            }
            currentY += max + spacing
            currentIndex += columns
        }
        // Place upwards, one row at a time
        currentY = anchorRowY - spacing
        currentIndex = anchorRowIndex - columns
        while (currentY > overdraw.top && currentIndex + columns - 1 >= dataRange.first) {
            val cells = (0..<columns).map {
                if (currentIndex + it in dataRange) getNewCell(
                    currentIndex + it,
                    constrain
                ) else null
            }
            val max = ratio?.let{ cellSize * it } ?: cells.maxOf { it?.size?.height ?: 0.0 }
            for (i in 0..<columns) {
                cells[i]?.place(cellOffsets[i], currentY - max, cellOffsets[i] + cellSize, currentY)
            }
            currentY -= max + spacing
            currentIndex -= columns
        }
    }

    override fun prebake(
        prebakeRange: IntRange,
        dataRange: IntRange,
        writer: ViewWriter,
        render: ViewWriter.(Int) -> Unit
    ): Unit = with(writer) {
        TODO()
    }
}