package com.picme.sdk2.generated.notification

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*



@Serializable
data class FirebaseCloudMessagingProjectConfig(
    val type: String = "", 
    val projectId: String = "", 
    val privateKeyId: String = "", 
    val privateKey: String = "", 
    val clientEmail: String = "", 
    val clientId: String = "", 
    val authUri: String = "", 
    val tokenUri: String = "", 
    val authProviderX509CertUrl: String = "", 
    val clientX509CertUrl: String = "", 
)

/** A record containing the response to a request to set the Firebase Cloud Messaging project configuration. **/

/** A record containing the response to a request to get the Firebase Cloud Messaging project configuration. **/
@Serializable
data class GetFirebaseCloudMessagingProjectConfigResponse(
    val firebaseProjectId: String = "", 
)


@Serializable
data class FirebaseCloudMessagingAppConfig(
    val serverKey: String = "", 
    val senderId: String = "", 
    val projectId: String = "", 
    val appId: String = "", 
    val apiKey: String = "", 
)

/** A record containing the response to a request to set the Firebase Cloud Messaging app configuration. **/

/** A record containing the response to a request to get the Firebase Cloud Messaging app configuration. **/
@Serializable
data class GetFirebaseCloudMessagingAppConfigResponse(
    val firebaseProjectId: String = "", 
)

/** A record containing the response to a request to get the user's notification events. **/
@Serializable
data class GetUserNotificationSummaryResponse(
    val events: List<UserNotificationSummary> = listOf(), 
    val actors: List<UserInfo> = listOf(), 
)

/** A record containing information about a notification event for a specific user. **/
@Serializable
data class UserNotificationSummary(
    val recipientEntityId: String = "", 
    val targetEntity: EntityInfo = EntityInfo(), 
    val eventType: NotificationDigestEventType = NotificationDigestEventType.values().first(), 
    val actors: List<UserId> = listOf(), 
    val firstEventTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val lastEventTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val read: Boolean = false, 
)

/** User-readble information about an entity. **/
@Serializable
data class EntityInfo(
    val scope: DataScope = DataScope(""), 
    val entityTypeId: RecordTypeId = RecordTypeId(""), 
    val entityId: String = "", 
    val parentTitle: String? = null, 
    val title: String = "", 
)


object NotificationDigestEventTypeSerializer: KSerializer<NotificationDigestEventType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationDigestEventType", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationDigestEventType {
        return NotificationDigestEventType.byValue[decoder.decodeInt()] ?: NotificationDigestEventType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationDigestEventType) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationDigestEventTypeSerializer::class)
enum class NotificationDigestEventType(val value: Int) {

    Upload(0),

    Message(1),

    Vote(2),
    ;
    companion object {
        val byValue = NotificationDigestEventType.values().associateBy { it.value }
    }
}

/** A record containing the response to a request to mark a notification summary as read. **/


@Serializable
data class NotificationSubscription(
    val collectionId: CollectionId? = null, 
    val types: NotificationType? = null, 
    val channels: NotificationChannel? = null, 
    val frequency: NotificationFrequency? = null, 
)


object NotificationFrequencySerializer: KSerializer<NotificationFrequency> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationFrequency", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationFrequency {
        return NotificationFrequency.byValue[decoder.decodeInt()] ?: NotificationFrequency.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationFrequency) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationFrequencySerializer::class)
enum class NotificationFrequency(val value: Int) {

    Asap(1),

    Daily(3),

    Never(2147483647),
    ;
    companion object {
        val byValue = NotificationFrequency.values().associateBy { it.value }
    }
}


object NotificationChannelSerializer: KSerializer<NotificationChannel> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationChannel", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationChannel {
        return NotificationChannel.byValue[decoder.decodeInt()] ?: NotificationChannel.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationChannel) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationChannelSerializer::class)
enum class NotificationChannel(val value: Int) {

    None(0),

    Email(1),
    ;
    companion object {
        val byValue = NotificationChannel.values().associateBy { it.value }
    }
}


object NotificationTypeSerializer: KSerializer<NotificationType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationType", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationType {
        return NotificationType.byValue[decoder.decodeInt()] ?: NotificationType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationType) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationTypeSerializer::class)
enum class NotificationType(val value: Int) {

    Uploads(2),

    Comments(16),

    Votes(32),
    ;
    companion object {
        val byValue = NotificationType.values().associateBy { it.value }
    }
}

/** A record containing the response of a request to put the notification subscription configurations. **/

/** A record containing notification subscription configurations. **/
@Serializable
data class ListNotificationSubscriptionConfigurationsResponse(
    val subscriptions: List<NotificationSubscription> = listOf(), 
)

/** A record containing the response to a request to get the user's notification events. **/
@Serializable
data class GetUserNotificationEventsResponse(
    val events: List<UserNotificationEvent> = listOf(), 
    val targetEntities: List<EntityInfo> = listOf(), 
    val actors: List<UserInfo> = listOf(), 
)

/** A record containing information about a notification event for a specific user. **/
@Serializable
data class UserNotificationEvent(
    val recipientEntityId: String = "", 
    val scope: DataScope = DataScope(""), 
    val targetEntityTypeId: RecordTypeId = RecordTypeId(""), 
    val targetEntityId: String = "", 
    val eventType: NotificationDigestEventType = NotificationDigestEventType.values().first(), 
    val eventTime: DateTime = Instant.fromEpochMilliseconds(0), 
    val actorId: UserId = UserId(""), 
    val parameters: List<String>? = null, 
    val read: Boolean = false, 
)

/** A record containing the response to <see cref="M:PicMeApi.NotificationApis.MarkNotificationEventRead(CloudFileSystems.ICloudFileSystem,SimpleDatabase.Database,PicMeApi.UserDataDetails,SimpleDatabase.DataScope,SimpleDatabase.RecordTypeId,System.String,PicMeModel.NotificationDigestEventType,System.DateTime,System.String,System.String[])" />. **/
@Serializable
data class MarkNotificationEventReadResponse(
    val updatedEvent: UserNotificationEvent = UserNotificationEvent(), 
)

