package com.picme.views.notifications

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.models.ImageScaleType
import com.lightningkite.kiteui.models.ImageSource
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.mainPageNavigator
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.children
import com.lightningkite.readable.*
import com.picme.*
import com.picme.components.ImageViewPager
import com.picme.components.showToast
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.sdk2.generated.notification.*
import com.picme.views.CollectionImageView
import com.picme.views.comments.CollectionCommentsPage
import com.picme.views.comments.ItemCommentsPage
import com.picme.views.likes.ItemLikesPage
import kotlinx.coroutines.launch
import kotlinx.datetime.*
import kotlin.time.Duration.Companion.days

@Routable("notifications")
object NotificationPage : Page, UnpaddedPage {
    override val title = Constant("Notifications")
    override fun ViewWriter.render(): ViewModifiable = col {
        padding = 0.dp
        val frequency = Property<NotificationFrequency?>(null)

        reactiveSuspending {
            frequency set (sessionNotNull().notification.listNotificationSubscriptionConfigurations().subscriptions.firstOrNull {
                it.collectionId == null
            }?.frequency ?: NotificationFrequency.Daily)
        }

        val notifications = sharedSuspending {
            val now = Clock.System.now()
            sessionNotNull().notification.getUserNotificationEventsByRange(
                startTime = now - 7.days,
                endTime = now,
            )
        }

        expanding - col {
            ::exists { !notifications.state().ready }
            space(8.0)
            centered - activityIndicator { }
        }

        expanding - recyclerView {
            ::exists { notifications.state().ready }
            children(
                items = notifications.lens { it.events.sortedWith(compareByDescending<UserNotificationEvent> { it.eventTime }.thenBy { it.read }) },
                id = { it },
            ) { event ->
                button {
                    onClick {
                        AppScope.launch {
                            sessionNotNull().notification.markNotificationEventRead(
                                scope = event().scope,
                                targetEntityTypeId = event().targetEntityTypeId,
                                targetEntityId = event().targetEntityId,
                                eventType = event().eventType,
                                eventTime = event().eventTime,
                                actorId = event().actorId.raw,
                                parameters = event().parameters,
                            )
                        }
                        mainPageNavigator.stack.value = event().main()
                        smallPageNavigator.stack.value =
                            event().sub()?.let { listOf(NotificationPage, it) } ?: emptyList()
                    }
                    row {
                        dynamicTheme {
                            if (event().read) {
                                NotificationReadSemantic
                            } else {
                                NotificationUnreadSemantic
                            }
                        }

                        sizeConstraints(width = 3.rem, height = 3.rem) - frame {
                            val img = Property<ImageSource?>(null)
                            reactiveSuspending {
                                img.value = null
                                img.value = event().image(notifications())
                            }
                            centered - icon { source = PIcon.folder }
                            image {
                                scaleType = ImageScaleType.Crop
                                ::source { img() }
                            }
                        }

                        expanding - col {
                            spacing = 0.25.rem
                            subtext {
                                reactive {
                                    setBasicHtmlContent(event().title(notifications()))
                                }
                            }
                            unpadded - NotificationCaptionSemantic.onNext - text {
                                ::content { event().eventTime.renderRelativeToString() }
                            }
                        }
                        space(0.0)
                    }
                }
            }
        }

        col {
            padding = 0.75.rem
            separator()
            row {
                centered - subtext("Send email notifications")
                expanding - fieldTheme - select {
                    enabled = false
                    ::enabled { frequency() != null }
                    bind(
                        edits = frequency.withWrite {
                            sessionNotNull().notification.putNotificationSubscriptionConfigurations(
                                listOf(
                                    NotificationSubscription(
                                        collectionId = null,
                                        types = null,
                                        channels = NotificationChannel.Email,
                                        frequency = it
                                    )
                                )
                            )
                            showToast(
                                "Notification settings saved",
                                "Email notification settings have been changed"
                            )
                        },
                        data = Constant(
                            listOf(
                                NotificationFrequency.Never,
                                NotificationFrequency.Asap,
                                NotificationFrequency.Daily,
                            )
                        ),
                        render = {
                            when (it) {
                                NotificationFrequency.Asap -> "Immediately"
                                NotificationFrequency.Daily -> "Once Daily"
                                else -> it.toString()
                            }
                        }
                    )
                }
            }
            space(0.0)
        }
    }
}

private fun UserNotificationEvent.main(): List<Page> {
    val collectionId = scope.raw.takeUnless { it.isBlank() } ?: targetEntityId
    val uploadId = if (scope.raw.isBlank()) null else targetEntityId
    return listOfNotNull(
        CollectionImageView(SafeIds.encode(collectionId)),
        when (eventType) {
            NotificationDigestEventType.Upload -> ImageViewPager(SafeIds.encode(collectionId), targetEntityId)
            NotificationDigestEventType.Message -> if (uploadId != null) ImageViewPager(
                SafeIds.encode(collectionId),
                uploadId
            ) else null

            NotificationDigestEventType.Vote -> if (uploadId != null) ImageViewPager(
                SafeIds.encode(collectionId),
                uploadId
            ) else null
        }
    )
}

private fun UserNotificationEvent.sub(): Page? {
    val collectionId = scope.raw.takeUnless { it.isBlank() } ?: targetEntityId
    val uploadId = if (scope.raw.isBlank()) null else UploadId(targetEntityId)
    return when (eventType) {
        NotificationDigestEventType.Upload -> null
        NotificationDigestEventType.Message -> uploadId?.let {
            ItemCommentsPage(
                SafeIds.encode(collectionId),
                uploadId
            )
        } ?: CollectionCommentsPage(collectionId)

        NotificationDigestEventType.Vote -> ItemLikesPage(collectionId, uploadId!!)
    }
}

private fun String?.bolded(): String? = this?.takeUnless { it.isBlank() }?.let { "<b>${this}</b>" }

private fun UserNotificationEvent.title(sharedInfo: GetUserNotificationEventsResponse): String {
    val actor = sharedInfo.actors.firstOrNull { it.userId == actorId }
    val collection = sharedInfo.targetEntities.find { it.entityId == scope.raw || it.entityId == targetEntityId }
    val target = sharedInfo.targetEntities.find { it.entityId == targetEntityId }
    return when (eventType) {
        NotificationDigestEventType.Upload -> listOf(
            (actor?.name?.bolded() ?: "Someone"),
            "uploaded a photo in",
            (collection?.title?.bolded() ?: "a collection"),
        ).joinToString(" ")

        NotificationDigestEventType.Message -> {
            if (target != collection) {
                listOf(
                    (actor?.name?.bolded() ?: "Someone"),
                    "commented on",
                    (target?.title?.bolded() ?: "a photo"),
                    "in",
                    (collection?.title?.bolded() ?: "a collection"),
                ).joinToString(" ")
            } else {
                listOf(
                    (actor?.name?.bolded() ?: "Someone"),
                    "commented on",
                    (target?.title?.bolded() ?: "a collection"),
                ).joinToString(" ")
            }
        }

        NotificationDigestEventType.Vote ->
            listOf(
                (actor?.name?.bolded() ?: "Someone"),
                "liked",
                (target?.title?.bolded() ?: "a photo"),
                "in",
                (collection?.title?.bolded() ?: "a collection")

            ).joinToString(" ")
    }
}

private suspend fun UserNotificationEvent.image(sharedInfo: GetUserNotificationEventsResponse): ImageRemote? {
    val event = this

    return when (event.eventType) {
        NotificationDigestEventType.Upload -> {
            val upload = sessionNotNull().collection2.getUploadLive(
                CollectionId(event.scope.raw),
                UploadId(event.targetEntityId)
            )
            ImageRemote(upload().getThumbnailUri)
        }

        NotificationDigestEventType.Message -> {
            val upload = if (event.scope.raw.isBlank()) Constant(null) else sessionNotNull().collection2.getUploadLive(
                CollectionId(event.scope.raw),
                UploadId(event.targetEntityId)
            )
            upload()?.getThumbnailUri?.imageIfExists() ?: sessionNotNull().collection2.getCollectionLive(
                CollectionId(event.scope.raw.ifBlank { event.targetEntityId }),
            ).await().getCoverPhotoUri.imageIfExists()
        }

        NotificationDigestEventType.Vote -> {
            val upload = sessionNotNull().collection2.getUploadLive(
                CollectionId(event.scope.raw),
                UploadId(event.targetEntityId)
            )
            upload().getThumbnailUri.imageIfExists() ?: sessionNotNull().collection2.getCollectionLive(
                CollectionId(event.scope.raw.ifBlank { event.targetEntityId }),
            ).await().getCoverPhotoUri.imageIfExists()
        }
    }
}
