package com.picme.views.comments

import com.lightningkite.readable.AppScope
import com.lightningkite.readable.Readable
import com.lightningkite.readable.invoke
import com.lightningkite.now
import com.lightningkite.readable.LateInitProperty

import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sdk2.generated.Text
import com.picme.sdk2.generated.UserInfo
import com.picme.sdk2.generated.discussion.CreateDiscussionMessageBody
import com.picme.sdk2.generated.discussion.CreateDiscussionMessageResponse
import com.picme.sdk2.generated.discussion.DiscussionMessageId
import com.picme.sdk2.generated.discussion.PatchDiscussionMessageBody
import com.picme.sessionNotNull
import com.picme.session
import kotlinx.coroutines.launch


interface CommentsControl {
    val comments: Readable<List<Comment>?>
    suspend fun create(message: String): CreateDiscussionMessageResponse
    suspend fun modify(id: String, message: String)
    suspend fun postComment(newMessage: String)
    suspend fun delete(c: Comment)
    val canDelete: (c: Comment) -> Boolean
}

data class ScopedEntity(
    val scope: DataScope,
    val discussionOrEntityId: String,
    val discussionOrEntityTypeId: RecordTypeId,
)

class BaseCommentsControl(
    private val info: ScopedEntity,
    override val canDelete: (c: Comment) -> Boolean,
) : CommentsControl {
    override val comments = LateInitProperty<List<Comment>>()

    init {
        AppScope.launch {
            comments set sessionNotNull().discussion.listDiscussionMessages(
                scope = info.scope,
                discussionOrEntityId = info.discussionOrEntityId,
                discussionOrEntityTypeId = info.discussionOrEntityTypeId,
                continuation = null
            ).let { response ->
                response.entries.map { message ->
                    val userInfo = response.userInfo.first { it.userId == message.message.creatorUserId }
                    Comment(
                        message = message.message.text.raw,
                        id = message.message.discussionMessageId.raw,
                        userInfo = userInfo,
                        createdAt = message.message.creationTime,
                    )
                }
            }
        }
    }

    override suspend fun postComment(newMessage: String) {
        if (newMessage.isBlank()) return
        val added = create(newMessage)
        comments set comments().plus(
            Comment(
                message = newMessage,
                id = added.discussionMessageId.raw,
                userInfo = UserInfo(
                    userId = sessionNotNull().authenticatedUser().userId,
                    name = "Me",
                    getProfilePicture = sessionNotNull().authentication.getMyUserInfo().profilePicture
                ),
                createdAt = now(),
            )
        )
    }

    override suspend fun create(message: String): CreateDiscussionMessageResponse {
        return sessionNotNull().discussion.createDiscussionMessage(
            scope = info.scope,
            discussionOrEntityId = info.discussionOrEntityId,
            discussionOrEntityTypeId = info.discussionOrEntityTypeId,
            body = CreateDiscussionMessageBody(Text(message))
        )
    }

    override suspend fun modify(id: String, message: String) {
        val modifiedComment = comments().find { it.id == id }?.copy(message = message) ?: return
        sessionNotNull().discussion.patchDiscussionMessage(
            scope = info.scope,
            discussionOrEntityId = info.discussionOrEntityId,
            discussionOrEntityTypeId = info.discussionOrEntityTypeId,
            discussionMessageId = DiscussionMessageId(id),
            body = PatchDiscussionMessageBody(Retainable(Text(message)))
        )
        comments set comments().map {
            if (it.id == id) modifiedComment else it
        }
    }

    override suspend fun delete(c: Comment) {
        sessionNotNull().discussion.deleteDiscussionMessage(
            scope = info.scope,
            discussionOrEntityId = info.discussionOrEntityId,
            discussionOrEntityTypeId = info.discussionOrEntityTypeId,
            discussionMessageId = DiscussionMessageId(c.id),
        )
        comments set comments().minus(c)
    }

//    val canDelete(c: Comment): Boolean {
//        return canDelete(c)
//        val userId = sessionNotNull.state.raw.authenticatedUser.state.getOrNull()?.userId?.raw
//        return false
////        return (currentCollection.value!!.creatorUserId.raw == userId) || (c.userInfo.isMe)
//    }
}
