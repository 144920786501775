package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayFrame
import com.lightningkite.readable.invoke
import com.picme.FatCardSemantic
import com.picme.spacingAndPadding

fun ViewWriter.showConfirmDialog(
    title: String,
    content: String,
    confirmLabel: String? = null,
    warning: String? = null,
    onConfirm: suspend () -> Unit
) {
    val overlay = overlayFrame ?: return

    with(overlay) {
        dismissBackground {
            fun close() {
                overlay.removeChild(this)
            }
            onClick { println("Dismiss bg"); close() }
            centered - padded - frame {
                centered - FatCardSemantic.onNext - sizeConstraints(width = 24.rem) - expanding - col {
                    spacingAndPadding = 2.rem
                    centered - h4(title)

                    text { ::content { content } }
                    danger - unpadded - text {
                        exists = false
                        ::exists { warning != null }
                        ::content { warning ?: "" }
                    }
                    frame {
                        ::exists { confirmLabel == null }
                        actionOrCancelSection(
                            onCancel = { close() },
                            actionButton = {}
                        )
                    }
                    frame {
                        ::exists { confirmLabel != null }
                        actionOrCancelSection(
                            onCancel = { close() },
                            actionButton = {
                                important - buttonTheme - button {
                                    centered - text(confirmLabel ?: "")
                                    onClick {
                                        onConfirm()
                                        close()
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }
    }
}


fun ViewWriter.dialogGeneric(content: RView.(close: () -> Unit) -> Unit) {
    val overlay = overlayFrame ?: return
    with(overlay) {
        dismissBackground {
            fun close() {
                overlay.removeChild(this)
            }
            onClick { close() }
            centered - padded - frame {
                centered - FatCardSemantic.onNext - sizeConstraints(width = 32.rem) - expanding - col {
                    spacingAndPadding = 2.rem
                    content { close() }
                }
            }
        }
    }
}