package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.space
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.readable.Action
import com.lightningkite.readable.awaitNotNull
import com.lightningkite.readable.invoke
import com.picme.*
import com.picme.components.askForNameForm
import com.picme.components.authPage
import com.picme.components.createUserName


@Routable("create-account-finish")
object FinishCreateAccount : Page, NoTopBar {
    override fun ViewWriter.render(): ViewModifiable {
        return authPage("What's your name?") {

            col {
                FadedSemantic.onNext - text {
                    content = "Enter your name or generate a random one to remain anonymous."
                    align = Align.Center
                }
                space()

                askForNameForm(
                    Action(
                        title = "Continue",
                        action = {
                            session.awaitNotNull().authentication.setUserAttributes(
                                name = createUserName(),
                                setTosRead = true
                            )
                            if (acceptingInvite() == null) {
                                navigateToCollOrLanding()
                            } else {
                                acceptQrCode()
                            }
                        }
                    )
                )
            }
        }
    }
}


