package com.picme.sdk2.generated.invitation

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object InvitationHandlerEndpointsSerializer : KSerializer<InvitationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("InvitationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: InvitationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): InvitationHandlerEndpoints {
        val string = decoder.decodeString()
        return InvitationHandlerEndpoints(string)
    }
}


@Serializable(with = InvitationHandlerEndpointsSerializer::class)
data class InvitationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Activates the specified Invite code.
            Any authenticated user can activate a Invite code. **/
    suspend fun activateInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ActivateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/activate",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Lists all users who have activated the specified Invite code.
            Note that this includes users who activated the code and then had their rights revoked.
            Requires ownership of the Invite code. **/
    suspend fun listActivatedUsers(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ListActivatedUsersResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/users",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Gets the specified Invite code object.
            Any authenticated user can call this API, whether they're related to the Invite code or its owner or not. **/
    suspend fun getInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): GetInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Updates a Invite code object from the specified parameters.
            Only the Invite code's creator can patch it. **/
    suspend fun patchInviteCode(
        inviteId: InviteCodeId,
        body: PatchInviteCodeBody,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )
/** Deletes the specified Invite code object.
            Only the Invite code's creator can delete it. **/
    suspend fun deleteInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
/** Creates a Invite code object from the specified parameters.
            Any authenticated user can create a Invite code, but the Invite code has to link something, and the caller must own whatever is being linked. **/
    suspend fun createInviteCode(
        body: CreateInviteCodeBody,
        linkParentRecord: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "linkParentRecord" to linkParentRecord.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )
/** Lists invite codes owned by the specified user (or the authenticated user), or associated with a specified collection.
            The caller must be the owner of the Invite code or must own the contained entity.
            With a <paramref name="linkRelationshipType" /> of <see cref="P:SimpleDatabase.LinkRelationshipType.Hierarchy" /> and a <paramref name="linkPrimaryGlobalId" /> for a collection, this will list all the request/sharing invite codes associated with the collection, both request invite codes and sharing invite codes.
            With a <paramref name="linkRelationshipType" /> of <see cref="P:SimpleDatabase.AuthLinkRelationshipTypes.ReferralLinkType" /> and a <paramref name="linkPrimaryGlobalId" /> for user, this will list all the referral invite codes used by the specified user to refer others. **/
    suspend fun listInviteCodes(
        linkRelationshipType: LinkRelationshipType?,
        linkPrimaryGlobalId: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): ListInviteCodesResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "linkRelationshipType" to linkRelationshipType.toParamString(),
            "linkPrimaryGlobalId" to linkPrimaryGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )
}

