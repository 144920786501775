package com.picme

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.PersistentProperty
import com.lightningkite.readable.*
import com.picme.SelectedTheme.Dark
import com.picme.SelectedTheme.Light
import kotlinx.coroutines.delay
import kotlinx.serialization.Serializable


val Color.Companion.picmeBlue get() = fromHexString("#0014CC")
val Color.Companion.messageBubble get() = fromHexString("##1c95ff")
val Color.Companion.separatorColor get() = fromHexString("#E1E1E1")
val Color.Companion.picmeAuthGrey get() = fromHexString("#EDF0FC")
val Color.Companion.picmePink get() = fromHexString("#fa00ff")
val Color.Companion.picmePurple get() = fromHexString("#8E0DE8")
val Color.Companion.picmeToastBg get() = fromHexString("#1e1e24")
val Color.Companion.picmeToastGreen get() = fromHexString("#61d345")
val Color.Companion.picmeDanger get() = fromHex(0xFFB00020.toInt())

val Color.Companion.menuIconBg get() = fromHexString("#F4EBFF")
val Color.Companion.picmeButtonSecondary get() = fromHexString("#ececed")
val Color.Companion.picmeFaded get() = fromHexString("#41414e")
val Color.Companion.darkPurple get() = fromHexString("#6941C6")
val Color.Companion.darkGray get() = fromHexString("#575757")
val Color.Companion.neoPicmeBlue get() = Color.fromHexString("#0080F0")
val Color.Companion.neoPicmePurple get() = Color.fromHexString("#ab47bc")
fun Color.brightnessInvert(): Color = toHSP().let {
    it.copy(brightness = 1f - it.brightness.coerceIn(0f, 1f))
}.toRGB()

val Color.Companion.bluePurpleGradient
    get() = LinearGradient(
        stops = listOf(
            GradientStop(0f, fromHexString("#0014cb")),
            GradientStop(1f, picmePink),
        ),
        angle = Angle.eighthTurn,
        screenStatic = false
    )
val Color.Companion.whitePurpleGradient
    get() = LinearGradient(
        stops = listOf(
            GradientStop(0f, Color.white),
            GradientStop(0.2f, Color.white),
            GradientStop(1f, fromHexString("#ede5ff")),
        ),
        angle = Angle.eighthTurn,
        screenStatic = false
    )

fun oldPicmeTheme(dark: Boolean): Theme = Theme.material(
    id = "",
    primary = Color.fromHexString("#0014CC"),
    title = FontAndStyle(font = Resources.inter, weight = 600),
    body = FontAndStyle(font = Resources.inter, weight = 500),
    elevation = 0.rem,
    outlineWidth = 0.dp,
    cornerRadii = CornerRadii.Constant(0.5.rem),
    background = if (dark) Color.gray(.1f) else Color.white,
    outline = if (dark) Color.gray(.2f) else Color.gray(.9f),
    foreground = if (dark) Color.white else Color.black,
    spacing = 0.75.rem,
).customize(
    newId = "picme-old-$dark",
    bodyTransitions = ScreenTransitions.Fade,
    dialogTransitions = ScreenTransitions.FadeResize,
    iconOverride = Color.darkPurple.let { if (dark) it.brightnessInvert() else it },
    separatorOverride = Color.fromHexString("#D0D0D0").let { if (dark) it.invert() else it },
    derivations = mapOf(
        CardSemantic to {
            val b = it.background.closestColor().lighten(0.1f)
            it.copy(id = "card", background = b, outlineWidth = 1.dp, outline = b.highlight(0.1f)).withBack
        },
        DialogSemantic to {
            it.withBack(
                elevation = 0.25.rem,
                cascading = false,
            )
        },
//    dialog = { copy(background = Color.gray) },
        BarSemantic to {
            it.copy(
                id = "bar",
                outlineWidth = 0.dp,
                cornerRadii = CornerRadii.ForceConstant(0.dp)
            ).withBack
        },
//        Sep to {
//            it.copy()
//        },
        ButtonSemantic to {
            it.copy(
                id = "button",
                cornerRadii = CornerRadii.ForceConstant(100.rem),
                spacing = it.spacing * 0.375,
                padding = Edges(it.spacing * 0.375),
                outlineWidth = 0.dp,
                font = it.font.copy(weight = 600, size = 1.1.rem)
            ).withBack
        },
        UnselectedSemantic to { it.withoutBack },
        SelectedSemantic to {
            it.copy(
                id = "sel",
                background = Color.menuIconBg.let { if (dark) it.brightnessInvert() else it },
            ).withBack
        },
        FocusSemantic to {
            it.copy(
                id = "focus",
                outline = if (it.background.closestColor() channelDifferenceSum Color.picmePurple >= 1f) Color.picmePurple else Color.picmeToastGreen,
                outlineWidth = 1.dp
            ).withBack
        },
        FieldSemantic to {
            it.copy(
                id = "field",
                cornerRadii = CornerRadii.ForceConstant(0.4.rem),
                spacing = 0.5.rem,
                padding = Edges(0.5.rem),
                outlineWidth = 1.dp
            ).withBack
        },
        ImportantSemantic to {
            it.copy(
                id = "important",
                foreground = Color.white,
                background = Color.bluePurpleGradient,
                iconOverride = Color.white,
                outlineWidth = 0.dp,
                font = it.font.copy(weight = 600)
            ).withBack
        },
        DangerSemantic to {
            it.copy(
                id = "danger",
                foreground = Color.picmeDanger,
                outlineWidth = 0.dp
            ).withBack
        },
        H1Semantic to {
            it.copy(id = "h1", font = it.font.copy(weight = 700)).withoutBack
        },
        H3Semantic to {
            it.copy(id = "h3", font = it.font.copy(weight = 700, size = 24.dp)).withoutBack
        },
        H4Semantic to {
            it.copy(id = "h4", font = it.font.copy(size = 1.125.rem, weight = 600)).withoutBack
        },
        H5Semantic to {
            it.copy(id = "h5", font = it.font.copy(weight = 600, size = 1.rem)).withoutBack
        },
        H6Semantic to {
            it.copy(id = "h6", font = it.font.copy(weight = 500, size = 1.rem)).withoutBack
        },
        SubtextSemantic to {
            it.withoutBack(font = it.font.copy(weight = 400, size = it.font.size - 0.125.rem))
        },
        LoadingSemantic to { it.withoutBack },
        WorkingSemantic to { it.withoutBack },
        ClickableSemantic to { it.withoutBack },
        ToastSemantic to {
            // Toasts are always dark
            it.withBack(
                foreground = Color.white,
                iconOverride = null,
                background = Color.picmeToastBg,
                outlineWidth = 0.px
            )
        },
        ActiveTaskBarSemantic to {
            it.withBack(
                cornerRadii = CornerRadii.ForceConstant(0.px),
                background = Color.picmeAuthGrey.let { if (dark) it.brightnessInvert() else it },
                font = it.font.copy(weight = 600),
            )
        },
        AuthSemantic to {
            it
                .alter(
                    cascading = true,
                    derivations = mapOf(
                        ButtonSemantic to {
                            it.withBack(
                                cornerRadii = CornerRadii.ForceConstant(100.rem),
                            )
                        }
                    )
                )
                .alter(
                    background = Color.picmeAuthGrey.let { if (dark) it.brightnessInvert() else it },
                    cornerRadii = CornerRadii.Constant(0.dp),
                    cascading = false
                )
                .withBack
        },
        AuthSmallSemantic to {
            it
                .alter(
                    cascading = true,
                    derivations = mapOf(
                        ButtonSemantic to {
                            it.withBack(
                                cornerRadii = CornerRadii.ForceConstant(100.rem),
                            )
                        }
                    )
                )
                .withoutBack
        },
        MostlyEmptySemantic to {
            it.withoutBack(background = Color.whitePurpleGradient)
        }
    ),
)

val Theme.root: Theme get() = derivedFrom?.root ?: this
fun picmeTheme(dark: Boolean, primary: Color = Color.neoPicmeBlue): Theme = Theme.material(
    id = "picme",
    primary = primary,
    title = FontAndStyle(font = Resources.inter, bold = true),
    body = FontAndStyle(font = Resources.inter),
    elevation = 0.rem,
    outlineWidth = 0.dp,
    cornerRadii = CornerRadii.Constant(0.5.rem),
    background = if (dark) Color.gray(.1f) else Color.white,
    outline = if (dark) Color.gray(.2f) else Color.gray(.9f),
    foreground = (if (dark) Color.white else Color.black).highlight(0.175f),
    spacing = 0.75.rem,
).customize(
    newId = "picme-$dark-${primary.toInt()}",
    bodyTransitions = ScreenTransitions.Fade,
    dialogTransitions = ScreenTransitions.FadeResize,
    iconOverride = Color.fromHexString("#818181"),
    separatorOverride = Color.fromHexString("#D0D0D0").let { if (dark) it.invert() else it },
    derivations = mapOf(
        CardSemantic to {
            val b = it.background.closestColor().lighten(0.1f)
            it.copy(id = "card", background = b, outlineWidth = 1.dp, outline = b.highlight(0.1f)).withBack
        },
        DialogSemantic to {
            it.withBack(
                elevation = 0.25.rem,
                cascading = false,
            )
        },
//    dialog = { copy(background = Color.gray) },
        BarSemantic to {
            it.copy(
                id = "bar",
                outlineWidth = 0.dp,
                cornerRadii = CornerRadii.ForceConstant(0.dp)
            ).withBack
        },
//        Sep to {
//            it.copy()
//        },
        ButtonSemantic to {
            val noOtherBorder = it.outlineWidth == 0.dp && it.foreground.closestColor()
                .toHSP().saturation == 0f && it.background.closestColor().toHSP().saturation == 0f
            it.copy(
                id = "button",
                cornerRadii = CornerRadii.ForceConstant(100.rem),
                foreground = if (noOtherBorder) primary else it.foreground,
                padding = it.padding * 0.375,
                spacing = it.spacing * 0.375,
            ).withBack
        },
        UnselectedSemantic to { it.withoutBack },
        SelectedSemantic to {
            it.copy(
                id = "sel",
                background = Color.fromHexString("#eeeeee").let { if (dark) it.brightnessInvert() else it },
            ).withBack
        },
        FocusSemantic to {
            it.copy(
                id = "focus",
                outline = if (it.background.closestColor() channelDifferenceSum primary >= 1f) primary else Color.picmeToastGreen,
                outlineWidth = 1.dp
            ).withBack
        },
        FieldLabelSemantic to {
            it.alter(
                foreground = it.foreground.applyAlpha(0.5f),
                font = it.font.copy(size = 0.875.rem, weight = 400),
                padding = Edges(left = 0.px, top = 0.px, right = 0.px, bottom = 0.25.rem)
            ).withoutBackButPadding
        },
        FieldSemantic to {
            it.copy(
                id = "field",
                cornerRadii = CornerRadii.ForceConstant(0.4.rem),
                spacing = 0.5.rem,
                padding = Edges(0.5.rem),
                outlineWidth = 1.dp
            ).withBack
        },
        ImportantSemantic to {
            it.copy(
                id = "important",
                foreground = Color.white,
                background = primary,
                iconOverride = Color.white,
                outlineWidth = 0.dp,
                font = it.font.copy(weight = 600)
            ).withBack
        },
        AffirmativeSemantic to {
            it.withoutBack(
                foreground = Color.green.let { if (dark) it.lighten(0.2f) else it },
                outline = Color.green.let { if (dark) it.lighten(0.2f) else it },
            )
        },
        DangerSemantic to {
            it.withoutBack(
                foreground = Color.picmeDanger.let { if (dark) it.lighten(0.2f) else it },
                outline = Color.picmeDanger.let { if (dark) it.lighten(0.2f) else it },
            )
        },
        H1Semantic to {
            it.copy(id = "h1", font = it.font.copy(weight = 700)).withoutBack
        },
        H3Semantic to {
            it.copy(id = "h3", font = it.font.copy(weight = 700, size = 24.dp)).withoutBack
        },
        H4Semantic to {
            it.copy(id = "h4", font = it.font.copy(size = 1.125.rem, weight = 600)).withoutBack
        },
        H5Semantic to {
            it.copy(id = "h5", font = it.font.copy(weight = 600, size = 1.rem)).withoutBack
        },
        H6Semantic to {
            it.copy(id = "h6", font = it.font.copy(weight = 500, size = 1.rem)).withoutBack
        },
        SubtextSemantic to {
            it.withoutBack(font = it.font.copy(weight = 400, size = it.font.size - 0.125.rem))
        },
        LoadingSemantic to { it.withoutBack },
        WorkingSemantic to { it.withoutBack },
        ClickableSemantic to { it.withoutBack },
        ToastSemantic to {
            // Toasts are always dark
            it.withBack(
                foreground = Color.white,
                iconOverride = null,
                background = Color.picmeToastBg,
                outlineWidth = 0.px
            )
        },
        ActiveTaskBarSemantic to {
            it.withBack(
                cornerRadii = CornerRadii.ForceConstant(0.px),
                background = Color.picmeAuthGrey.let { if (dark) it.brightnessInvert() else it },
                font = it.font.copy(weight = 600),
            )
        },
        AuthSemantic to {
            it
                .alter(
                    cascading = true,
                    derivations = mapOf(
                        ButtonSemantic to {
                            it.withBack(
                                cornerRadii = CornerRadii.ForceConstant(100.rem),
                            )
                        }
                    )
                )
                .alter(
                    background = Color.picmeAuthGrey.let { if (dark) it.brightnessInvert() else it },
                    cornerRadii = CornerRadii.Constant(0.dp),
                    cascading = false
                )
                .withBack
        },
        AuthSmallSemantic to {
            it
                .alter(
                    cascading = true,
                    derivations = mapOf(
                        ButtonSemantic to {
                            it.withBack(
                                cornerRadii = CornerRadii.ForceConstant(100.rem),
                            )
                        }
                    )
                )
                .withoutBack
        },
        MostlyEmptySemantic to {
            it.withBack(background = Color.whitePurpleGradient)
        },
        LinkSemantic to {
            it.withoutBack(foreground = primary)
        },
//        NotificationBadgeSemantic to {
//            it.withBack(background = if (dark) Color.gray(.1f) else Color.white)
//        }
    ),
)

object ToastSemantic : Semantic("toast") {
    override fun default(theme: Theme) = theme[DialogSemantic]
}

object ActiveTaskBarSemantic : Semantic("activetaskbar") {
    override fun default(theme: Theme) = theme[BarSemantic].theme.let {
        it.withBack(
            font = it.font.copy(weight = 600)
        )
    }
}

object DropTargetSemantic : Semantic("droptarget") {
    override fun default(theme: Theme): ThemeAndBack {
        val c = theme.separator
        return theme.alter(
            cascading = true,
            foreground = theme.icon,
        ).withBack(
            cascading = false,
            outlineWidth = 1.dp,
            outline = c,
//            padding = theme.padding.copy(left = theme.padding.left * 2, right = theme.padding.right * 2),
            derivations = mapOf(
                HoverSemantic to { it.withBack(outlineWidth = 2.dp) },
                SelectedSemantic to { it.withBack(outlineWidth = 2.dp, background = c.applyAlpha(0.1f)) },
            )
        )
    }
}

object SemiImportantSemantic : Semantic("semiimp") {
    override fun default(theme: Theme): ThemeAndBack {
        val c = theme[ImportantSemantic].theme.background
        return theme.alter(
            cascading = true,
            foreground = c,
        ).withBack(
            cascading = false,
            outlineWidth = 1.dp,
            outline = c,
//            padding = theme.padding.copy(left = theme.padding.left * 2, right = theme.padding.right * 2),
            derivations = mapOf(
                HoverSemantic to { it.withBack(outlineWidth = 2.dp) },
                SelectedSemantic to { it.withBack(outlineWidth = 2.dp, background = c.applyAlpha(0.1f)) },
            )
        )
    }
}

object ImagePlaceholderSemantic : Semantic("imageplaceholder") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.alter(
            cornerRadii = CornerRadii.ForceConstant(0.5.rem),
            background = Color.gray.applyAlpha(0.4f),
            cascading = true
        ).withBackNoPadding
    }
}

object MenuOptionContainerSemantic : Semantic("menu-option-container") {
    override fun default(theme: Theme) = theme.alter(
        padding = Edges.ZERO,
        spacing = 0.px,
        cascading = false
    ).withBack
}

object MenuOptionSemantic : Semantic("menu-option") {
    override fun default(theme: Theme) = theme.withBack
}

object TabSemantic : Semantic("tab") {
    override fun default(theme: Theme) = theme.alter(
        font = theme.font.copy(size = 0.875.rem),
        cornerRadii = CornerRadii.PerCorner(10.dp, topLeft = true, topRight = true),
        padding = theme.padding * 3 / 4,
        derivations = mapOf(SelectedSemantic to {
            it.withBack(
                background = theme[SelectedSemantic].theme.background,
                font = it.font.copy(weight = 600),
            )
        }, UnselectedSemantic to { it.withBack })
    ).withBack
}

object TabUnderlineSemantic : Semantic("tabunderline") {
    override fun default(theme: Theme) = theme.alter(
        cornerRadii = CornerRadii.ForceConstant(0.dp),
        derivations = mapOf(
            SelectedSemantic to {
                it.withBack(
                    background = theme.foreground,
                )
            },
            UnselectedSemantic to {
                it.withBack(
                    background = theme.separator
                )
            })
    ).withoutBackButPadding
}

object RoundedImageSemantic : Semantic("roundedImage") {
    override fun default(theme: Theme) = theme.copy(
        id = key,
        cornerRadii = CornerRadii.ForceConstant(100.rem),
        padding = Edges.ZERO,
        spacing = 0.px
    ).withBack
}

object NotificationBadgeSemantic : Semantic("NotificationBadgeSemantic") {
    override fun default(theme: Theme) = theme.withBack(
        iconOverride = theme.background,
        padding = Edges(3.dp),
        spacing = 0.px,
        cornerRadii = CornerRadii.ForceConstant(100.rem),
        background = Color.white
    )
}

object ViewPagerButtonSemantic : Semantic("vp-button") {
    override fun default(theme: Theme) = theme.withoutBack(
        cornerRadii = CornerRadii.Constant(0.dp),
        derivations = mapOf(ButtonSemantic to {
            it.withBack(
                background = Color.black.applyAlpha(0.5f),
                foreground = Color.white,
                iconOverride = Color.white,
                cornerRadii = CornerRadii.RatioOfSize(2f),
                spacing = theme.spacing * 0.75,
                padding = theme.padding * 0.75,
            )
        })
    )
}

data object LogoSemantic : Semantic("logo") {
    override fun default(theme: Theme) = theme.withBack(
        foreground = Color.black,
        background = Color.white,
        cornerRadii = CornerRadii.ForceConstant(0.5.rem),
    )
}

data object FatCardSemantic : Semantic("fat") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme[CardSemantic].theme.withBack(
            cascading = false,
            spacing = 1.5.rem,
            padding = Edges(1.5.rem),
            cornerRadii = CornerRadii.ForceConstant(1.25.rem),
        )
    }
}

data object FadedSemantic : Semantic("faded-foreground") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(foreground = theme.foreground.applyAlpha(0.75f), iconOverride = null)
    }
}

data object EmptyTextSemantic : Semantic("empty-text") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(
            foreground = theme.foreground.applyAlpha(0.5f), iconOverride = null, font = theme.font.copy(
                size = 0.875.rem
            )
        )
    }
}

data object AuthSemantic : Semantic("auth") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme[MostlyEmptySemantic]
    }
}

data object AuthSmallSemantic : Semantic("authsm") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme[MostlyEmptySemantic]
    }
}

data object MyCommentCardSemantic : Semantic("MyCommentCardSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            id = key,
            background = Color.messageBubble,
            foreground = Color.white,
            spacing = 8.dp,
            padding = Edges(8.dp),
            cornerRadii = CornerRadii.PerCorner(0.5.rem, topLeft = true, topRight = true, bottomLeft = true),
            outline = Color.transparent,
            font = theme.font.copy(size = 0.875.rem)
        ).withBack
    }
}

data object ProfileLetterSemantic {
    private val internal = HashMap<Dimension, Semantic>()
    operator fun get(size: Dimension): Semantic = internal.getOrPut(size) {
        object : Semantic("profile-letter-${size.value.toString().replace('.', '-')}") {
            override fun default(theme: Theme): ThemeAndBack = theme.withoutBack(
                font = theme.font.copy(size = size * 2 / 4, weight = 600),
                padding = Edges.ZERO,
                spacing = 0.px
            )
        }
    }
}

data object SubwindowTitleSemantic : Semantic("subwindow-title") {
    override fun default(theme: Theme): ThemeAndBack = theme.withoutBack(
        font = theme.font.copy(size = 1.rem, weight = 600),
    )
}

//
data object CommentItemCardSemantic : Semantic("CommentItemCardSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            id = key,
            background = Color.picmeFaded.applyAlpha(0.1f),
            spacing = 8.dp,
            padding = Edges(8.dp),
            cornerRadii = CornerRadii.PerCorner(0.5.rem, topRight = true, bottomLeft = true, bottomRight = true),
            outline = Color.transparent,
            font = theme.font.copy(size = 0.875.rem),
        ).withBack
    }
}

data object CommentCaptionSemantic : Semantic("CommentCaptionSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.copy(
            id = key,
            font = theme.font.copy(size = 0.75.rem),
            foreground = theme.foreground.applyAlpha(0.5f)
        ).withoutBack
    }
}

data object ImageOverlaySemantic : Semantic("image-overlay") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withBack(
            background = Color.black.withAlpha(0.2f),
            foreground = Color.white,
            iconOverride = null,
            outlineWidth = 0.px,
            cornerRadii = CornerRadii.ForceConstant(0.px)
        )
    }
}

data object NotificationUnreadSemantic : Semantic("NotificationUnreadSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withBack(
            background = theme.background.closestColor().highlight(0.05f),
            outlineWidth = 0.px,
            cornerRadii = CornerRadii.ForceConstant(0.px)
        )
    }
}

data object NotificationReadSemantic : Semantic("NotificationReadSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withBack(
            outlineWidth = 0.px,
            cornerRadii = CornerRadii.ForceConstant(0.px)
        )
    }
}

data object NotificationCaptionSemantic : Semantic("NotificationCaptionSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withBack(
            font = theme.font.copy(size = 12.dp),
            spacing = 0.dp
        )
    }
}

data object FullScreenImageSemantic : Semantic("fullscreenimage") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withBack(background = Color.black, foreground = Color.white)
    }
}

data object HiddenClickStatesSemantid : Semantic("noclickstate") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(
            derivations = mapOf(
                HoverSemantic to { it.withoutBack },
                DownSemantic to { it.withoutBack },
            )
        )
    }
}

data object DangerForegroundSemantic : Semantic("DangerForegroundSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(foreground = Color.picmeDanger, outline = Color.picmeDanger)
    }
}

data object ImportantForegroundSemantic : Semantic("ImportantForegroundSemantic") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(foreground = Color.bluePurpleGradient, outline = Color.bluePurpleGradient)
    }
}

data object SelectedAccentColorOutline : Semantic("SelectedAccentColorFrame") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(outline = theme.foreground, outlineWidth = 2.dp, padding = Edges(2.dp))
    }
}

data object MostlyEmptySemantic : Semantic("mostlyEmpty") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack
    }
}

data object LinkSemantic : Semantic("link") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(
            foreground = if (theme.background.closestColor().perceivedBrightness > 0.5f) Color.blue.darken(0.1f) else Color.blue.lighten(
                0.3f
            ),
            cornerRadii = CornerRadii.ForceConstant(1.rem)
        )
    }
}
data object NaturalLinkSemantic : Semantic("natural-link") {
    override fun default(theme: Theme): ThemeAndBack {
        return theme.withoutBack(
            foreground = if (theme.background.closestColor().perceivedBrightness > 0.5f) Color.blue.darken(0.1f) else Color.blue.lighten(
                0.3f
            ),
            cornerRadii = CornerRadii.ForceConstant(1.rem)
        )
    }
}

val spaceBetweenFieldsAndAction = 3.rem

@Deprecated("")
val appMaterialTheme: Theme = picmeTheme(dark = true)

val selectedThemeOption = PersistentProperty("theme-preference", SelectedTheme.Light, SelectedTheme.serializer())
val selectedThemeOptionDirty = Property<SelectedTheme?>(null)
val selectedThemePrimaryColor = PersistentProperty<Int>("theme-color", Color.neoPicmePurple.toInt())
val selectedThemePrimaryColorDirty = Property<Int?>(null)

val appTheme = shared {
    val color = selectedThemePrimaryColorDirty() ?: selectedThemePrimaryColor()
    val theme = selectedThemeOptionDirty() ?: selectedThemeOption()
    theme.theme(Color.fromInt(color))()
}

val everySecond = sharedProcess {
    var i = 0
    while (true) {
        emit(i++)
        delay(1000)
    }
}

@Serializable
enum class SelectedTheme(val display: String, val secret: Boolean) {
    Light("Light", secret = false),
    Dark("Dark", secret = false),
    Dark2("Dark2", secret = true),
    Anisha("Anisha", secret = true),
    Purple("Purple", secret = true),
    PurpleDark("D/Purple", secret = true),
    KuiFlat("Kui Flat", secret = true),
    DanceTime("Dance Time", secret = true)
}

fun SelectedTheme.theme(primaryColor: Color?): Readable<Theme> {
    return when (this) {
        SelectedTheme.Light -> Constant(picmeTheme(dark = false, primaryColor ?: Color.neoPicmeBlue))
        SelectedTheme.Dark -> Constant(picmeTheme(dark = true, primaryColor ?: Color.neoPicmeBlue.lighten(0.2f)))
        SelectedTheme.Dark2 -> Constant(picmeTheme(dark = true, Color.neoPicmeBlue))
        SelectedTheme.Anisha -> Constant(picmeTheme(dark = false, Color.neoPicmePurple.lighten(0.2f)))
        SelectedTheme.Purple -> Constant(oldPicmeTheme(dark = false))
        SelectedTheme.PurpleDark -> Constant(oldPicmeTheme(dark = true))
        SelectedTheme.KuiFlat -> Constant(Theme.flat("flat", 0.6.turns))
        SelectedTheme.DanceTime -> shared {
            if (everySecond() % 2 == 0) Light.theme(primaryColor)()
            else Dark.theme(primaryColor)()
        }
    }
}