package com.picme.views.comments

import com.lightningkite.kiteui.Routable
import com.lightningkite.readable.shared
import com.picme.AllowPartialExpansion
import com.picme.sdk2.generated.DataScope
import com.picme.sdk2.generated.RecordTypeId
import com.picme.sessionNotNull
import com.picme.views.PageForCollection

@Routable("collections/{urlSafeId}/comments")
class CollectionCommentsPage(override val urlSafeId: String) : CommentsPage, AllowPartialExpansion,
    PageForCollection() {
    override fun forOtherCollection(urlSafeId: String): PageForCollection = CollectionCommentsPage(urlSafeId)
    override val noComments: String
        get() = "Be the first to comment on this collection!"
    override val control = shared {
        val it = collection()
        BaseCommentsControl(
            ScopedEntity(
                DataScope(it.collection.collectionGlobalId.scope.ifBlank { "::" }),
                it.collection.collectionId.raw,
                RecordTypeId(it.collection.collectionGlobalId.typeId),
            ),
            canDelete = { comment ->
                val myId = sessionNotNull.state.raw.authenticatedUser.state.raw.userId
                it.collection.creatorUserId == myId || comment.userInfo.userId == myId
            }
        )
    }
}