package com.picme

import com.lightningkite.kiteui.models.Dimension
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.direct.RowOrCol

@Deprecated("Set them separately")
var RView.spacingAndPadding: Dimension?
    get() = padding
    set(value) {
        padding = value
    }
@Deprecated("Set them separately")
var RowOrCol.spacingAndPadding: Dimension?
    get() = spacing
    set(value) {
        spacing = value
//        if(themeAndBack.padding) {
//            padding = value
//        }
    }