package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.readable.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.radioToggleButton
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.readable.bind
import com.picme.TabSemantic
import com.picme.TabUnderlineSemantic
import com.picme.darkPurple
import com.picme.separatorColor
import kotlinx.serialization.Serializable

@Serializable
enum class SelectedTab { First, Second }

fun ViewWriter.tabSelect(whichOne: ImmediateWritable<SelectedTab>, first: String, second: String) {
    col {
        spacing = 0.px
        row {
            paddingByEdge = Edges(
                left = 1.rem,
                right = 1.rem,
                top = 0.rem,
                bottom = 0.rem,
            )
            expanding - TabSemantic.onNext - radioToggleButton {
                centered - text(first)
                checked bind whichOne.equalTo(SelectedTab.First)
            }
            space()
            expanding - TabSemantic.onNext - radioToggleButton {
                centered - text(second)
                checked bind whichOne.equalTo(SelectedTab.Second)
            }
        }
        row {
            spacing = 0.px
            unpadded - expanding - sizeConstraints(height = 1.dp) - TabUnderlineSemantic.onNext - frame {
                dynamicTheme {
                    if (whichOne() == SelectedTab.First) SelectedSemantic else UnselectedSemantic
                }
            }

            unpadded - expanding - sizeConstraints(height = 1.dp) - TabUnderlineSemantic.onNext - frame {
                dynamicTheme {
                    if (whichOne() == SelectedTab.Second) SelectedSemantic else UnselectedSemantic
                }
            }
        }
    }
}

