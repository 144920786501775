package com.picme.actuals

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.readable.AppScope
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.models.ImageResource
import com.lightningkite.kiteui.models.ImageSource
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.readable.Readable
import com.lightningkite.readable.invoke
import com.lightningkite.kiteui.views.RContext
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.readable.sharedSuspending
import com.picme.Resources
import com.picme.sdk2.generated.AdSize
import com.picme.sdk2.generated.ListedAd
import com.picme.session
import kotlinx.coroutines.launch
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

expect class Advertisement(context: RContext) : RView {
    var adUnitId: String?
    var adImgSrc: ImageSource?
    var fullSizeAd: Boolean?
    val adLoaded: Readable<Boolean?>
}

@OptIn(ExperimentalContracts::class)
@ViewDsl
inline fun ViewWriter.advertisement(setup: Advertisement.() -> Unit = {}): Advertisement {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return write(Advertisement(context), setup)
}

object AdCache {
    private val cachedAds = mutableListOf<ListedAd>()
    private fun List<ListedAd>.firstOfSize(size: AdSize): ListedAd? {
        return this.firstOrNull {
            if (size == AdSize.Small) it.size.value == 0
            else it.size.value > 0
        }
    }


    private suspend fun fetchMore(): List<ListedAd>? {
        return session()?.ad?.listAds(
            collectionId = null,  //TODO: Reintroduce context properly
            unitTest = false
        )?.ads?.also {
            cachedAds.addAll(it)
        }
    }

    suspend fun getAd(size: AdSize, contextId: String): ImageSource {
        try {
            val ad = cachedAds.firstOfSize(size) ?: fetchMore()?.firstOfSize(size)
            if (ad == null) return defaultAd(size)

            cachedAds.remove(ad)

            AppScope.launch {
                session()?.ad?.recordImpression(
                    collectionId = null,  // TODO: Reintroduce context properly
                    contextId = contextId,
                    adId = ad.adId
                )
            }

            return ad.uri.let(::ImageRemote)
        } catch (e: Exception) {
            e.printStackTrace2()
            return defaultAd(size)
        }
    }

    private fun defaultAd(size: AdSize): ImageResource {
        return if (size == AdSize.Large) Resources.picMePaigeAdVertical
        else Resources.picMePaigeAdVertical
    }
}

interface InterstitialAd {
    fun show(viewWriter: ViewWriter)
}

suspend fun loadInterstitialAd(): InterstitialAd? = loadInterstitialAd(when (Platform.current) {
    Platform.Android -> "ca-app-pub-3940256099942544/1033173712"
    Platform.iOS -> "ca-app-pub-3940256099942544/4411468910"
    else -> ""
})
expect suspend fun loadInterstitialAd(adUnitId: String): InterstitialAd?

val interstitialAd = sharedSuspending { loadInterstitialAd() }
